import * as currenciesActions from "./constants";

export const AddCurrencies = (data) => {
  return {
    type: currenciesActions.SET_CURRENCIES,
    payload: data,
  };
};
export const AddMainCurrencies = (data) => {
  return {
    type: currenciesActions.SET_MAIN_CURRENCIES,
    payload: data,
  };
};
