import { NewRequest } from "../../helpers/axios";
import { DELETE, GET, POST, PUT } from "../../helpers/axiosConst";
import { API_URLS, BuildAPIUrl, URLS_LOCAL } from "../../utils/config/URLS";
import {
  AddProduct,
  SetProductList,
  SetSingleProduct,
} from "../../store/actions/productActions";
import { toast } from "react-toastify";

export const GetProductList = (
  params = false,
  setState = () => false,
  state = {},
  setOnLoad = () => false
) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(API_URLS.GET_PRODUCT),
      {},
      (resp) => {
        setOnLoad(true);
        const { data, pages, total } = resp.data;
        setState({
          ...state,
          isFetchData: false,
          data,
          pages,
          total,
          params: {
            ...state.params,
            page: Number(params.page),
            perPage: Number(params.perPage),
            filtered: params.filtered,
            sorted: params.sorted,
          },
        });
        dispatch(SetProductList(resp.data.data));
      },
      () => {
        setOnLoad(true);
      },
      params && {
        page: Number(params.page),
        perPage: Number(params.perPage),
        sorted: params.sorted,
        filtered: params.filtered,
      }
    );
  };
};

export const GetAllProduct = () => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(API_URLS.GET_PRODUCT),
      null,
      (resp) => {
        dispatch(SetProductList(resp.data.data));
      },
      () => false,
      {
        page: 1,
        perPage: 100000,
      }
    );
  };
};

export const CreateProduct = (
  data,
  items,
  navigate = false,
  handleSuccess = () => false
) => {
  NewRequest(
    POST,
    BuildAPIUrl(API_URLS.GET_PRODUCT),
    { data, items },
    (res) => {
      const channel = new BroadcastChannel("product");
      channel.postMessage(res.data);
      toast.success(`Product added`);
      if (navigate) {
        navigate(`/settings/product/${res.data.data.ID}/edit`);
      }
      setTimeout(() => handleSuccess(), 300);
    }
  );
};

export const GetProduct = () => {
  return (dispatch) => {
    NewRequest(GET, BuildAPIUrl(API_URLS.CONSTRUCTOR_PRODUCT), null, (res) => {
      dispatch(AddProduct(res.data));
    });
  };
};

export const EditProduct = (data, items, id) => {
  NewRequest(
    PUT,
    BuildAPIUrl(API_URLS.GET_PRODUCT),
    { data, items, id: id },
    () => {
      toast.success(`Product changed`);
    }
  );
};

export const DeleteProductCard = (id, onPageClick, state, navigate) => {
  return (dispatch) => {
    NewRequest(
      DELETE,
      BuildAPIUrl(`/api/constructor/product/item/${id}`),
      null,
      (res) => {
        toast.success("Delete completed");
        const channel = new BroadcastChannel("product");
        channel.postMessage(res.data);
        if (onPageClick) {
          onPageClick(state.params);
        }
        if (navigate) {
          navigate(URLS_LOCAL.USER.PRODUCT);
        }
      }
    );
  };
};

export const GetSingleProduct = (id, setFormData, setItemsData) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(`/api/constructor/product/item/${id}`),
      null,
      (res) => {
        dispatch(SetSingleProduct(res.data));
        setFormData({
          fields: res.data.fields,
        });
        setItemsData({
          items: res.data.items,
        });
      }
    );
  };
};
