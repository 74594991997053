import * as clientsActions from "./constants";

export const SetClientAction = (data) => {
  return {
    type: clientsActions.SET_CLIENT,
    payload: data,
  };
};

export const SetClients = (data) => {
  return {
    type: clientsActions.SET_CLIENTS,
    payload: data,
  };
};
