import * as productActions from "./constants";

export const SetSingleProduct = (data) => {
  return {
    type: productActions.SET_PRODUCT_SINGLE,
    payload: data,
  };
};

export const SetProductList = (data) => {
  return {
    type: productActions.SET_PRODUCT_LIST,
    payload: data,
  };
};
export const AddProduct = (data) => {
  return {
    type: productActions.SET_PRODUCT,
    payload: data,
  };
};
