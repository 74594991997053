export const SET_PRODUCT_TABLE = "SET_PRODUCT_TABLE";
export const SET_CLIENT_TABLE = "SET_CLIENT_TABLE";
export const SET_COMPANY_TABLE = "SET_COMPANY_TABLE";
export const SET_CUSTOM_TABLE = "SET_CUSTOM_TABLE";
export const SET_ROLE_TABLE = "SET_ROLE_TABLE";
export const SET_TAXES_TABLE = "SET_TAXES_TABLE";
export const SET_CURRENCY_TABLE = "SET_CURRENCY_TABLE";
export const SET_MAILS_TABLE = "SET_MAILS_TABLE";
export const SET_MAILS_TEMPLATE_TABLE = "SET_MAILS_TEMPLATE_TABLE";
export const SET_WORKFLOW_TABLE = "SET_WORKFLOW_TABLE";
export const SET_DOMAINS_TABLE = "SET_DOMAINS_TABLE";
