export const ADD_GOOGLE_OAUTH = "ADD_GOOGLE_OAUTH";
export const SET_CALENDAR_STATUS = "SET_CALENDAR_STATUS";
export const SET_CALENDAR = "SET_CALENDAR";
export const SET_CALENDAR_LIST = "SET_CALENDAR_LIST";
export const VIDEO_LIST = "VIDEO_LIST";
export const WIZARD_CHANGE = "WIZARD_CHANGE";
export const STEP_START = "STEP_START";
export const INCREMENT = "INCREMENT";
export const DECREMENT = "DECREMENT";
export const SET_EVENTS = "SET_EVENTS";
