import * as tableActions from "../../actions/tableActions/constants";
import { SET_DOMAINS_TABLE } from "../../actions/tableActions/constants";

const initialState = {
  product_table: [],
  client_table: [],
  company_table: [],
  custom_table: [],
  role_table: [],
  taxes_table: [],
  currency_table: [],
  mails_table: [],
  mails_template_table: [],
  workflow_table: [],
  domains_table: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case tableActions.SET_PRODUCT_TABLE:
      return {
        ...state,
        product_table: action.payload,
      };
    case tableActions.SET_CLIENT_TABLE:
      return {
        ...state,
        client_table: action.payload,
      };
    case tableActions.SET_COMPANY_TABLE:
      return {
        ...state,
        company_table: action.payload,
      };
    case tableActions.SET_CUSTOM_TABLE:
      return {
        ...state,
        custom_table: action.payload,
      };
    case tableActions.SET_ROLE_TABLE:
      return {
        ...state,
        role_table: action.payload,
      };
    case tableActions.SET_TAXES_TABLE:
      return {
        ...state,
        taxes_table: action.payload,
      };
    case tableActions.SET_CURRENCY_TABLE:
      return {
        ...state,
        currency_table: action.payload,
      };
    case tableActions.SET_MAILS_TABLE:
      return {
        ...state,
        mails_table: action.payload,
      };
    case tableActions.SET_MAILS_TEMPLATE_TABLE:
      return {
        ...state,
        mails_template_table: action.payload,
      };
    case tableActions.SET_WORKFLOW_TABLE:
      return {
        ...state,
        workflow_table: action.payload,
      };
    case tableActions.SET_DOMAINS_TABLE:
      return {
        ...state,
        domains_table: action.payload,
      };
    default:
      return state;
  }
};
