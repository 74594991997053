import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import Main from "./Main";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles.scss";
import { Provider } from "react-redux";
import persistor, { store } from "./store";
import { CloseButton } from "./components/common/Toast";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import RoutesProvider from "./routes/RoutesProvider";
import "./components/constructor/constructor.scss";
import "./components/constructor/blocks/toolbar/toolbar.scss";

const bugsnagOps = {
  apiKey: "163fc2a40405725d30cc1c28bcdbdb37",
  plugins: [new BugsnagPluginReact()],
  enabledBreadcrumbTypes: ["error", "log", "navigation", "user"],
  releaseStage: process.env.REACT_APP_BUGSNAG_ENV || process.env.NODE_ENV,
  maxBreadcrumbs: 100,
  maxEvents: 100,
  enabledReleaseStages: ["production", "staging", "development"],
};

Bugsnag.start(bugsnagOps);

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const ErrorView = () => (
  <div className={"error_block"}>
    <p>An error has occurred</p>
  </div>
);
const root = createRoot(document.getElementById("main"));

root.render(
  <ErrorBoundary FallbackComponent={ErrorView}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <BrowserRouter>
          <Main>
            <RoutesProvider>
              <App />
              <ToastContainer
                pauseOnHover={true}
                closeButton={<CloseButton />}
                position={toast.POSITION.TOP_RIGHT}
                theme={"colored"}
              />
            </RoutesProvider>
          </Main>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
);
