import React, { useContext, useEffect, Fragment } from "react";
import classNames from "classnames";
import { Nav, Navbar, Row, Col } from "react-bootstrap";
import { navbarBreakPoint } from "../../../config";
import AppContext, { RouterContext } from "../../../context/Context";
import Flex from "../../common/Flex";
import Logo from "../../common/Logo";
import NavbarVerticalMenu from "./NavbarVerticalMenu";
import ToggleButton from "./ToggleButton";
import { capitalize } from "helpers/utils";
import bgNavbar from "../../../assets/img/generic/bg-navbar.png";

const NavbarVertical = () => {
  const routerData = useContext(RouterContext);
  const {
    config: { navbarStyle, showBurgerMenu, isNavbarVerticalCollapsed },
  } = useContext(AppContext);
  const HTMLClassList = document.getElementsByTagName("html")[0].classList;
  useEffect(() => {
    if (isNavbarVerticalCollapsed) {
      HTMLClassList.add("navbar-vertical-collapsed");
    }
  }, []);
  const NavbarLabel = () => (
    <Nav.Item as="li">
      <Row className="mt-2 mb-2 navbar-vertical-label-wrapper">
        <Col
          xs="auto"
          className="navbar-vertical-label px-1 navbar-vertical-label"
        ></Col>
        <Col className="ps-0">
          <hr className="mb-0 navbar-vertical-divider"></hr>
        </Col>
      </Row>
    </Nav.Item>
  );

  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames("navbar-vertical", {
        [`navbar-${navbarStyle}`]: navbarStyle !== "transparent",
      })}
      variant="light"
    >
      <Flex alignItems="center px-3 pb-2">
        <ToggleButton />
        <Logo at="navbar-vertical" width={130} />
      </Flex>
      <Navbar.Collapse
        in={showBurgerMenu}
        className={"test"}
        style={{
          backgroundImage:
            navbarStyle === "vibrant"
              ? `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbar})`
              : "none",
        }}
      >
        <div className="navbar-vertical-content scrollbar">
          <Nav className="flex-column px-3" as="ul">
            {routerData.map((route) => (
              <Fragment key={route.label}>
                {!route.labelDisable && (
                  <NavbarLabel label={capitalize(route.label)} />
                )}
                <NavbarVerticalMenu routes={route.children} />
              </Fragment>
            ))}
          </Nav>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarVertical;
