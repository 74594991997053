import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { createMarkup } from "helpers/utils";
import Avatar from "../common/Avatar";

const Notification = ({ avatar, time, className, unread, flush, children }) => (
  <Link
    className={classNames(
      "notification",
      { "notification-flush": flush },
      className
    )}
    to="#!"
  >
    {avatar && (
      <div className="notification-avatar">
        <Avatar {...avatar} className="me-3" />
      </div>
    )}
    <div className="notification-body">
      <p className="mb-1" dangerouslySetInnerHTML={createMarkup(children)} />
      <span className="notification-time">{time}</span>
      <span
        className={`read-status ${unread ? "bg-green" : "bg-secondary"}`}
      ></span>
    </div>
  </Link>
);

Notification.propTypes = {
  avatar: PropTypes.shape(Avatar.propTypes),
  time: PropTypes.string.isRequired,
  className: PropTypes.string,
  unread: PropTypes.bool,
  flush: PropTypes.bool,
  emoji: PropTypes.string,
  children: PropTypes.node,
};

Notification.defaultProps = { unread: false, flush: false };

export default Notification;
