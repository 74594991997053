import * as pipelineActions from "../../actions/pipelineActions/constants";

const initialState = {
  pipelines: [],
  pipelinesTriggers: [],
  pipeline: {},
  pipelineData: { dontCreated: true },
  options: {
    trigger_entities: [],
    condition_actions: {
      number: [],
      string: [],
    },
    action_types: [],
    trigger_actions: [],
    timeout_types: [],
    time_units: [],
  },
  fields: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case pipelineActions.SET_PIPELINE:
      return {
        ...state,
        pipelines: action.payload,
      };
    case pipelineActions.SET_PIPELINE_TRIGGER:
      return {
        ...state,
        pipelinesTriggers: action.payload,
      };
    case pipelineActions.SET_PIPELINE_DATA:
      return {
        ...state,
        pipelineData: action.payload,
      };
    case pipelineActions.DELETE_PIPELINE_DATA:
      return {
        ...state,
        pipelineData: { dontCreated: true },
      };
    case pipelineActions.SET_PIPELINE_SINGLE:
      return {
        ...state,
        pipeline: action.payload,
      };
    case pipelineActions.SET_FIELD:
      return {
        ...state,
        fields: action.payload,
      };
    case pipelineActions.PIPELINE_OPTIONS:
      return {
        ...state,
        options: {
          ...state.options,
          action_types: action.payload["action-types"],
          time_units: action.payload["time-units"],
          timeout_types: action.payload["timeout-types"],
          trigger_entities: action.payload["trigger-entities"],
          trigger_actions: action.payload["trigger-actions"],
          condition_actions: action.payload["condition-actions"],
        },
      };
    default:
      return state;
  }
};
