import { NewRequest } from "../../helpers/axios";
import { DELETE, GET, POST, PUT } from "../../helpers/axiosConst";
import { API_URLS, BuildAPIUrl, URLS_LOCAL } from "../../utils/config/URLS";
import {
  AddOpportunities,
  AddSelectedOpportunities,
  SetOpportunitiesOptions,
  SetOpportunitiesWeightAction,
} from "../../store/actions/opportunitiesActions";
import { toast } from "react-toastify";
import { newColumns } from "../../helpers/kanbanHelpers";

export const GetOpportunities = () => {
  return (dispatch) => {
    NewRequest(GET, BuildAPIUrl(API_URLS.GET_OPPORTUNITIES), null, (res) => {
      dispatch(AddOpportunities(res.data.length !== 0 ? res.data : []));
    });
  };
};

export const GetDealsAll = (id, setDeals) => {
  NewRequest(
    GET,
    BuildAPIUrl(`${API_URLS.GET_OPPORTUNITIES}/${id}/deals`),
    null,
    (res) => {
      setDeals(res.data);
    }
  );
};

export const GetOpportunitiesStatuses = () => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(API_URLS.GET_OPPORTUNITIES_OPTIONS),
      null,
      (res) => {
        dispatch(SetOpportunitiesOptions(res.data));
      }
    );
  };
};

export const GetOpportunitiesSelectedBoard = (id) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(`${API_URLS.GET_OPPORTUNITIES}/${id}`),
      null,
      (res) => {
        dispatch(AddSelectedOpportunities(res.data));
      }
    );
  };
};

export const SetOpportunitiesBoard = (data, navigate, params) => {
  const newDashboard = {
    title: data.name,
  };
  return (dispatch) => {
    NewRequest(
      POST,
      BuildAPIUrl(API_URLS.GET_OPPORTUNITIES),
      newDashboard,
      (res) => {
        toast.success("Dashboard created");
        dispatch(GetOpportunities());
        if (params === "create-opportunities") {
          navigate(`/opportunities/${res.data.ID}`);
        }
      }
    );
  };
};

export const EditOpportunitiesBoardAction = (data, currentBoard) => {
  const newDashboard = {
    title: data.name,
    displayOptions: currentBoard.displayOptions,
  };
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(`${API_URLS.GET_OPPORTUNITIES}/${currentBoard.ID}`),
      newDashboard,
      (res) => {
        toast.success("Dashboard edited");
        dispatch(GetOpportunities());
        dispatch(AddSelectedOpportunities(res.data));
      }
    );
  };
};

export const DeleteOpportunitiesBoardAction = (id, board, navigate) => {
  return (dispatch) => {
    NewRequest(
      DELETE,
      BuildAPIUrl(`${API_URLS.GET_OPPORTUNITIES}/${id}`),
      null,
      () => {
        toast.success("Dashboard deleted");
        dispatch(
          SetOpportunitiesWeightAction({
            weight: {},
          })
        );
        dispatch(GetOpportunities());
        let newSelected = board.filter((item) => item.ID !== id);
        dispatch(AddSelectedOpportunities(newSelected[0]));
        navigate(
          `/opportunities/${
            newSelected.length > 0 ? newSelected[0].ID : "create-opportunities"
          }`
        );
      }
    );
  };
};

export const AddStageAction = (id, data, stagesWeight, opportunitiesItems) => {
  const oldItems = opportunitiesItems.map((item) => item.id);
  return (dispatch) => {
    NewRequest(
      POST,
      BuildAPIUrl(`${API_URLS.GET_OPPORTUNITIES}/${id}/stages`),
      data,
      (res) => {
        toast.success("Stage added");
        console.log(res.data);
        dispatch(
          SetStageWeight(id, {
            weight: [
              ...stagesWeight,
              ...newColumns(Object.keys(res.data.stages), oldItems),
            ],
          })
        );
        dispatch(GetOpportunities());
        dispatch(AddSelectedOpportunities(res.data));
      }
    );
  };
};

export const SetStageWeight = (id, data) => {
  return (dispatch) => {
    NewRequest(
      POST,
      BuildAPIUrl(`${API_URLS.GET_OPPORTUNITIES}/${id}/stages/weight`),
      data,
      (res) => {
        dispatch(AddSelectedOpportunities(res.data));
      }
    );
  };
};

export const EditStageAction = (id, data, stagesId) => {
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(`${API_URLS.GET_OPPORTUNITIES}/${id}/stages/${stagesId}`),
      data,
      (res) => {
        toast.success("Stage changed");
        dispatch(GetOpportunities());
        dispatch(AddSelectedOpportunities(res.data));
      }
    );
  };
};

export const DeleteStageAction = (
  id,
  stageId,
  transferId,
  stagesWeight,
  noEmpty,
  weight
) => {
  const data = { transferColumnID: transferId };
  const transferData = weight.weight[data.transferColumnID] ?? [];
  return (dispatch) => {
    NewRequest(
      DELETE,
      BuildAPIUrl(`${API_URLS.GET_OPPORTUNITIES}/${id}/stages/${stageId}`),
      data,
      (res) => {
        toast.success("Stage deleted");
        dispatch(
          SetStageWeight(id, {
            weight: [...stagesWeight].filter((item) => item !== stageId),
          })
        );
        if (noEmpty) {
          dispatch(
            SetOpportunitiesWeight(
              {
                weight: {
                  ...weight.weight,
                  [data.transferColumnID]: [
                    ...transferData,
                    ...weight.weight[stageId],
                  ],
                },
              },
              id
            )
          );
        }
        dispatch(GetOpportunities());
        dispatch(AddSelectedOpportunities(res.data));
      }
    );
  };
};

export const GetOpportunitiesWeight = (id) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(`${API_URLS.GET_OPPORTUNITIES}/${id}/deals/weight`),
      null,
      (res) => {
        if (res.data === null) {
          dispatch(
            SetOpportunitiesWeightAction({
              weight: {},
            })
          );
        } else {
          dispatch(SetOpportunitiesWeightAction(res.data));
        }
      },
      () => {
        dispatch(
          SetOpportunitiesWeightAction({
            weight: {},
          })
        );
      }
    );
  };
};

export const SetOpportunitiesWeight = (data, id) => {
  return (dispatch) => {
    NewRequest(
      POST,
      BuildAPIUrl(`${API_URLS.GET_OPPORTUNITIES}/${id}/deals/weight`),
      data,
      (res) => {
        dispatch(SetOpportunitiesWeightAction(res.data));
      }
    );
  };
};

export const AddDealsAttachment = (
  data,
  id,
  dealId,
  setDeals,
  setFormData,
  setActivity
) => {
  return (dispatch) => {
    NewRequest(
      POST,
      BuildAPIUrl(
        `${API_URLS.GET_OPPORTUNITIES}/${id}/deals/${dealId}/attachments`
      ),
      data,
      () => {
        toast.success("Attachments added");
        GetDealsAll(id, setDeals);
        dispatch(GetDealsAction(id, dealId, setFormData));
        dispatch(GetDealsActivity(id, dealId, setActivity));
      }
    );
  };
};

export const DeleteDealsAttachment = (
  id,
  dealId,
  attachment,
  setDeals,
  setFormData,
  setActivity
) => {
  return (dispatch) => {
    NewRequest(
      DELETE,
      BuildAPIUrl(
        `${API_URLS.GET_OPPORTUNITIES}/${id}/deals/${dealId}/attachments/${attachment}`
      ),
      null,
      () => {
        toast.success("Attachments deleted");
        GetDealsAll(id, setDeals);
        dispatch(GetDealsAction(id, dealId, setFormData));
        dispatch(GetDealsActivity(id, dealId, setActivity));
      }
    );
  };
};

export const SetDeals = (data, id, weight, deals, setDeals) => {
  return (dispatch) => {
    NewRequest(
      POST,
      BuildAPIUrl(`${API_URLS.GET_OPPORTUNITIES}/${id}/deals`),
      data,
      (res) => {
        toast.success("Deal created");
        setDeals([...deals, res.data]);
        const oldData = weight.weight[res.data.opportunityStageID] ?? [];
        dispatch(
          SetOpportunitiesWeight(
            {
              weight: {
                ...weight.weight,
                [res.data.opportunityStageID]: [...oldData, res.data.ID],
              },
            },
            res.data.opportunityBoardID
          )
        );
      }
    );
  };
};

export const DeleteDeals = (id, dealId, weight, columnId, deals, setDeals) => {
  return (dispatch) => {
    NewRequest(
      DELETE,
      BuildAPIUrl(`${API_URLS.GET_OPPORTUNITIES}/${id}/deals/${dealId}`),
      null,
      () => {
        toast.success("Deal deleted");
        setDeals([...deals].filter((item) => item.ID !== dealId));
        dispatch(
          SetOpportunitiesWeight(
            {
              weight: {
                ...weight.weight,
                [columnId]: weight.weight[columnId].filter(
                  (item) => item !== dealId
                ),
              },
            },
            id
          )
        );
      }
    );
  };
};

export const EditDealsAction = (
  data,
  id,
  dealId,
  deals,
  setDeals,
  setFormData,
  setActivity = () => false,
  opportunitiesDispatch = () => false,
  opportunitiesState = false
) => {
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(`${API_URLS.GET_OPPORTUNITIES}/${id}/deals/${dealId}`),
      data,
      (res) => {
        const data = {
          title: res.data.title,
          opportunityStageID: res.data.opportunityStageID,
          description: res.data.description,
          createdAt: res.data.createdAt,
          updatedAt: res.data.updatedAt,
          createdBy: res.data.createdBy,
          color: res.data.color,
          assigned: res.data.assigned,
          productItems: res.data.productItems,
          attachments: res.data.attachments,
        };
        toast.success("Task updated");
        setFormData(data);
        opportunitiesDispatch({
          ...opportunitiesState,
          currentTask: res.data,
        });
        setDeals(
          [...deals].map((item) => {
            if (item.ID === dealId) {
              return res.data;
            } else {
              return item;
            }
          })
        );
        dispatch(GetDealsActivity(id, dealId, setActivity));
      }
    );
  };
};

export const GetDealsAction = (
  id,
  dealId,
  setFormData,
  products = [],
  setLoaded = () => false
) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(`${API_URLS.GET_OPPORTUNITIES}/${id}/deals/${dealId}`),
      null,
      (res) => {
        const data = {
          title: res.data.title,
          createdAt: res.data.createdAt,
          updatedAt: res.data.updatedAt,
          createdBy: res.data.createdBy,
          resolved: res.data.resolved,
          opportunityStageID: res.data.opportunityStageID,
          description: res.data.description,
          color: res.data.color,
          assigned: res.data.assigned,
          productItems: res.data.productItems,
          attachments: res.data.attachments,
        };
        setFormData(data);
        setLoaded(false);
      }
    );
  };
};

export const GetDealsActivity = (
  id,
  dealId,
  setActivity,
  setLoaded = () => false
) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(
        `${API_URLS.GET_OPPORTUNITIES}/${id}/deals/${dealId}/activity-log`
      ),
      null,
      (res) => {
        setActivity(res.data);
        setLoaded(false);
      }
    );
  };
};

export const Transactions = () => {
  NewRequest(GET, BuildAPIUrl(API_URLS.GET_TRANSACTIONS), null, (res) => {
    console.log(res.data);
  });
};

export const UpdateDealsAction = (
  data,
  id,
  dealId,
  source,
  destination,
  setDeals,
  deals,
  opportunitiesDispatch,
  opportunitiesState,
  weight,
  actionType
) => {
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(
        `${API_URLS.GET_OPPORTUNITIES}/${id}/deals/${dealId}/${actionType}`
      ),
      data,
      (res) => {
        dispatch(SetOpportunitiesWeight(weight, res.data.opportunityBoardID));
      },
      () => {
        setDeals(
          deals.map((item) => {
            if (item.ID === dealId) {
              return {
                ...item,
                opportunityStageID: source,
              };
            } else {
              return item;
            }
          })
        );
        opportunitiesDispatch({
          ...opportunitiesState,
          weight: {
            weight: {
              ...opportunitiesState.weight.weight,
              [destination]: opportunitiesState.weight.weight[
                destination
              ]?.filter((item) => item !== dealId),
              [source]: [...opportunitiesState.weight.weight[source]],
            },
          },
        });
      }
    );
  };
};

export const SendOpportunitiesOptions = (id, data) => {
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(`${API_URLS.GET_OPPORTUNITIES}/${id}`),
      data,
      (res) => {
        dispatch(AddSelectedOpportunities(res.data));
      }
    );
  };
};
