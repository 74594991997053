import * as currenciesActions from "../../actions/currenciesActions/constants";

const initialState = {
  currencies: [],
  mainCurrency: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case currenciesActions.SET_CURRENCIES:
      return {
        ...state,
        currencies: action.payload,
      };
    case currenciesActions.SET_MAIN_CURRENCIES:
      return {
        ...state,
        mainCurrency: action.payload,
      };
    default:
      return state;
  }
};
