import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import Avatar from "../../common/Avatar";
import { UserSignOutAction } from "../../../requests/userRequests/index";

const ProfileDropdown = () => {
  function LogOutFunc(e) {
    e.preventDefault();
    UserSignOutAction();
  }
  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item as={Link} to={`/settings/user/profile`}>
            Profile &amp; account
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item onClick={LogOutFunc}>Logout</Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
