import { NewRequest } from "../../helpers/axios";
import { GET, POST } from "../../helpers/axiosConst";
import { API_URLS, BuildAPIUrl, URLS_LOCAL } from "../../utils/config/URLS";
import {
  AddContract,
  SetContractList,
} from "../../store/actions/contractActions";
import { toast } from "react-toastify";

export const GetContractList = (
  params = false,
  setState = () => false,
  state = {},
  setOnLoad = () => false
) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(API_URLS.GET_CONTRACT),
      {},
      (resp) => {
        setOnLoad(true);
        const { data, pages, total } = resp.data;
        setState({
          ...state,
          isFetchData: false,
          data,
          pages,
          total,
          params: {
            ...state.params,
            page: Number(params.page),
            perPage: Number(params.perPage),
            filtered: params.filtered,
            sorted: params.sorted,
            conditions: params.conditions,
          },
        });
        dispatch(SetContractList(resp.data.data));
      },
      () => {
        setOnLoad(true);
      },
      params && {
        page: Number(params.page),
        perPage: Number(params.perPage),
        sorted: params.sorted,
        filtered: params.filtered,
        conditions: params.conditions,
      }
    );
  };
};

export const CreateContract = (data, navigate) => {
  NewRequest(POST, BuildAPIUrl(API_URLS.GET_CONTRACT), { data }, () => {
    toast.success(`Contract added`);
    navigate(URLS_LOCAL.USER.PRODUCT);
  });
};

export const GetContract = () => {
  return (dispatch) => {
    NewRequest(GET, BuildAPIUrl(API_URLS.CONSTRUCTOR_CONTRACT), null, (res) => {
      dispatch(AddContract(res.data));
    });
  };
};
