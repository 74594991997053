import * as userActions from "./constants";

export function UserStatusList(data) {
  return {
    type: userActions.SET_STATUS_LIST,
    payload: data,
  };
}
export function SetRelatedOptions(data) {
  return {
    type: userActions.SET_RELATED_OPTIONS,
    payload: data,
  };
}
export function setCountries(data) {
  return {
    type: userActions.SET_COUNTRIES,
    payload: data,
  };
}

export function setUsersAction(data) {
  return {
    type: userActions.SET_USERS,
    payload: data,
  };
}

export function setAvatar(data) {
  return {
    type: userActions.SET_AVATAR,
    payload: data,
  };
}
export const ChangeMail = (data) => {
  return {
    type: userActions.MAIL_CHANGE,
    payload: data,
  };
};

export const LoggedIn = () => {
  return {
    type: userActions.LOGGED_IN,
  };
};

export const SetUserInfo = (data) => {
  return {
    type: userActions.SET_USER_INFO,
    payload: data,
  };
};

export const GetTwoFactor = (data) => {
  return {
    type: userActions.TWO_FACTOR,
    payload: data,
  };
};

export const SetTwoFactor = (data) => {
  return {
    type: userActions.SET_TWO_FACTOR,
    payload: data,
  };
};
export const DeleteTwoFactor = (data) => {
  return {
    type: userActions.DELETE_TWO_FACTOR,
    payload: data,
  };
};

export const UserUpdateInfo = (data) => {
  return {
    type: userActions.USER_UPDATE_INFO,
    payload: data,
  };
};

export const UserSignInActionSuccess = (data) => {
  return {
    type: userActions.USER_SIGN_IN_ACTION_SUCCESS,
    payload: data,
  };
};

export const UserSignInActionFailure = (data) => {
  return {
    type: userActions.USER_SIGN_IN_ACTION_FAILURE,
    data: data,
  };
};

export function UpdateAuthToken() {
  return {
    type: userActions.USER_UPDATE_TOKEN,
  };
}

export function UserRefreshTokenActionSuccess(data) {
  return {
    type: userActions.USER_UPDATE_TOKEN,
    data: data,
  };
}
export function WorkTimeSwitch(data) {
  return {
    type: userActions.WORK_TIME_SWITCH,
    payload: data,
  };
}
export function ChangeEmail(data) {
  return {
    type: userActions.CHANGE_EMAIL,
    payload: data,
  };
}
export function registerOauth(token) {
  return {
    type: userActions.REGISTER_IN_OAUTH,
    payload: token,
  };
}
