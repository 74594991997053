import React from "react";
import DocumentTitle from "react-document-title";
import permission from "../../../src/assets/img/icons/permissions.svg";
import ConfirmButton from "./ConfirmButton";
import { useNavigate } from "react-router";
import { URLS_LOCAL } from "../../utils/config/URLS";

const NotPermissions = () => {
  const navigate = useNavigate();
  return (
    <DocumentTitle title="Not Permissions | Sellbyx">
      <div
        className={
          "d-flex flex-column align-items-center justify-content-center p-4 not-permission"
        }
      >
        <img src={permission} alt="not-permissions" />
        <h1>Sorry but you do not have permission to view this page</h1>
        <ConfirmButton
          text={"To main"}
          classNames={"mt-3"}
          handler={() => navigate(URLS_LOCAL.USER.DASHBOARD)}
        />
      </div>
    </DocumentTitle>
  );
};

export default NotPermissions;
