import { NewRequest } from "../../helpers/axios";
import { DELETE, GET, POST } from "../../helpers/axiosConst";
import { API_URLS, BuildAPIUrl, URLS_LOCAL } from "../../utils/config/URLS";
import { toast } from "react-toastify";
import { SetZoomSettings, ZoomSettings } from "../../store/actions/zoomActions";

export const GetZoomUrl = (setZoomLink) => {
  NewRequest(GET, BuildAPIUrl(API_URLS.GET_URL_ZOOM), null, (res) => {
    setZoomLink(res.data.message);
  });
};

export const GetZoomStatus = () => {
  return (dispatch) => {
    NewRequest(GET, BuildAPIUrl(API_URLS.GET_ZOOM_STATUS), null, (res) => {
      dispatch(SetZoomSettings(res.data));
      if (res.data.settings.OauthSettings.length > 0) {
        dispatch(ZoomSettings(true));
      } else {
        dispatch(ZoomSettings(false));
      }
    });
  };
};

export const DeleteZoomIntegration = (id) => {
  return (dispatch) => {
    NewRequest(
      DELETE,
      BuildAPIUrl(`${API_URLS.DELETE_ZOOM_INTEGRATION}/${id}`),
      null,
      () => {
        toast.success(`Successfully delete witch zoom`);
        dispatch(ZoomSettings(false));
      }
    );
  };
};

export const ZoomOauthConfirm = (code, navigate) => {
  return (dispatch) => {
    NewRequest(POST, BuildAPIUrl(API_URLS.ADD_ZOOM_TOKEN), code, () => {
      toast.success(`Successfully integrate witch zoom`);
      dispatch(ZoomSettings(true));
      navigate(URLS_LOCAL.USER.ZOOM);
    });
  };
};
