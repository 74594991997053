import React from "react";
import { Col, Row } from "react-bootstrap";
import { URL_FULL, URL_HOST } from "../../utils/config/URLS";

const Footer = () => (
  <footer className="footer container">
    <Row className="justify-content-between text-center fs--1 mt-2 mb-4">
      <Col sm="auto">
        <p className="mb-0 text-600">
          <br className="d-sm-none" /> {new Date().getFullYear()} &copy;{" "}
          <a href={URL_FULL} style={{ color: "#2A85FF" }}>
            {URL_HOST}
          </a>
        </p>
      </Col>
    </Row>
  </footer>
);

export default Footer;
