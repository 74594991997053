import React from "react";
import {
  CONSTRUCTOR_DATA_COMPANIES_COLLECTION,
  SEARCH_URLS,
} from "../constants";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const ConstructorDataCompanies = ({ elem }) => {
  return (
    <Link
      className={"search_link"}
      to={SEARCH_URLS[CONSTRUCTOR_DATA_COMPANIES_COLLECTION](elem.mongo_id)}
    >
      Company: {elem.fields.company_name}
    </Link>
  );
};

ConstructorDataCompanies.propTypes = {
  elem: PropTypes.object,
};

export default ConstructorDataCompanies;
