import { NewRequest, NewRequestProgress } from "../../helpers/axios";
import { DELETE, GET, POST, PUT } from "../../helpers/axiosConst";
import { API_URLS, BuildAPIUrl, URLS_LOCAL } from "../../utils/config/URLS";
import {
  AddTemplateAction,
  DeleteTemplateData,
  EmailSettings,
  GetMailAction,
  GetTemplateAction,
  MailVerify,
  OauthSettings,
  SetBbCode,
  setMailStatus,
  SetMessage,
  SetMessages,
} from "../../store/actions/mailActions";
import { toast } from "react-toastify";

export const GetMail = (setOnLoad = () => false) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(API_URLS.GET_MAIL),
      null,
      (res) => {
        setOnLoad(true);
        dispatch(GetMailAction(res.data.domains));
      },
      () => {
        setOnLoad(true);
        dispatch(GetMailAction([]));
      }
    );
  };
};

export const DeleteMail = (data) => {
  return (dispatch) => {
    NewRequest(DELETE, BuildAPIUrl(API_URLS.GET_MAIL), data, (res) => {
      toast.success(`Successfully add mails`);
      dispatch(GetMail());
    });
  };
};

export const GetTemplates = (setOnLoad = () => false) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(API_URLS.GET_TEMPLATES),
      null,
      (res) => {
        setOnLoad(true);
        dispatch(GetTemplateAction(res.data.templateList));
      },
      () => {
        setOnLoad(true);
        dispatch(GetTemplateAction([]));
      }
    );
  };
};

export const AddTemplate = (name, value, navigate) => {
  const newData = {
    html: value,
    json: JSON.stringify(value),
    name: name,
  };
  return (dispatch) => {
    NewRequest(POST, BuildAPIUrl(API_URLS.GET_TEMPLATES), newData, (res) => {
      dispatch(AddTemplateAction(res.data.template));
      dispatch(DeleteTemplateData());
      dispatch(GetTemplates());
      const channel = new BroadcastChannel("template");
      channel.postMessage(res.data.template);
      toast.success(`Successfully add templates`);
      navigate(`/settings/template/${res.data.template.ID}/edit`);
    });
  };
};

export const EditTemplate = (name, value, id) => {
  const newData = {
    html: value,
    json: JSON.stringify(value),
    name: name,
  };
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(`${API_URLS.GET_TEMPLATES}/${id}`),
      newData,
      () => {
        dispatch(GetTemplates());
        toast.success(`Successfully add templates`);
      }
    );
  };
};

export const DeleteTemplate = (id) => {
  return (dispatch) => {
    NewRequest(
      DELETE,
      BuildAPIUrl(`${API_URLS.GET_TEMPLATES}/${id}`),
      null,
      (res) => {
        dispatch(GetTemplates());
        const channel = new BroadcastChannel("template");
        channel.postMessage(res.data);
        toast.success(`Successfully delete templates`);
      }
    );
  };
};

export const AddMail = (data) => {
  return (dispatch) => {
    NewRequest(POST, BuildAPIUrl(API_URLS.GET_MAIL), data, (res) => {
      toast.success(`Successfully add mails`);
      dispatch(GetMail());
    });
  };
};

export const VerifyCode = (verify) => {
  NewRequest(POST, BuildAPIUrl(API_URLS.MAIL_VERIFY), verify, () => {
    toast.success(`Check your mail`);
  });
};

export const VerifyEnterCode = (data, setVerifyed) => {
  return (dispatch) => {
    NewRequest(POST, BuildAPIUrl(API_URLS.VERIFY_CONFIRM), data, (res) => {
      dispatch(MailVerify(res.data.mailing_address));
      toast.success(`Verify complete`);
      setVerifyed({
        pinCode: "",
      });
    });
  };
};

export const MailSettingsAdd = (data) => {
  return (dispatch) => {
    NewRequest(POST, BuildAPIUrl(API_URLS.MAIL_SETTINGS_ADD), data, (res) => {
      dispatch(MailSettingsStatus());
      toast.success(`Successfully add mails settings`);
      dispatch(EmailSettings(true));
    });
  };
};

export const MailSettingsStatus = () => {
  return (dispatch) => {
    NewRequest(GET, BuildAPIUrl(API_URLS.MAIL_STATUS), null, (res) => {
      if (
        res.data.settings.EmailSettings != null &&
        res.data.settings.EmailSettings.length > 0
      ) {
        dispatch(EmailSettings(true));
      } else {
        dispatch(EmailSettings(false));
      }
      if (
        res.data.settings.OauthSettings != null &&
        res.data.settings.OauthSettings.length > 0
      ) {
        dispatch(OauthSettings(true));
      } else {
        dispatch(OauthSettings(false));
      }
      dispatch(setMailStatus(res.data));
    });
  };
};

export const GetGoogleUrl = (setGoogleLink) => {
  NewRequest(GET, BuildAPIUrl(API_URLS.GET_GOOGLE_URL), null, (res) => {
    setGoogleLink(res.data);
  });
};

export const MailSettingsDelete = (id) => {
  return (dispatch) => {
    NewRequest(
      DELETE,
      BuildAPIUrl(`${API_URLS.MAIL_SETTINGS_DELETE}/${id}`),
      null,
      (res) => {
        dispatch(MailSettingsStatus());
        toast.success(`Successfully delete mails settings`);
        dispatch(EmailSettings(false));
      }
    );
  };
};

export const MailSettingsDeleteOauth = () => {
  return (dispatch) => {
    NewRequest(DELETE, BuildAPIUrl(API_URLS.MAIL_DELETE_OAUTH), null, () => {
      dispatch(MailSettingsStatus());
      toast.success(`Successfully delete mails settings`);
      dispatch(OauthSettings(false));
    });
  };
};

export const OauthConfirm = (code, navigate) => {
  return (dispatch) => {
    NewRequest(POST, BuildAPIUrl(API_URLS.MAIL_OAUTH_CONFIRM), code, () => {
      toast.success(`Successfully integrate witch google`);
      dispatch(OauthSettings(true));
      navigate(URLS_LOCAL.USER.MAIL_SETTINGS);
    });
  };
};

export const GetMessage = (
  id,
  params = {},
  emailState = false,
  setEmailState = () => false
) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(`/api/clients/${id}/messages`),
      null,
      (res) => {
        if (emailState) {
          setEmailState({
            ...emailState,
            loader: false,
            params: { ...params },
          });
        }
        dispatch(SetMessages(res.data));
      },
      () => {
        if (emailState) {
          setEmailState({
            ...emailState,
            loader: false,
            params: { ...params },
          });
        }
      },
      params
    );
  };
};

export const GetMessageOpen = (id, messageID, params) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(`/api/clients/${id}/messages/open/${messageID}`),
      null,
      (res) => {
        let data = {
          ...res.data.message,
          prevID: res.data.prevID,
          nextID: res.data.nextID,
        };
        dispatch(
          ChangeStatusMessage(
            id,
            {
              messageIDs: [messageID],
              status: 2,
            },
            params
          )
        );
        dispatch(SetMessage(data));
      },
      () => false
    );
  };
};

export const ChangeStatusMessage = (
  clientID,
  data,
  params,
  setReadStatus = () => false
) => {
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(`/api/clients/${clientID}/messages/status`),
      data,
      () => {
        dispatch(GetMessage(clientID, params));
      },
      () => {
        setReadStatus((readStatus) => (readStatus == 1 ? 2 : 1));
      }
    );
  };
};

export const ChangeStarsMessage = (clientID, data, params, setMarked) => {
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(`/api/clients/${clientID}/messages/star`),
      data,
      () => {
        dispatch(GetMessage(clientID, params));
      },
      () => {
        setMarked((marked) => !marked);
      }
    );
  };
};

export const MessageDelete = (
  clientID,
  data,
  params,
  navigate,
  emailState = [],
  setEmailState = false
) => {
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(`/api/clients/${clientID}/messages/deleted`),
      data,
      () => {
        if (
          data.messageIDs.length >= emailState.emails.length &&
          emailState.pages === emailState.params.page
        ) {
          dispatch(GetMessage(clientID, { ...params, page: params.page - 1 }));
          if (setEmailState) {
            setEmailState({
              ...emailState,
              params: {
                ...emailState.params,
                page: params.page - 1,
              },
            });
          }
        } else if (
          data.messageIDs.length > emailState.emails.length &&
          emailState.pages !== emailState.params.page
        ) {
          dispatch(
            GetMessage(clientID, {
              ...params,
              page: 1,
            })
          );
          if (setEmailState) {
            setEmailState({
              ...emailState,
              params: {
                ...emailState.params,
                page: 1,
              },
            });
          }
        } else {
          dispatch(GetMessage(clientID, params));
        }
        navigate(`/clients/${clientID}/messages`);
      }
    );
  };
};

export const AttachmentGetIdRequest = (
  files,
  type,
  attachmentsIds,
  setAttachmentsIds,
  setAttachmentProgress,
  progressData,
  setProgressData,
  attachments,
  handleSubmit,
  target
) => {
  let data = new FormData();
  for (let i = 0; i < files.length; i++) {
    data.append(`file`, files[i] ?? null);
  }
  data.append("entity", type);
  NewRequestProgress(
    POST,
    BuildAPIUrl(API_URLS.ATTACHMENT),
    data,
    (res) => {
      handleSubmit(res.data);
      target.value = "";
      const initial = attachments.length;
      const start = files.length;
      if (start > 0) {
        let resultArray = files.map((item, index) => ({
          [initial + index]: false,
        }));
        let result = progressData;
        for (let i = 0; i < resultArray.length; i++) {
          result[initial + i] = resultArray[i][initial + i];
        }
        setProgressData(result);
      }
      setAttachmentsIds([...attachmentsIds, ...res.data]);
    },
    () => false,
    {},
    setAttachmentProgress,
    progressData,
    setProgressData,
    files,
    attachments
  );
};

export const SendMessage = (id, data, params, navigate) => {
  return (dispatch) => {
    NewRequest(POST, BuildAPIUrl(`/api/clients/${id}/messages`), data, () => {
      toast.success(`Message send`);
      dispatch(GetMessage(id, params));
      navigate(`/clients/${id}/messages`);
    });
  };
};

export const BbCodeStatic = () => {
  return (dispatch) => {
    NewRequest(GET, BuildAPIUrl(API_URLS.BB_CODE_STATIC), null, (res) => {
      console.log(res.data);
    });
  };
};

export const BbCodeDynamic = (type) => {
  return (dispatch) => {
    NewRequest(GET, BuildAPIUrl(`${API_URLS.BB_CODE}/${type}`), null, (res) => {
      dispatch(SetBbCode(res.data));
    });
  };
};
