import React from "react";
import { SEARCH_URLS, USER_CALENDAR_EVENT_COLLECTION } from "../constants";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const UserCalendarEvent = ({ elem }) => {
  return (
    <Link
      className={"search_link"}
      to={SEARCH_URLS[USER_CALENDAR_EVENT_COLLECTION]()}
    >
      Event: {elem.title}
    </Link>
  );
};

UserCalendarEvent.propTypes = {
  elem: PropTypes.object,
};

export default UserCalendarEvent;
