import { sendAvatar } from "../requests/userRequests/index";

export function avatarToBlob(avatar, setNewAvatar) {
  const image = new Image();
  image.src = avatar;
  const canvas = document.createElement("canvas");
  canvas.width = 64;
  canvas.height = 64;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(image, 0, 0);
  canvas.toBlob(function (blob) {
    const file = new File([blob], "avatarDefault.png", {
      type: "image/png",
    });
    setNewAvatar(file);
  });
}

export function canvasToBlob(
  avatar,
  dispatch,
  setProgress,
  setAvatarModal,
  setNewAvatar,
  setEditorData
) {
  avatar.toBlob(function (blob) {
    const file = new File([blob], "avatar.png", {
      type: "image/png",
    });
    dispatch(
      sendAvatar(file, setProgress, setAvatarModal, setNewAvatar, setEditorData)
    );
  });
}
