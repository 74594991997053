import * as dashboardActions from "./constants";

export const AddSelectedBoard = (data) => {
  return {
    type: dashboardActions.SET_SELECTED_BOARD,
    payload: data,
  };
};
export const SetBoardOptions = (data) => {
  return {
    type: dashboardActions.SET_BOARD_OPTIONS,
    payload: data,
  };
};
export const SetWeightAction = (data) => {
  return {
    type: dashboardActions.SET_WEIGHT,
    payload: data,
  };
};
export const AddBoard = (data) => {
  return {
    type: dashboardActions.SET_BOARD,
    payload: data,
  };
};
