import { NewRequestSignal } from "../../helpers/axios";
import { GET } from "../../helpers/axiosConst";
import { API_URLS, BuildAPIUrl } from "../../utils/config/URLS";
import { SetSearchData } from "../../store/actions/searchActions";

export const GetSearchData = (
  query,
  setSearchResult,
  setLoading = () => false,
  signal
) => {
  return (dispatch) => {
    NewRequestSignal(
      GET,
      BuildAPIUrl(`${API_URLS.SEARCH}?query=${query}`),
      null,
      (res) => {
        setLoading(false);
        dispatch(SetSearchData(res.data));
        setSearchResult(res.data);
      },
      (err) => err,
      {},
      signal
    );
  };
};
