import React from "react";
import PropTypes from "prop-types";
import ModalLayout from "../../../layouts/ModalLayout";

const DeleteModal = ({
  name,
  action,
  modal,
  setModal,
  handleDelete,
  children,
}) => {
  function handleSubmit(e) {
    e.stopPropagation();
    action();
    setModal(false);
  }
  function close() {
    setModal(false);
  }
  return (
    <ModalLayout
      modal={modal}
      close={close}
      title={`Do you really want to delete ${name} ${
        handleDelete ? `"${handleDelete}" ?` : ""
      }`}
      confirmText={"Delete"}
      handleSubmit={handleSubmit}
      className={"delete_modal"}
    >
      {children}
    </ModalLayout>
  );
};

DeleteModal.propTypes = {
  name: PropTypes.string,
  action: PropTypes.func,
  modal: PropTypes.bool,
  setModal: PropTypes.func,
  handleDelete: PropTypes.string,
  children: PropTypes.node,
};

export default DeleteModal;
