import React, { useState } from "react";
import ContextMenu from "./ContextMenu";
import DeleteModal from "./modal/DeleteModal";

const NotificationContextButtons = () => {
  const [deleteAll, setDeleteAll] = useState(false);
  const contextParams = [
    { name: "Unmark All" },
    { name: "Delete All", handler: () => setDeleteAll(true) },
  ];
  function handleDeleteAll() {}
  return (
    <>
      <ContextMenu params={contextParams} />
      <DeleteModal
        modal={deleteAll}
        setModal={setDeleteAll}
        name={"all notifications"}
        action={handleDeleteAll}
      />
    </>
  );
};

export default NotificationContextButtons;
