import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import user from "./userReducer";
import domain from "./domainReducer";
import mail from "./mailReducer";
import organization from "./organizationReducer";
import card from "./cardReducer";
import role from "./roleReducer";
import clients from "./clientsReducer";
import calendar from "./calendarReduser";
import zoom from "./zoomReducer";
import company from "./companyReducer";
import product from "./productReducer";
import dashboard from "./dashboardReducer";
import comments from "./commentsReducer";
import contract from "./contractReducer";
import pipeline from "./pipelineReducer";
import boards from "./boardReducer";
import filter from "./filterReducer";
import opportunities from "./opportunitiesReducer";
import currencies from "./currenciesReducer";
import form from "./formReducer";
import search from "./searchReducer";
import table from "./tableReducer";
import navbar from "./navbarReducer";

const rootPersistConfig = {
  key: "root",
  storage,
  whitelist: [
    "user",
    "role",
    "mail",
    "clients",
    "calendar",
    "organization",
    "zoom",
    "company",
    "product",
    "dashboard",
    "comments",
    "contract",
    "pipeline",
    "boards",
    "filter",
    "opportunities",
    "currencies",
    "form",
    "card",
    "table",
    "navbar",
  ],
};

const rootReducer = combineReducers({
  user,
  domain,
  mail,
  organization,
  card,
  role,
  clients,
  calendar,
  zoom,
  company,
  product,
  dashboard,
  comments,
  contract,
  pipeline,
  boards,
  filter,
  opportunities,
  currencies,
  form,
  search,
  table,
  navbar,
});

export default persistReducer(rootPersistConfig, rootReducer);
