import * as navbarActions from "./constants";

export const SetNavbarPages = (data) => {
  return {
    type: navbarActions.SET_NAVBAR_PAGES,
    payload: data,
  };
};
export const SetNavbarSettings = (data) => {
  return {
    type: navbarActions.SET_NAVBAR_SETTINGS,
    payload: data,
  };
};
export const DeleteNavbarSettings = (data) => {
  return {
    type: navbarActions.SET_DELETE_SETTINGS,
    payload: data,
  };
};
export const UpdateNavbar = (data) => {
  return {
    type: navbarActions.UPDATE_NAVBAR,
    payload: data,
  };
};
