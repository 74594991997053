import React, { useState } from "react";
import PropTypes from "prop-types";
import { isIterableArray } from "helpers/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Flex from "./Flex";
import classNames from "classnames";
import add from "../../../src/assets/img/illustrations/add_button.svg";
import { useSelector } from "react-redux";
import ava1 from "../../assets/img/illustrations/ava-5.png";
import ChangeAvatarModal from "./modal/ChangeAvatarModal";

const Avatar = ({
  size,
  change,
  rounded,
  name,
  className,
  mediaClass,
  isExact,
  icon,
}) => {
  const classNames = ["avatar", `avatar-${size}`, className].join(" ");
  const avatar = useSelector((store) => store.user.avatar);
  const [avatarModal, setAvatarModal] = useState(false);
  const mediaClasses = [
    rounded ? `rounded-${rounded}` : "rounded",
    mediaClass,
  ].join(" ");
  const getAvatar = () => {
    if (avatar != "") {
      if (isIterableArray(avatar)) {
        return (
          <div className={`${mediaClasses} overflow-hidden h-100 d-flex`}>
            <div className="w-50 border-right">
              <img src={avatar[0]} alt="" />
            </div>
            <div className="w-50 d-flex flex-column">
              <img src={avatar[1]} alt="" className="h-50 border-bottom" />
              <img src={avatar[2]} alt="" className="h-50" />
            </div>
          </div>
        );
      } else {
        return (
          <img className={mediaClasses} src={`https://${avatar}`} alt="" />
        );
      }
    }

    if (name) {
      return (
        <div className={`avatar-name ${mediaClasses}`}>
          <span>{isExact ? name : name.match(/\b\w/g).join("")}</span>
        </div>
      );
    }

    if (icon) {
      return (
        <Flex className={`avatar-name ${mediaClasses}`}>
          <FontAwesomeIcon icon={icon} />
        </Flex>
      );
    }

    return (
      <div className={`avatar-emoji ${mediaClasses}`}>
        <img className={mediaClasses} src={ava1} alt="icon" />
      </div>
    );
  };

  return (
    <div className={`${classNames} position-relative`}>
      {getAvatar()}
      {change ? (
        <div className={"add_avatar"}>
          <img src={add} alt="icon" onClick={() => setAvatarModal(true)} />
          <ChangeAvatarModal
            avatarModal={avatarModal}
            setAvatarModal={setAvatarModal}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export const AvatarGroup = ({ children, dense, className }) => {
  return (
    <div
      className={classNames(className, "avatar-group", {
        "avatar-group-dense": dense,
      })}
    >
      {children}
    </div>
  );
};

Avatar.propTypes = {
  change: PropTypes.bool,
  size: PropTypes.oneOf(["s", "m", "l", "xl", "2xl", "3xl", "4xl", "5xl"]),
  rounded: PropTypes.string,
  src: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  name: PropTypes.string,
  emoji: PropTypes.string,
  className: PropTypes.string,
  mediaClass: PropTypes.string,
  isExact: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Avatar.defaultProps = {
  size: "xl",
  rounded: "circle",
  emoji: "😊",
  isExact: false,
};

AvatarGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  dense: PropTypes.bool,
};

export default Avatar;
