import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import NavbarTop from "../components/navbar/top/NavbarTop";
import NavbarVertical from "../components/navbar/vertical/NavbarVertical";
import Footer from "../components/footer/Footer";
import classNames from "classnames";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { URLS_LOCAL } from "../utils/config/URLS";
import { useNavigate } from "react-router";
import { GetRole } from "../requests/roleRequests/index";
import DocumentTitle from "react-document-title";
import {
  calendarSettingsStatus,
  GetCalendar,
} from "../requests/calendarRequests/index";
import { GetOrganization } from "../requests/organizationRequests/index";
import { GetZoomStatus } from "../requests/zoomRequests/index";
import { GetEntities } from "../requests/commentsRequests/index";
import { GetBoard } from "../requests/dashboardRequests/index";
import { GetOpportunities } from "../requests/opportunitiesRequests/index";
import { GetAllCurrencies } from "../requests/currencyRequests/index";
import { GetFilters } from "../requests/filterRequests/index";
import { GetCustomList } from "../requests/cardRequests/index";
import {
  GetAllUsers,
  getAvatarRequest,
  getCountries,
  GetRelationOptions,
} from "../requests/userRequests";

const MainLayout = ({ children }) => {
  const { hash, pathname } = useLocation();
  const isKanban = pathname.includes("kanban");
  const dispatch = useDispatch();
  const loggedIn = useSelector((store) => store.user.loggedIn);
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: "start", behavior: "smooth" });
        }
      }
    }, 0);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!loggedIn) {
      localStorage.clear();
      navigate(URLS_LOCAL.AUTH.LOGIN);
    }
  }, [pathname]);
  useEffect(() => {
    if (loggedIn) {
      dispatch(GetRole());
      dispatch(getAvatarRequest());
      dispatch(getCountries());
      dispatch(GetAllUsers());
      dispatch(calendarSettingsStatus());
      dispatch(GetCalendar());
      dispatch(GetOrganization());
      dispatch(GetZoomStatus());
      dispatch(GetEntities());
      dispatch(GetFilters());
      dispatch(GetRelationOptions());
      dispatch(GetBoard());
      dispatch(GetCustomList());
      dispatch(GetOpportunities());
      dispatch(GetAllCurrencies());
    }
  }, [loggedIn]);
  return (
    <DocumentTitle title="Load | Sellbyx">
      <div className={"container-fluid"}>
        <NavbarVertical />
        <div className={classNames("content", { "pb-0": isKanban })}>
          <NavbarTop />
          {children}
          <Outlet />
          <Footer />
        </div>
      </div>
    </DocumentTitle>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
