import * as roleActions from "./constants";

export const SetOperations = (data) => {
  return {
    type: roleActions.SET_OPERATIONS,
    payload: data,
  };
};

export const ChangeRoleAction = (data) => {
  return {
    type: roleActions.CHANGE_ROLE,
    payload: data,
  };
};

export const AddNewRole = (role) => {
  return {
    type: roleActions.SET_NEW_ROLE,
    payload: role,
  };
};

export const SetRole = (data) => {
  return {
    type: roleActions.GET_ROLE,
    payload: data,
  };
};
