import React from "react";
import { Spinner } from "react-bootstrap";

const Loader = () => (
  <div className={"loader"}>
    <div className="spinner-grow text-primary" role="status">
      <span className="sr-only"></span>
    </div>
    <div className="spinner-grow text-secondary" role="status">
      <span className="sr-only"></span>
    </div>
    <div className="spinner-grow text-success" role="status">
      <span className="sr-only"></span>
    </div>
    <div className="spinner-grow text-danger" role="status">
      <span className="sr-only"></span>
    </div>
    <div className="spinner-grow text-warning" role="status">
      <span className="sr-only"></span>
    </div>
    <div className="spinner-grow text-info" role="status">
      <span className="sr-only"></span>
    </div>
    <div className="spinner-grow text-gray" role="status">
      <span className="sr-only"></span>
    </div>
    <div className="spinner-grow text-dark" role="status">
      <span className="sr-only"></span>
    </div>
  </div>
);

export default Loader;
