import { NewRequest } from "../../helpers/axios";
import { GET, POST } from "../../helpers/axiosConst";
import { API_URLS, BuildAPIUrl } from "../../utils/config/URLS";
import { toast } from "react-toastify";
import { AddMainCurrencies } from "../../store/actions/currenciesActions";
import { SetOrganizationData } from "../../store/actions/organizationActions";

export const UpdateOrganization = (data) => {
  const newData = {
    address: {
      city: data.city,
      country: data.country,
      line1: data.street,
      line2: data.timezone,
      state: data.state,
      zipCode: data.zipCode,
    },
    fax: data.fax,
    mobile: data.mobile,
    name: data.name,
    phone: data.phone,
    website: data.website,
  };
  return (dispatch) => {
    NewRequest(
      POST,
      BuildAPIUrl(API_URLS.GET_ORGANIZATION_DATA_URL),
      JSON.stringify(newData),
      () => {
        toast.success(`Successfully update`);
        dispatch(GetOrganization());
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const SetFinance = (data) => {
  return (dispatch) => {
    NewRequest(
      POST,
      BuildAPIUrl(API_URLS.SET_FINANCE),
      data,
      () => {
        toast.success(`Finance info updated`);
        dispatch(GetOrganization());
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const GetOrganization = () => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(API_URLS.GET_ORGANIZATION_DATA_URL),
      null,
      (resp) => {
        dispatch(SetOrganizationData(resp.data.organisation));
        dispatch(
          AddMainCurrencies(resp.data.organisation.financeSettings.currency)
        );
      }
    );
  };
};
