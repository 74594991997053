import * as roleActions from "../../actions/roleActions/constants";

const initialState = {
  role: [],
  operations: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case roleActions.SET_NEW_ROLE:
      return {
        ...state,
        role: [...state.role, action.payload],
      };
    case roleActions.SET_OPERATIONS:
      return {
        ...state,
        operations: [...state.operations, action.payload],
      };
    case roleActions.CHANGE_ROLE:
      return {
        ...state,
        role: state.role.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        }),
      };
    case roleActions.GET_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    default:
      return state;
  }
};
