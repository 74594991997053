import { createContext } from "react";
import { settings } from "../config";

const AppContext = createContext(settings);
export const EmailContext = createContext({ emails: [] });
export const AuthWizardContext = createContext({ user: {} });
export const CalendarContext = createContext({});
export const WorkTimeContext = createContext({});
export const ClientCardContext = createContext({});
export const KanbanContext = createContext({});
export const OpportunitiesContext = createContext({});
export const DrawerContext = createContext({});
export const TableContext = createContext({});
export const RouterContext = createContext({});

export default AppContext;
