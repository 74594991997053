import { NewRequest } from "../../helpers/axios";
import { DELETE, GET, POST, PUT } from "../../helpers/axiosConst";
import { API_URLS, BuildAPIUrl, URLS_LOCAL } from "../../utils/config/URLS";
import {
  AddGoogleOauth,
  ChangeStep,
  SetCalendar,
  SetCalendarList,
  SetCalendarStatus,
  SetEvents,
  SetVideo,
  StepStart,
} from "../../store/actions/calendarActions";
import { toast } from "react-toastify";

export const GetGoogleUrlCalendar = (setGoogleLink) => {
  NewRequest(
    GET,
    BuildAPIUrl(API_URLS.GET_GOOGLE_URL_CALENDAR),
    null,
    (res) => {
      setGoogleLink(res.data);
    }
  );
};

export const OauthConfirmCalendar = (code, navigate) => {
  return (dispatch) => {
    NewRequest(POST, BuildAPIUrl(API_URLS.CALENDAR_CONFIRM), { code }, () => {
      toast.success(`Successfully integrate witch google`);
      dispatch(AddGoogleOauth(true));
      navigate(URLS_LOCAL.USER.CALENDAR_SETTINGS);
    });
  };
};

export const OauthDeleteCalendar = (id) => {
  return (dispatch) => {
    NewRequest(
      DELETE,
      BuildAPIUrl(`${API_URLS.DELETE_CALENDAR}/${id}`),
      null,
      () => {
        toast.success(`Successfully integration delete`);
        dispatch(AddGoogleOauth(false));
        dispatch(StepStart());
      }
    );
  };
};

export const RemoveCalendarSync = (data) => {
  return (dispatch) => {
    NewRequest(POST, BuildAPIUrl(API_URLS.REMOVE_SYNC_CALENDAR), data, () => {
      toast.success(`Successfully sync delete`);
      dispatch(GetCalendarList());
      dispatch(GetCalendar());
    });
  };
};

export const GetCalendar = (setDisabled = () => false) => {
  return (dispatch) => {
    NewRequest(GET, BuildAPIUrl(API_URLS.GET_CALENDAR), null, (res) => {
      dispatch(SetCalendar(res.data.calendar));
      setDisabled(false);
    });
  };
};

export const GetCalendarList = () => {
  return (dispatch) => {
    NewRequest(GET, BuildAPIUrl(API_URLS.GET_LIST), null, (res) => {
      dispatch(SetCalendarList(res.data.calendars));
    });
  };
};

export const CalendarSync = (
  data,
  modal = false,
  step,
  setLoad = () => false
) => {
  return (dispatch) => {
    NewRequest(POST, BuildAPIUrl(API_URLS.CALENDAR_SYNC), data, () => {
      setLoad(false);
      toast.success(`Sync completed`);
      if (step == 2) {
        dispatch(ChangeStep("increment"));
      }
      if (modal) {
        dispatch(GetCalendar());
      }
    });
  };
};

export const GetEvents = () => {
  return (dispatch) => {
    NewRequest(GET, BuildAPIUrl(API_URLS.GET_EVENTS), null, (res) => {
      dispatch(SetEvents(res.data.calendarEvents));
    });
  };
};

export const AddEvents = (data, formData, setFormData, handleClose) => {
  const newData = {
    attendeesID: data.attendeesID,
    description: data.description,
    startDateTime: data.startDateTime.toISOString(),
    externalCalendarID: data.externalCalendarID,
    endDateTime: data.endDateTime.toISOString(),
    timeZone: data.timeZone,
    title: data.title,
    type: data.type,
  };
  return (dispatch) => {
    NewRequest(POST, BuildAPIUrl(API_URLS.GET_EVENTS), newData, () => {
      toast.success(`Event add success`);
      dispatch(GetEvents());
      handleClose();
    });
  };
};

export const EditEvents = (
  data,
  id,
  navigate = () => false,
  formData,
  setFormData = () => false,
  setEdit = () => false
) => {
  const newData = {
    attendeesID: data.attendeesID,
    description: data.description,
    startDateTime: data.startDateTime.toISOString(),
    externalCalendarID: data.externalCalendarID,
    endDateTime: data.endDateTime.toISOString(),
    timeZone: data.timeZone,
    title: data.title,
    type: data.type,
  };
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(`${API_URLS.GET_EVENTS}/${id}`),
      newData,
      () => {
        toast.success(`Event edit success`);
        dispatch(GetEvents());
        setFormData({
          ...formData,
          attendeesID: [],
          description: "",
          startDateTime: "",
          externalCalendarID: "",
          endDateTime: "",
          timeZone: "",
          title: "",
          type: 0,
        });
        setEdit(false);
        navigate(URLS_LOCAL.USER.CALENDAR);
      }
    );
  };
};

export const DeleteEvents = (id, navigate) => {
  return (dispatch) => {
    NewRequest(
      DELETE,
      BuildAPIUrl(`${API_URLS.GET_EVENTS}/${id}`),
      null,
      () => {
        toast.success(`Event delete success`);
        dispatch(GetEvents());
        navigate(URLS_LOCAL.USER.CALENDAR);
      },
      () => {
        navigate(URLS_LOCAL.USER.CALENDAR);
      }
    );
  };
};

export const calendarSettingsStatus = () => {
  return (dispatch) => {
    NewRequest(GET, BuildAPIUrl(API_URLS.CALENDAR_STATUS), null, (res) => {
      if (res.data.settings.OauthSettings.length > 0) {
        dispatch(AddGoogleOauth(true));
      } else {
        dispatch(AddGoogleOauth(false));
        dispatch(StepStart());
      }
      dispatch(SetCalendarStatus(res.data));
    });
  };
};

export const GetVideo = () => {
  return (dispatch) => {
    NewRequest(GET, BuildAPIUrl(API_URLS.GET_VIDEO), null, (res) => {
      dispatch(SetVideo(res.data.videoConversationList));
    });
  };
};

export const AddVideo = (data, formData, setFormData) => {
  const newData = {
    attendeesID: data.attendeesID,
    externalGoogleID: data.externalCalendarID,
    title: data.title,
    startDateTime: data.startDateTime,
    endDateTime: data.endDateTime,
  };
  return (dispatch) => {
    NewRequest(POST, BuildAPIUrl(API_URLS.GET_VIDEO), newData, (res) => {
      toast.success(`Event edit success`);
      dispatch(GetEvents());
      setFormData({
        ...formData,
        description: "",
        className: "",
        title: "",
      });
    });
  };
};

export const GetSingleVideo = (id) => {
  return (dispatch) => {
    NewRequest(GET, BuildAPIUrl(`${API_URLS.GET_VIDEO}/${id}`), null, (res) => {
      console.log(res.data);
    });
  };
};

export const DeleteVideo = (id) => {
  return (dispatch) => {
    NewRequest(
      DELETE,
      BuildAPIUrl(`${API_URLS.GET_VIDEO}/${id}`),
      null,
      (res) => {
        console.log(res.data);
      }
    );
  };
};
