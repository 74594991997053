import * as searchActions from "../../actions/searchActions/constants";

const initialState = {
  search_data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case searchActions.SET_SEARCH_ITEMS:
      return {
        ...state,
        search_data: action.payload,
      };
    default:
      return state;
  }
};
