import * as organizationActions from "../../actions/organizationActions/constants";

const initialState = {
  info: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case organizationActions.SET_ORGANIZATION_INFO:
      return {
        ...state,
        info: action.payload,
      };
    default:
      return state;
  }
};
