import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import OutsideClick from "../OutsideClick";

import points from "../../../assets/img/icons/points.svg";
import("./style.scss");

const ContextMenu = ({ params, classNames }) => {
  const [toggle, setToggle] = useState(false);
  return (
    <div className={`${classNames} context-menu`}>
      <Button onClick={() => setToggle((toggle) => !toggle)} className={"p-0"}>
        <img src={points} alt="icon" />
      </Button>
      {toggle && (
        <OutsideClick onToggle={() => setToggle(false)}>
          <div className={"context-menu-content"}>
            {params.map((item, index) => (
              <div
                key={index}
                className={"context-menu-content-item"}
                onClick={() => {
                  setToggle(false);
                  item.handler && item.handler();
                }}
              >
                {item.name}
              </div>
            ))}
          </div>
        </OutsideClick>
      )}
    </div>
  );
};

ContextMenu.propTypes = {
  params: PropTypes.array,
  classNames: PropTypes.string,
};

export default ContextMenu;
