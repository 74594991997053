import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import ConfirmButton from "../components/common/ConfirmButton";

const ModalLayout = ({
  close,
  title,
  modal,
  handleSubmit,
  confirmText = "Send",
  cancelText = "Cancel",
  children,
  className,
  isFooter = true,
  cancelHandler = close,
  btnDelete = false,
}) => {
  return (
    <Modal
      show={modal}
      onHide={close}
      className={className}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className={"align-items-start border-0"}>
        <Modal.Title className={"fs-4"} id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className={"border-0"}>{children}</Modal.Body>
      {isFooter && (
        <Modal.Footer className={"border-0"}>
          <div className={"w-100 g-3 d-flex justify-content-between"}>
            <ConfirmButton
              text={confirmText}
              handler={handleSubmit}
              classNames={"py-3 px-5"}
            />
            <ConfirmButton
              text={cancelText}
              handler={cancelHandler}
              classNames={`py-3 px-5 bg-gray text-black ${
                btnDelete ? "btn-danger" : "btn-light"
              }`}
            />
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
};

ModalLayout.propTypes = {
  close: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  modal: PropTypes.bool,
  handleSubmit: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  isFooter: PropTypes.bool,
  cancelHandler: PropTypes.func,
  btnDelete: PropTypes.bool,
};

export default ModalLayout;
