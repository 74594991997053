import * as companyActions from "../../actions/companyActions/constants";

const initialState = {
  data: {},
  company: [],
  singleCompany: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case companyActions.SET_COMPANY_CONSTRUCTOR:
      return {
        ...state,
        data: action.payload,
      };
    case companyActions.SET_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    case companyActions.SET_COMPANY_SINGLE:
      return {
        ...state,
        singleCompany: action.payload,
      };
    default:
      return state;
  }
};
