import React, { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { authRoutes, routes } from "./router";
import Loader from "./components/common/Spinner";
import { URLS_LOCAL } from "./utils/config/URLS";
import MainLayout from "./layouts/MainLayout";
import Error404 from "./components/errors/Error404";
import Error500 from "./components/errors/Error500";
import NotPermissions from "./components/common/NotPermissions";

const App = () => {
  const authRouter = authRoutes.map((route, index) => {
    return route.element ? (
      <Route
        key={index}
        path={route.path}
        name={route.name}
        element={<Suspense fallback={<Loader />}>{route.element}</Suspense>}
      />
    ) : null;
  });
  const privateRoutes = routes.map((route, index) => {
    return route.element ? (
      <Route
        key={index}
        path={route.path}
        name={route.name}
        element={<Suspense fallback={<Loader />}>{route.element}</Suspense>}
      />
    ) : null;
  });
  return (
    <Suspense fallback={""}>
      <Routes>
        <Route path={"/"} element={<MainLayout />}>
          {privateRoutes}
        </Route>
        {authRouter}
        <Route path={URLS_LOCAL.NOT_FOUND} element={<Error404 />} />
        <Route path={URLS_LOCAL.SERVER_ERROR} element={<Error500 />} />
        <Route path={URLS_LOCAL.NOT_PERMISSIONS} element={<NotPermissions />} />
        <Route
          path="*"
          element={<Navigate to={URLS_LOCAL.NOT_FOUND} replace />}
        />
      </Routes>
    </Suspense>
  );
};

export default App;
