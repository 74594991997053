export const USER_SIGN_IN_ACTION_SUCCESS = "USER_SIGN_IN_ACTION_SUCCESS";
export const USER_SIGN_IN_ACTION_FAILURE = "USER_SIGN_IN_ACTION_FAILURE";
export const USER_UPDATE_INFO = "USER_UPDATE_INFO";
export const TWO_FACTOR = "TWO_FACTOR";
export const SET_TWO_FACTOR = "SET_TWO_FACTOR";
export const DELETE_TWO_FACTOR = "DELETE_TWO_FACTOR";
export const USER_UPDATE_TOKEN = "USER_UPDATE_TOKEN";
export const USER_REFRESH_TOKEN = "USER_REFRESH_TOKEN";
export const LOGGED_IN = "LOGGED_IN";
export const MAIL_CHANGE = "MAIL_CHANGE";
export const SET_AVATAR = "SET_AVATAR";
export const SET_USERS = "SET_USERS";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_RELATED_OPTIONS = "SET_RELATED_OPTIONS";
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_STATUS_LIST = "SET_STATUS_LIST";
export const WORK_TIME_SWITCH = "WORK_TIME_SWITCH";
export const CHANGE_EMAIL = "CHANGE_EMAIL";
export const REGISTER_IN_OAUTH = "REGISTER_IN_OAUTH";
