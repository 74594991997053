import { NewRequest } from "../../helpers/axios";
import { DELETE, GET, POST, PUT } from "../../helpers/axiosConst";
import { API_URLS, BuildAPIUrl } from "../../utils/config/URLS";
import { SetComments, SetEntities } from "../../store/actions/commentsActions";
import { toast } from "react-toastify";

export const GetEntities = () => {
  return (dispatch) => {
    NewRequest(GET, BuildAPIUrl(API_URLS.GET_ENTITIES), null, (res) => {
      dispatch(SetEntities(res.data));
    });
  };
};

export const GetComments = (type, id, params, setLoading = () => false) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(`${API_URLS.GET_COMMENTS}/${type}/${id}`),
      null,
      (res) => {
        dispatch(SetComments(res.data));
        setLoading(false);
      },
      () => false,
      params
    );
  };
};

export const SendComment = (type, data, id) => {
  return (dispatch) => {
    NewRequest(
      POST,
      BuildAPIUrl(`${API_URLS.GET_COMMENTS}/${type}/${id}`),
      data,
      () => {
        toast.success("Comment send");
        dispatch(GetComments(type, id));
      }
    );
  };
};

export const EditComment = (type, data, id, commentID) => {
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(`${API_URLS.GET_COMMENTS}/${type}/${id}/${commentID}`),
      data,
      () => {
        toast.success("Comment edited");
        dispatch(GetComments(type, id));
      }
    );
  };
};

export const EditCommentAttachment = (type, data, id, commentID) => {
  return (dispatch) => {
    NewRequest(
      POST,
      BuildAPIUrl(
        `${API_URLS.GET_COMMENTS}/${type}/${id}/${commentID}/attachments`
      ),
      data,
      () => {
        dispatch(GetComments(type, id));
      }
    );
  };
};

export const DeleteComment = (type, id, commentID) => {
  return (dispatch) => {
    NewRequest(
      DELETE,
      BuildAPIUrl(`${API_URLS.GET_COMMENTS}/${type}/${id}/${commentID}`),
      null,
      () => {
        toast.success("Comment deleted");
        dispatch(GetComments(type, id));
      }
    );
  };
};

export const DeleteCommentAttachment = (
  type,
  id,
  commentID,
  deleteAttachId
) => {
  return (dispatch) => {
    NewRequest(
      DELETE,
      BuildAPIUrl(
        `${API_URLS.GET_COMMENTS}/${type}/${id}/${commentID}/attachments/${deleteAttachId}`
      ),
      null,
      () => {
        toast.success("Attachments deleted");
        dispatch(GetComments(type, id));
      }
    );
  };
};
