import * as cardActions from "../../actions/cardActions/constants";

const initialState = {
  data: {},
  customFilterFields: [],
  productFilterFields: [],
  clientFilterFields: [],
  contractFilterFields: [],
  companyFilterFields: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case cardActions.GET_CARD:
      return {
        ...state,
        data: action.payload,
      };
    case cardActions.SET_CUSTOM_FIELDS:
      return {
        ...state,
        customFilterFields: action.payload,
      };
    case cardActions.SET_CUSTOM_FIELDS_CLIENT:
      return {
        ...state,
        clientFilterFields: action.payload,
      };
    case cardActions.SET_CUSTOM_FIELDS_PRODUCT:
      return {
        ...state,
        productFilterFields: action.payload,
      };
    case cardActions.SET_CUSTOM_FIELDS_COMPANY:
      return {
        ...state,
        companyFilterFields: action.payload,
      };
    case cardActions.SET_CUSTOM_FIELDS_CONTRACT:
      return {
        ...state,
        contractFilterFields: action.payload,
      };
    default:
      return state;
  }
};
