import React from "react";
import { Button } from "react-bootstrap";
import PropTypes from "prop-types";

const ConfirmButton = React.memo(
  ({
    text,
    handler = () => false,
    classNames,
    disabled = false,
    children,
    value,
    reference,
    type = "button",
  }) => {
    return (
      <Button
        ref={reference}
        disabled={disabled}
        value={value}
        type={type}
        onClick={handler}
        className={`fw-normal action_button border-0 ${classNames}`}
      >
        {children}
        {text}
      </Button>
    );
  }
);

ConfirmButton.propTypes = {
  text: PropTypes.string,
  handler: PropTypes.func,
  classNames: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  value: PropTypes.string,
  reference: PropTypes.any,
  type: PropTypes.string,
};

export default ConfirmButton;
