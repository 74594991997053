import React from "react";
import { PIPELINE_TRIGGERS_COLLECTION, SEARCH_URLS } from "../constants";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";

const PipelineTrigger = ({ elem }) => {
  return (
    <Link
      className={"search_link"}
      to={SEARCH_URLS[PIPELINE_TRIGGERS_COLLECTION](elem.pipelineID)}
    >
      Trigger from {moment(elem.createdAt).fromNow()}
    </Link>
  );
};

PipelineTrigger.propTypes = {
  elem: PropTypes.object,
};

export default PipelineTrigger;
