import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import reducers from "./reducers";
import { UpdateAuthToken } from "./actions/userActions";
import {
  UserPingAction,
  UserRefreshTokenAction,
} from "../requests/userRequests/index";

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

const persistor = persistStore(store);

persistor.subscribe(() => {
  store.dispatch(UpdateAuthToken());
  setInterval(() => {
    store.dispatch(UserRefreshTokenAction(store.getState().user.accessToken));
  }, 60 * 30 * 1000);
  setInterval(() => {
    store.dispatch(UserPingAction());
  }, 15 * 1000);
});

window.addEventListener("focus", () => {
  store.dispatch(UserPingAction());
});

export default persistor;
