import { NewRequest } from "../../helpers/axios";
import { DELETE, GET, POST, PUT } from "../../helpers/axiosConst";
import { API_URLS, BuildAPIUrl, URLS_LOCAL } from "../../utils/config/URLS";
import { SetClients } from "../../store/actions/clientsActions";
import { SetProductList } from "../../store/actions/productActions";
import { toast } from "react-toastify";
import { AddCompany } from "../../store/actions/companyActions";
import {
  AddCard,
  AddCustomFields,
  AddCustomFieldsClient,
  AddCustomFieldsCompany,
  AddCustomFieldsContract,
  AddCustomFieldsProduct,
} from "../../store/actions/cardActions";
import { GetContract } from "../../requests/contractRequests/index";
import {
  DeleteNavbarSettings,
  SetNavbarPages,
  SetNavbarSettings,
  UpdateNavbar,
} from "../../store/actions/navbarActions";
import { GetCompanyConstructor } from "../companyRequests";
import { GetProduct } from "../productRequests";

export const GetCustomCards = (
  params = false,
  setState = () => false,
  state = {},
  setOnLoad = () => false,
  id
) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(`${API_URLS.CONSTRUCTOR_CUSTOM_GET}/${id}`),
      {},
      (resp) => {
        setOnLoad(true);
        const { data, pages, total } = resp.data;
        setState({
          ...state,
          isFetchData: false,
          data,
          pages,
          total,
          params: {
            ...state.params,
            page: Number(params.page),
            perPage: Number(params.perPage),
            filtered: params.filtered,
            sorted: params.sorted,
          },
        });
      },
      () => {
        setOnLoad(true);
      },
      params && {
        page: Number(params.page),
        perPage: Number(params.perPage),
        sorted: params.sorted,
        filtered: params.filtered,
        conditions: params.conditions,
      }
    );
  };
};

export const GetCard = () => {
  return (dispatch) => {
    NewRequest(GET, BuildAPIUrl(API_URLS.CONSTRUCTOR_CARD), null, (res) => {
      dispatch(AddCard(res.data));
    });
  };
};

export const GetCustomList = () => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(API_URLS.CONSTRUCTOR_CUSTOM_LIST),
      null,
      (res) => {
        const newPages = Object.values(res.data).map((item) => ({
          name: item.SubType,
          icon: item.Icon,
          active: true,
          to: `/custom-constructor/${item.SubType}`,
        }));
        const newSettings = Object.values(res.data).map((item) => ({
          name: item.SubType,
          active: true,
          to: `/settings/custom-constructor/${item.SubType}`,
        }));
        dispatch(SetNavbarPages(newPages));
        dispatch(SetNavbarSettings(newSettings));
      }
    );
  };
};

export const EditCardCustom = (data, items, type, id) => {
  NewRequest(
    PUT,
    BuildAPIUrl(`${API_URLS.CONSTRUCTOR_CUSTOM_GET}/${type}`),
    { id: id, data, items },
    () => {
      toast.success(`Product changed`);
    }
  );
};

export const GetCustomFilterField = (type) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(`${API_URLS.CONSTRUCTOR_CUSTOM_CARD}/${type}`),
      null,
      (resp) => {
        dispatch(AddCustomFields(resp.data.sections));
      },
      () => {
        dispatch(AddCustomFields([]));
      }
    );
  };
};

export const GetFilterField = (type) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(`${API_URLS.CONSTRUCTOR}/${type}`),
      null,
      (resp) => {
        if (type === "client_card") {
          dispatch(AddCustomFieldsClient(resp.data.sections));
        }
        if (type === "company_card") {
          dispatch(AddCustomFieldsCompany(resp.data.sections));
        }
        if (type === "product") {
          dispatch(AddCustomFieldsProduct(resp.data.sections));
        }
        if (type === "contract") {
          dispatch(AddCustomFieldsContract(resp.data.sections));
        }
      },
      () => {
        if (type === "client_card") {
          dispatch(AddCustomFieldsClient([]));
        }
        if (type === "company_card") {
          dispatch(AddCustomFieldsCompany([]));
        }
        if (type === "product") {
          dispatch(AddCustomFieldsProduct([]));
        }
        if (type === "contract") {
          dispatch(AddCustomFieldsContract([]));
        }
      }
    );
  };
};

export const GetCustomCard = (
  type,
  setFormData = () => false,
  formData = {}
) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(`${API_URLS.CONSTRUCTOR_CUSTOM_CARD}/${type}`),
      null,
      (res) => {
        dispatch(AddCard(res.data));
        setFormData({
          ...formData,
          subType: res.data.subType,
          oldSubType: res.data.subType,
        });
      }
    );
  };
};

export const SetCard = (data, type) => {
  return (dispatch) => {
    NewRequest(
      POST,
      BuildAPIUrl(`${API_URLS.CONSTRUCTOR}/${type}`),
      data,
      () => {
        if (type === "client_card") {
          dispatch(GetCard(type));
        }
        if (type === "company_card") {
          dispatch(GetCompanyConstructor());
        }
        if (type === "product") {
          dispatch(GetProduct());
        }
        if (type === "contract") {
          dispatch(GetContract());
        }
      }
    );
  };
};

export const SetCustomCard = (data, formData, navigate) => {
  const newData = { subType: formData.subType, icon: formData.icon, ...data };
  return (dispatch) => {
    NewRequest(
      POST,
      BuildAPIUrl(`${API_URLS.CONSTRUCTOR_CUSTOM}`),
      newData,
      (res) => {
        navigate(`/settings/custom-constructor/${res.data.subType}`);
        dispatch(GetCustomList());
      }
    );
  };
};

export const UpdateCustomCard = (data, formData) => {
  const newData = { subType: formData.subType, icon: formData.icon, ...data };
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(`${API_URLS.CONSTRUCTOR_CUSTOM_CARD}/${formData.oldSubType}`),
      { ...newData },
      (res) => {
        toast.success("Constructor updated");
        dispatch(AddCard(res.data));
        dispatch(
          UpdateNavbar({
            subType: res.data.subType,
            icon: formData.icon,
            oldSubType: formData.oldSubType,
          })
        );
      }
    );
  };
};

export const DeleteCustomCard = (navigate, formData) => {
  return (dispatch) => {
    NewRequest(
      DELETE,
      BuildAPIUrl(`${API_URLS.CONSTRUCTOR_CUSTOM_CARD}/${formData.oldSubType}`),
      null,
      () => {
        dispatch(DeleteNavbarSettings(formData.oldSubType));
        navigate(URLS_LOCAL.USER.ADD_CONSTRUCTOR);
      }
    );
  };
};

export const DeleteCustomConstructorCard = (
  type,
  id,
  onPageClick,
  state,
  navigate
) => {
  return (dispatch) => {
    NewRequest(
      DELETE,
      BuildAPIUrl(`${API_URLS.CONSTRUCTOR_CUSTOM_GET}/${type}/item/${id}`),
      null,
      () => {
        toast.success("Delete completed");
        if (onPageClick) {
          onPageClick(state.params);
        }
        if (navigate) {
          navigate(`/custom-constructor/${type}`);
        }
      }
    );
  };
};

export const CreateCustomCard = (data, items, navigate = false, id) => {
  NewRequest(
    POST,
    BuildAPIUrl(`${API_URLS.CONSTRUCTOR_CUSTOM_GET}/${id}`),
    { data, items },
    (res) => {
      toast.success(`Card added`);
      console.log(res.data.data.ID);
      if (navigate) {
        navigate(`/settings/custom-card/${id}/edit-card/${res.data.data.ID}`);
      }
    }
  );
};

export const GetSingleCard = (type, id, setFormData, setItemsData) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(`${API_URLS.CONSTRUCTOR_CUSTOM_GET}/${type}/item/${id}`),
      null,
      (res) => {
        setFormData({
          fields: res.data.fields,
        });
        setItemsData({
          items: res.data.items,
        });
      }
    );
  };
};

export const GetCardLookupFields = (
  type,
  formData = {},
  setFormData = () => false
) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(API_URLS.LOOKUP),
      null,
      (res) => {
        if (type === "client_card") {
          dispatch(SetClients(res.data.data));
          let newOptions = res.data.data.map((item) => ({
            label: item.fields.email,
            value: item.id,
          }));
          setFormData({
            ...formData,
            type: "lookup",
            items: newOptions,
          });
        } else if (type === "product") {
          dispatch(SetProductList(res.data.data));
          let newOptions = res.data.data.map((item) => ({
            label: item.fields.product_name,
            value: item.id,
          }));
          setFormData({
            ...formData,
            type: "lookup",
            items: newOptions,
          });
        } else if (type === "company_card") {
          dispatch(AddCompany(res.data.data));
          let newOptions = res.data.data.map((item) => ({
            label: item.fields.company_name,
            value: item.id,
          }));
          setFormData({
            ...formData,
            type: "lookup",
            items: newOptions,
          });
        }
      },
      (err) => {
        console.log(err);
      },
      {
        type: type,
      }
    );
  };
};
