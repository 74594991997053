import React from "react";
import { SEARCH_URLS, USERS_COLLECTION } from "../constants";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Users = ({ elem }) => {
  return (
    <Link className={"search_link"} to={SEARCH_URLS[USERS_COLLECTION]}>
      User: {`${elem.firstName} ${elem.lastName}`}
    </Link>
  );
};

Users.propTypes = {
  elem: PropTypes.object,
};

export default Users;
