import React from "react";

import { URLS_LOCAL } from "./utils/config/URLS";
const CustomConstructorList = React.lazy(() =>
  import("./components/pages/customConstructor/CustomConstructorList")
);
const Oauth = React.lazy(() => import("./components/authentication/Oauth"));
const CustomConstructorCreate = React.lazy(() =>
  import("./components/pages/customConstructor/CustomConstructorCreate")
);
const EditCustomCard = React.lazy(() =>
  import("./components/pages/customConstructor/EditCustomCard")
);
const Dashboard = React.lazy(() => import("./components/dashboard/main"));
const ProductCardWrapper = React.lazy(() =>
  import("./components/pages/product/ProductCardWrapper")
);
const EditCustomConstructor = React.lazy(() =>
  import("./components/pages/customConstructor/EditCustomConstructor")
);
const NotPermissions = React.lazy(() =>
  import("./components/common/NotPermissions")
);
const AddConstructorWrapper = React.lazy(() =>
  import("./components/constructor/AddConstructorWrapper")
);
const KanbanRedirectComponent = React.lazy(() =>
  import("./components/app/kanban/KanbanRedirectComponent")
);
const OpportunitiesRedirectComponent = React.lazy(() =>
  import("./components/app/opportunities/OpportunitiesRedirectComponent")
);
const OpportunitiesBoard = React.lazy(() =>
  import("./components/app/opportunities/OpportunitiesBoard")
);

const ClientsCardWrapper = React.lazy(() =>
  import("./components/pages/clients/ClientsCardWrapper")
);
const Zoom = React.lazy(() => import("./components/pages/zoom/Zoom"));
const Invoice = React.lazy(() => import("./components/pages/invoice/Invoice"));
const AddNewClient = React.lazy(() =>
  import("./components/pages/clients/AddNewClient")
);

const ClientCalendar = React.lazy(() =>
  import("./components/app/calendar/ClientCalendar")
);
const WorkFlowCreate = React.lazy(() =>
  import("./components/pages/workflow/WorkFlowCreate")
);
const WorkFlowTrigger = React.lazy(() =>
  import("./components/pages/workflow/trigger/PipelineTrigger")
);
const CompanyCardWrapper = React.lazy(() =>
  import("./components/pages/company/CompanyCardWrapper")
);
const ContractConstructorWrapper = React.lazy(() =>
  import("./components/constructor/ContractConstructorWrapper")
);
const AddNewContract = React.lazy(() =>
  import("./components/pages/contract/AddNewContract")
);
const AddNewProduct = React.lazy(() =>
  import("./components/pages/product/AddNewProduct")
);
const AddNewCompany = React.lazy(() =>
  import("./components/pages/company/AddNewCompany")
);
const Dashboards = React.lazy(() =>
  import("./components/app/dashboards/Dashboards")
);
const Company = React.lazy(() =>
  import("./components/pages/company/CompanyTable")
);
const Product = React.lazy(() =>
  import("./components/pages/product/ProductTable")
);
const Contract = React.lazy(() =>
  import("./components/pages/contract/ContractTable")
);
const Notifications = React.lazy(() =>
  import("./components/pages/notifications/Notifications")
);
const WorkFlow = React.lazy(() =>
  import("./components/pages/workflow/WorkFlow")
);
const WorkFlowPages = React.lazy(() =>
  import("./components/pages/workflow/WorkFlowPages")
);
const ProfileTabs = React.lazy(() =>
  import("./components/pages/user/ProfileTabs")
);
const Login = React.lazy(() =>
  import("./components/authentication/forms/Login")
);
const Invitation = React.lazy(() =>
  import("./components/authentication/forms/Invitation")
);
const TwoFaCode = React.lazy(() =>
  import("./components/authentication/forms/TwoFaCode")
);
const ServerError = React.lazy(() => import("./components/errors/Error500"));
const MailConfirm = React.lazy(() =>
  import("./components/authentication/forms/MailConfirm")
);
const PasswordReset = React.lazy(() =>
  import("./components/authentication/forms/PasswordReset")
);
const Registration = React.lazy(() =>
  import("./components/authentication/forms/Registration")
);
const ForgetPassword = React.lazy(() =>
  import("./components/authentication/forms/ForgetPassword")
);
const CardConstructorWrapper = React.lazy(() =>
  import("./components/constructor/CardConstructorWrapper")
);

const CompanyConstructorWrapper = React.lazy(() =>
  import("./components/constructor/CompanyConstructorWrapper")
);

const ProductConstructorWrapper = React.lazy(() =>
  import("./components/constructor/ProductConstructorWrapper")
);

const Clients = React.lazy(() => import("./components/pages/clients/Clients"));
const Kanban = React.lazy(() => import("./components/app/kanban/Kanban"));
const SettingsOrganization = React.lazy(() =>
  import("./components/pages/organization/settings/SettingsOrganization")
);
const Mails = React.lazy(() => import("./components/pages/mail/Mails"));
const MailOauth = React.lazy(() =>
  import("./components/pages/mail/mail_settings/MailOauth")
);
const MyUsers = React.lazy(() => import("./components/pages/users/Users"));
const MailSettings = React.lazy(() =>
  import("./components/pages/mail/mail_settings/MailSettings")
);
const MailChange = React.lazy(() =>
  import("./components/pages/mail/change_mail/MailChange")
);
const CalendarSettings = React.lazy(() =>
  import("./components/pages/calendar/CalendarSettings")
);
const MailChangeConfirm = React.lazy(() =>
  import("./components/pages/mail/change_mail/MailChangeConfirm")
);
const CalendarOauth = React.lazy(() =>
  import("./components/pages/calendar/CalendarOauth")
);
const Domains = React.lazy(() => import("./components/pages/domain/Domains"));
const RoleCreate = React.lazy(() =>
  import("./components/pages/role/RoleCreate")
);
const RoleList = React.lazy(() => import("./components/pages/role/RoleList"));
const RoleEdit = React.lazy(() => import("./components/pages/role/RoleEdit"));
const ZoomAuthorization = React.lazy(() =>
  import("./components/pages/zoom/ZoomAuthorization")
);
const InvoiceEdit = React.lazy(() =>
  import("./components/pages/invoice/edit_invoice/InvoiceEdit")
);
const TemplateEdit = React.lazy(() =>
  import("./components/pages/mail/template/TemplateEdit")
);
const CreateTemplate = React.lazy(() =>
  import("./components/pages/mail/template/CreateTemplate")
);
const Currencies = React.lazy(() =>
  import("./components/pages/currencies/CurrenciesTable")
);
const MailTemplates = React.lazy(() =>
  import("./components/pages/mail/template/MailTemplates")
);
const Taxes = React.lazy(() => import("./components/pages/taxes/TaxesTable"));
const FormConstructor = React.lazy(() =>
  import("./components/pages/forms/FomConstructor")
);
const FormCreate = React.lazy(() =>
  import("./components/constructor/FormConstructorWrapper")
);
const FormEdit = React.lazy(() => import("./components/pages/forms/EditForm"));

export const routes = [
  {
    path: URLS_LOCAL.USER.NOT_PERMISSIONS,
    name: "NotPermissions",
    element: <NotPermissions />,
  },
  {
    path: URLS_LOCAL.USER.CUSTOM_CONSTRUCTOR_EDIT,
    name: "CustomList",
    element: <CustomConstructorList />,
  },
  {
    path: URLS_LOCAL.USER.CREATE_CUSTOM_CARD,
    name: "CustomConstructorCreate",
    element: <CustomConstructorCreate />,
  },
  {
    path: URLS_LOCAL.USER.CARD_CUSTOM_EDIT_CARD,
    name: "EditCustomCard",
    element: <EditCustomCard />,
  },
  {
    path: URLS_LOCAL.USER.DASHBOARD,
    name: "Dashboard",
    element: <Dashboard />,
  },
  { path: URLS_LOCAL.USER.PROFILE, name: "Profile", element: <ProfileTabs /> },
  { path: URLS_LOCAL.USER.DOMAIN, name: "Domains", element: <Domains /> },
  { path: URLS_LOCAL.USER.ZOOM, name: "Zoom", element: <Zoom /> },
  { path: URLS_LOCAL.USER.COMPANY, name: "Company", element: <Company /> },
  { path: URLS_LOCAL.USER.PRODUCT, name: "Product", element: <Product /> },
  { path: URLS_LOCAL.USER.TAXES, name: "Taxes", element: <Taxes /> },
  { path: URLS_LOCAL.USER.FORMS, name: "Forms", element: <FormConstructor /> },
  { path: URLS_LOCAL.USER.FORM_CREATE, name: "Forms", element: <FormCreate /> },
  { path: URLS_LOCAL.USER.FORM_EDIT, name: "Forms", element: <FormEdit /> },
  {
    path: URLS_LOCAL.USER.CUSTOM_CONSTRUCTOR_SETTINGS_EDIT,
    name: "EditCustomConstructor",
    element: <EditCustomConstructor />,
  },
  {
    path: URLS_LOCAL.USER.ADD_CONSTRUCTOR,
    name: "AddConstructor",
    element: <AddConstructorWrapper />,
  },
  {
    path: URLS_LOCAL.USER.CURRENCIES,
    name: "Currencies",
    element: <Currencies />,
  },
  { path: URLS_LOCAL.USER.CONTRACT, name: "Contract", element: <Contract /> },
  {
    path: URLS_LOCAL.USER.ZOOM_AUTHORIZATION,
    name: "ZoomAuthorization",
    element: <ZoomAuthorization />,
  },
  { path: URLS_LOCAL.USER.CLIENTS, name: "Clients", element: <Clients /> },
  {
    path: URLS_LOCAL.USER.NOTIFICATIONS,
    name: "Notifications",
    element: <Notifications />,
  },
  { path: URLS_LOCAL.USER.USERS, name: "User", element: <MyUsers /> },
  { path: URLS_LOCAL.USER.WORK_FLOW, name: "WorkFlow", element: <WorkFlow /> },
  {
    path: URLS_LOCAL.USER.WORK_FLOW_TRIGGERS,
    name: "WorkFlowTrigger",
    element: <WorkFlowTrigger />,
  },
  {
    path: URLS_LOCAL.USER.WORK_FLOW_PAGES,
    name: "WorkFlowPages",
    element: <WorkFlowPages />,
  },
  {
    path: URLS_LOCAL.USER.WORK_FLOW_CREATE,
    name: "WorkFlowCreate",
    element: <WorkFlowCreate />,
  },
  {
    path: URLS_LOCAL.USER.CARD_WRAPPER,
    name: "ClientProfile",
    element: <ClientsCardWrapper />,
  },
  {
    path: URLS_LOCAL.USER.COMPANY_WRAPPER,
    name: "CompanyProfile",
    element: <CompanyCardWrapper />,
  },
  {
    path: URLS_LOCAL.USER.PRODUCT_WRAPPER,
    name: "CompanyProfile",
    element: <ProductCardWrapper />,
  },
  {
    path: URLS_LOCAL.USER.CREATE_CLIENT,
    name: "CreateClient",
    element: <AddNewClient />,
  },
  {
    path: URLS_LOCAL.USER.CREATE_COMPANY,
    name: "CreateCompany",
    element: <AddNewCompany />,
  },
  {
    path: URLS_LOCAL.USER.CREATE_PRODUCT,
    name: "CreateProduct",
    element: <AddNewProduct />,
  },
  {
    path: URLS_LOCAL.USER.CREATE_CONTRACT,
    name: "CreateContract",
    element: <AddNewContract />,
  },
  { path: URLS_LOCAL.USER.MAIL, name: "Mails", element: <Mails /> },
  {
    path: URLS_LOCAL.USER.MAIL_CHANGE,
    name: "MailsChange",
    element: <MailChange />,
  },
  {
    path: URLS_LOCAL.USER.CLIENTS_EDIT,
    name: "ClientsEDit",
    element: <ClientsCardWrapper />,
  },
  {
    path: URLS_LOCAL.USER.COMPANY_EDIT,
    name: "CompanyEDit",
    element: <CompanyCardWrapper />,
  },
  {
    path: URLS_LOCAL.USER.PRODUCT_EDIT,
    name: "ProductEit",
    element: <ProductCardWrapper />,
  },
  {
    path: URLS_LOCAL.USER.INVOICE_EDIT,
    name: "InvoiceEdit",
    element: <InvoiceEdit />,
  },
  {
    path: URLS_LOCAL.USER.CALENDAR_READ,
    name: "CalendarRead",
    element: <ClientCalendar />,
  },
  {
    path: URLS_LOCAL.USER.DEAL_READ,
    name: "DealRead",
    element: <OpportunitiesBoard />,
  },
  {
    path: URLS_LOCAL.USER.TASK_BOARD_READ,
    name: "TaskBoardRead",
    element: <Kanban />,
  },
  {
    path: URLS_LOCAL.USER.CALENDAR_EDIT,
    name: "CalendarEdit",
    element: <ClientCalendar />,
  },
  {
    path: URLS_LOCAL.USER.CALENDAR,
    name: "ClientCalendar",
    element: <ClientCalendar />,
  },
  {
    path: URLS_LOCAL.USER.INVOICE,
    name: "Invoice",
    element: <Invoice />,
  },
  {
    path: URLS_LOCAL.USER.DASHBOARDS,
    name: "Dashboards",
    element: <Dashboards />,
  },
  {
    path: URLS_LOCAL.USER.OPPORTUNITIES,
    name: "Opportunities",
    element: <OpportunitiesRedirectComponent />,
  },
  {
    path: URLS_LOCAL.USER.OPPORTUNITIES_BOARD,
    name: "OpportunitiesBoard",
    element: <OpportunitiesBoard />,
  },
  {
    path: URLS_LOCAL.USER.TASKS_BOARD,
    name: "TasksBoard",
    element: <Kanban />,
  },
  {
    path: URLS_LOCAL.USER.TASKS,
    name: "Tasks",
    element: <KanbanRedirectComponent />,
  },
  {
    path: URLS_LOCAL.USER.CALENDAR_OAUTH_CONFIRM,
    name: "CalendarOauth",
    element: <CalendarOauth />,
  },
  {
    path: URLS_LOCAL.USER.CLIENTS_READ_MESSAGE,
    name: "ReadMessage",
    element: <ClientsCardWrapper />,
  },
  {
    path: URLS_LOCAL.USER.CLIENTS_COMPOSE,
    name: "ReadMessage",
    element: <ClientsCardWrapper />,
  },
  {
    path: URLS_LOCAL.USER.CLIENT_CARD,
    name: "ClientsCard",
    element: <CardConstructorWrapper />,
  },
  {
    path: URLS_LOCAL.USER.COMPANY_CARD,
    name: "CompanyCard",
    element: <CompanyConstructorWrapper />,
  },
  {
    path: URLS_LOCAL.USER.PRODUCT_CARD,
    name: "ProductCard",
    element: <ProductConstructorWrapper />,
  },
  {
    path: URLS_LOCAL.USER.CONTRACT_CARD,
    name: "ContractCard",
    element: <ContractConstructorWrapper />,
  },
  {
    path: URLS_LOCAL.USER.CALENDAR_SETTINGS,
    name: "CalendarSettings",
    element: <CalendarSettings />,
  },
  {
    path: URLS_LOCAL.USER.MAIL_CHANGE_CONFIRM,
    name: "MailsChangeConfirm",
    element: <MailChangeConfirm />,
  },
  {
    path: URLS_LOCAL.USER.MAIL_OAUTH,
    name: "MailOauth",
    element: <MailOauth />,
  },
  {
    path: URLS_LOCAL.USER.MAIL_SETTINGS,
    name: "MailsSettings",
    element: <MailSettings />,
  },
  {
    path: URLS_LOCAL.USER.MAIL_TEMPLATES,
    name: "MaleTemplate",
    element: <MailTemplates />,
  },
  {
    path: URLS_LOCAL.USER.ROLE_CREATE,
    name: "RoleCreate",
    element: <RoleCreate />,
  },
  {
    path: URLS_LOCAL.USER.ROLE_LIST,
    name: "RoleCreate",
    element: <RoleList />,
  },
  { path: URLS_LOCAL.USER.ROLE_EDIT, name: "RoleEdit", element: <RoleEdit /> },
  {
    path: URLS_LOCAL.USER.TEMPLATE_EDIT,
    name: "TemplateEdit",
    element: <TemplateEdit />,
  },
  {
    path: URLS_LOCAL.USER.TEMPLATE_CREATE,
    name: "CreateTemplate",
    element: <CreateTemplate />,
  },
  {
    path: URLS_LOCAL.USER.ORGANIZATION,
    name: "SettingsOrganization",
    element: <SettingsOrganization />,
  },
];

export const authRoutes = [
  { path: URLS_LOCAL.AUTH.LOGIN, element: <Login /> },
  { path: URLS_LOCAL.AUTH.INVITATION, element: <Invitation /> },
  { path: URLS_LOCAL.AUTH.SERVER_ERROR, element: <ServerError /> },
  { path: URLS_LOCAL.AUTH.REGISTER, element: <Registration /> },
  { path: URLS_LOCAL.AUTH.VERIFY, element: <TwoFaCode /> },
  { path: URLS_LOCAL.AUTH.MAIL_CONFIRM, element: <MailConfirm /> },
  { path: URLS_LOCAL.AUTH.RESET, element: <PasswordReset /> },
  {
    path: URLS_LOCAL.AUTH.FORGOT_PASSWORD,
    element: <ForgetPassword />,
  },
  {
    path: URLS_LOCAL.AUTH.AUTH,
    name: "Oauth",
    element: <Oauth />,
  },
];
