import { NewRequest } from "../../helpers/axios";
import { DELETE, GET, POST, PUT } from "../../helpers/axiosConst";
import { API_URLS, BuildAPIUrl, URLS_LOCAL } from "../../utils/config/URLS";
import {
  AddCompany,
  AddCompanyConstructor,
  SetSingleCompany,
} from "../../store/actions/companyActions";
import { toast } from "react-toastify";

export const GetCompany = (
  params = false,
  setState = () => false,
  state = {},
  setOnLoad = () => false
) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(API_URLS.GET_COMPANY),
      {},
      (resp) => {
        setOnLoad(true);
        const { data, pages, total } = resp.data;
        setState({
          ...state,
          isFetchData: false,
          data,
          pages,
          total,
          params: {
            ...state.params,
            page: Number(params.page),
            perPage: Number(params.perPage),
            filtered: params.filtered,
            sorted: params.sorted,
          },
        });
        dispatch(AddCompany(resp.data.data));
      },
      () => {
        setOnLoad(true);
      },
      params && {
        page: Number(params.page),
        perPage: Number(params.perPage),
        sorted: params.sorted,
        filtered: params.filtered,
      }
    );
  };
};

export const GetCompanyConstructor = () => {
  return (dispatch) => {
    NewRequest(GET, BuildAPIUrl(API_URLS.CONSTRUCTOR_COMPANY), null, (res) => {
      dispatch(AddCompanyConstructor(res.data));
    });
  };
};

export const GetSingleCompany = (id, setFormData, formData) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(`/api/constructor/company_card/item/${id}`),
      null,
      (res) => {
        dispatch(SetSingleCompany(res.data));
        setFormData({
          ...formData,
          fields: res.data.fields,
        });
      }
    );
  };
};

export const DeleteCompanyCard = (id, onPageClick, state, navigate) => {
  return (dispatch) => {
    NewRequest(
      DELETE,
      BuildAPIUrl(`/api/constructor/company_card/item/${id}`),
      null,
      () => {
        toast.success("Delete completed");
        if (onPageClick) {
          onPageClick(state.params);
        }
        if (navigate) {
          navigate(URLS_LOCAL.USER.COMPANY);
        }
      }
    );
  };
};

export const EditCompany = (data, id) => {
  NewRequest(PUT, BuildAPIUrl(API_URLS.GET_COMPANY), { data, id: id }, () => {
    toast.success(`Company changed`);
  });
};

export const CreateCompany = (data, items, navigate) => {
  NewRequest(
    POST,
    BuildAPIUrl(API_URLS.GET_COMPANY),
    { data, items },
    (res) => {
      toast.success(`Company added`);
      navigate(`/settings/company-card/${res.data.data.ID}/edit`);
    }
  );
};
