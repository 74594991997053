import React from "react";
import {
  CONSTRUCTOR_DATA_PRODUCTS_COLLECTION,
  SEARCH_URLS,
} from "../constants";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const ConstructorDataProduct = ({ elem }) => {
  return (
    <Link
      className={"search_link"}
      to={SEARCH_URLS[CONSTRUCTOR_DATA_PRODUCTS_COLLECTION](elem.mongo_id)}
    >
      Product: {elem.fields.product_name}
    </Link>
  );
};

ConstructorDataProduct.propTypes = {
  elem: PropTypes.object,
};

export default ConstructorDataProduct;
