import * as contractActions from "../../actions/contractActions/constants";

const initialState = {
  data: {},
  contracts: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case contractActions.SET_CONTRACT:
      return {
        ...state,
        data: action.payload,
      };
    case contractActions.SET_CONTRACT_LIST:
      return {
        ...state,
        contracts: action.payload,
      };
    default:
      return state;
  }
};
