import * as zoomActions from "./constants";

export const ZoomSettings = (data) => {
  return {
    type: zoomActions.ZOOM_SETTINGS,
    payload: data,
  };
};

export const SetZoomSettings = (data) => {
  return {
    type: zoomActions.SET_ZOOM_SETTINGS,
    payload: data,
  };
};
