import * as calendarActions from "./constants";

export const ChangeStep = (value) => {
  if (value === "increment") {
    return {
      type: calendarActions.INCREMENT,
    };
  }
  if (value === "decrement") {
    return {
      type: calendarActions.DECREMENT,
    };
  }
};

export const WizardChange = (data) => {
  return {
    type: calendarActions.WIZARD_CHANGE,
    payload: data,
  };
};
export const StepStart = () => {
  return {
    type: calendarActions.STEP_START,
  };
};

export const SetVideo = (data) => {
  return {
    type: calendarActions.VIDEO_LIST,
    payload: data,
  };
};

export const SetEvents = (data) => {
  return {
    type: calendarActions.SET_EVENTS,
    payload: data,
  };
};

export const SetCalendarList = (data) => {
  return {
    type: calendarActions.SET_CALENDAR_LIST,
    payload: data,
  };
};

export const SetCalendar = (data) => {
  return {
    type: calendarActions.SET_CALENDAR,
    payload: data,
  };
};

export const SetCalendarStatus = (data) => {
  return {
    type: calendarActions.SET_CALENDAR_STATUS,
    payload: data,
  };
};

export const AddGoogleOauth = (data) => {
  return {
    type: calendarActions.ADD_GOOGLE_OAUTH,
    payload: data,
  };
};
