import React from "react";
import { SEARCH_URLS, USER_CALENDAR_COLLECTION } from "../constants";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const UserCalendar = ({ elem }) => {
  return (
    <Link className={"search_link"} to={SEARCH_URLS[USER_CALENDAR_COLLECTION]}>
      {elem.title}
    </Link>
  );
};

UserCalendar.propTypes = {
  elem: PropTypes.object,
};

export default UserCalendar;
