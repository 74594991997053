import * as boardActions from "../../actions/boardActions/constants";

const initialState = {
  selectedBoard: { empty: true },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case boardActions.SELECTED_BOARD:
      return {
        ...state,
        selectedBoard: action.payload,
      };
    default:
      return state;
  }
};
