import * as dashboardActions from "../../actions/dashboardActions/constants";

const initialState = {
  board: [],
  selectedBoard: {
    empty: true,
    columns: {},
  },
  weight: {
    weight: {},
  },
  boardOptions: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case dashboardActions.SET_BOARD:
      return {
        ...state,
        board: action.payload,
        selectedBoard: state.selectedBoard.empty
          ? action.payload[0] !== undefined
            ? action.payload[0]
            : {
                empty: true,
                columns: {},
              }
          : action.payload.length > 0
          ? state.selectedBoard
          : {
              empty: true,
              columns: {},
            },
      };
    case dashboardActions.SET_WEIGHT:
      return {
        ...state,
        weight: {
          ...action.payload,
        },
      };
    case dashboardActions.SET_BOARD_OPTIONS:
      return {
        ...state,
        boardOptions: action.payload,
      };
    case dashboardActions.SET_SELECTED_BOARD:
      return {
        ...state,
        selectedBoard:
          action.payload !== undefined
            ? action.payload
            : {
                empty: true,
                columns: {},
              },
      };
    default:
      return state;
  }
};
