import team8 from "assets/img/team/8.jpg";
import team9 from "assets/img/team/9.jpg";
import team10 from "assets/img/team/10.jpg";
import logosOxford from "assets/img/logos/oxford.png";

export const notifications = [
  {
    id: 8,
    avatar: {
      src: team8,
      size: "xl",
    },
    unread: true,
    children:
      "<strong>David Fincher</strong> declared you as a verified member of <strong>Stanford University.</strong>",
    time: "2d",
    className: "rounded-0 border-x-0 border-300 border-bottom-0",
    to: "#!",
  },
  {
    id: 9,
    avatar: {
      src: team9,
      size: "xl",
    },
    unread: true,
    children:
      "Follow <strong>International Conference on Water and Environmental Engineering (ICWEE)</strong> event arranged by United Nations Development Program on 30 December 2018",
    time: "3d",
    className: "rounded-0 border-x-0 border-300 border-bottom-0",
    to: "#!",
  },
  {
    id: 10,
    avatar: {
      src: team10,
      size: "xl",
    },
    unread: false,
    children:
      "<strong>James Cameron</strong> invited to join the group: United Nations International Children's Fund",
    time: "2d",
    className: "rounded-0 border-x-0 border-300 border-bottom-0",
    to: "#!",
  },
  {
    id: 11,
    avatar: {
      src: logosOxford,
      size: "xl",
    },
    children:
      '<strong>University of Oxford</strong> created an event : "Causal Inference Hilary 2019"',
    time: "1w",
    className: "rounded-top-0 border-x-0 border-300 border-bottom-0",
    unread: false,
    to: "#!",
  },
];

export const notificationsToday = [
  {
    id: 8,
    avatar: {
      src: team8,
      size: "2xl",
    },
    unread: false,
    children:
      "<strong>David Fincher</strong> declared you as a verified member of <strong>Stanford University.</strong>",
    time: "2d",
    className: "rounded-0 border-x-0 border-300 border-bottom-0",
    to: "#!",
  },
  {
    id: 9,
    avatar: {
      src: team9,
      size: "2xl",
    },
    unread: true,
    children:
      "Follow <strong>International Conference on Water and Environmental Engineering (ICWEE)</strong> event arranged by United Nations Development Program on 30 December 2018",
    time: "3d",
    className: "rounded-0 border-x-0 border-300 border-bottom-0",
    to: "#!",
  },
];

export const notificationsEarlier = [
  {
    id: 8,
    avatar: {
      src: team8,
      size: "2xl",
    },
    unread: false,
    children:
      "<strong>David Fincher</strong> declared you as a verified member of <strong>Stanford University.</strong>",
    time: "2d",
    className: "rounded-0 border-x-0 border-300 border-bottom-0",
    to: "#!",
  },
  {
    id: 9,
    avatar: {
      src: team9,
      size: "2xl",
    },
    unread: false,
    children:
      "Follow <strong>International Conference on Water and Environmental Engineering (ICWEE)</strong> event arranged by United Nations Development Program on 30 December 2018",
    time: "3d",
    className: "rounded-0 border-x-0 border-300 border-bottom-0",
    to: "#!",
  },
  {
    id: 11,
    avatar: {
      src: logosOxford,
      size: "2xl",
    },
    children:
      '<strong>University of Oxford</strong> created an event : "Causal Inference Hilary 2019"',
    time: "1w",
    className: "rounded-top-0 border-x-0 border-300 border-bottom-0",
    unread: false,
    to: "#!",
  },
];
