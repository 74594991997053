import React, { useState } from "react";
import { Form, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FalconCloseButton from "../../common/FalconCloseButton";
import { useAsyncDebounce } from "react-table";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { GetSearchData } from "../../../requests/searchRequests/index";
import {
  AGILE_BOARD_TASK_COLLECTION,
  CONSTRUCTOR_DATA_CLIENTS_COLLECTION,
  CONSTRUCTOR_DATA_COMPANIES_COLLECTION,
  CONSTRUCTOR_DATA_PRODUCTS_COLLECTION,
  CONSTRUCTOR_SETTINGS_COLLECTION,
  CURRENCY_COLLECTION,
  DOMAINS_MAILING_ADDRESS_COLLECTION,
  DOMAINS_MAILING_COLLECTION,
  EMAIL_TEMPLATES_COLLECTION,
  OPPORTUNITY_DEAL_COLLECTION,
  PIPELINE_COLLECTION,
  PIPELINE_TRIGGERS_COLLECTION,
  ROLES_COLLECTION,
  SEARCH_HEADERS,
  TAX_COLLECTION,
  USER_CALENDAR_COLLECTION,
  USER_CALENDAR_EVENT_COLLECTION,
  USERS_COLLECTION,
} from "./constants";
import ConstructorDataProduct from "./fields/constructor_data_prduct";
import OpportunityDeal from "./fields/opportunity_deal";
import EmailTemplates from "./fields/email_templates";
import Domains from "./fields/domains";
import ConstructorDataClients from "./fields/constructor_data_clients";
import DomainsMailingAddress from "./fields/domains_mailing_address";
import Users from "./fields/users";
import Pipeline from "./fields/pipeline";
import PipelineTrigger from "./fields/pipeline_trigger";
import AgileBoardTask from "./fields/agile_board_task";
import UserCalendarEvent from "./fields/user_calendar_event";
import ConstructorDataCompanies from "./fields/constructor_data_companies";
import ConstructorSettings from "./fields/constructor_settings";
import Roles from "./fields/roles";
import Tax from "./fields/tax";
import Currency from "./fields/currency";
import UserCalendar from "./fields/user_calendar";
import loadingImg from "../../../assets/img/icons/loading.svg";

const SearchBox = () => {
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [searchResult, setSearchResult] = useState({});
  const [loading, setLoading] = useState(false);
  const toggle = () => setDropdownOpen(!dropdownOpen);
  const controller = new AbortController();
  const onChange = useAsyncDebounce((value) => {
    dispatch(
      GetSearchData(value, setSearchResult, setLoading, controller.signal)
    );
  }, 200);
  return (
    <Dropdown onToggle={toggle} className="search-box">
      <Dropdown.Toggle
        as="div"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
        bsPrefix="toggle"
      >
        <Form className="position-relative me-6 search_form">
          <Form.Control
            type="search"
            placeholder="Search..."
            aria-label="Search"
            className="rounded-pill search-input border-0"
            value={searchInputValue}
            onChange={({ target }) => {
              controller.abort();
              setLoading(true);
              setSearchInputValue(target.value);
              onChange(target.value);
            }}
            onClick={() => setDropdownOpen(false)}
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="position-absolute text-400 search-box-icon"
          />
          {searchInputValue && (
            <div className="search-box-close-btn-container">
              <FalconCloseButton
                size="sm"
                noOutline
                onClick={() => setSearchInputValue("")}
              />
            </div>
          )}
        </Form>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <div className="scrollbar py-3" style={{ maxHeight: "24rem" }}>
          {loading ? (
            <div
              className={
                "d-flex justify-content-center align-items-center loader_submit"
              }
            >
              <img
                src={loadingImg}
                alt="icon"
                className={"attachments_loading me-2"}
              />
            </div>
          ) : !Object.keys(searchResult) ||
            Object.keys(searchResult).length === 0 ? (
            <div className={"text-center search_not_found"}>
              Items not found
            </div>
          ) : (
            Object.keys(searchResult).map((item, index) => (
              <div className={"search_results"} key={index}>
                <div className={"search_results_item"}>
                  <h3>{SEARCH_HEADERS[item]}</h3>
                  {searchResult[item].map((elem, indexElem) => (
                    <div key={indexElem}>
                      {(() => {
                        switch (item) {
                          case CONSTRUCTOR_DATA_PRODUCTS_COLLECTION:
                            return <ConstructorDataProduct elem={elem} />;
                          case OPPORTUNITY_DEAL_COLLECTION:
                            return <OpportunityDeal elem={elem} />;
                          case EMAIL_TEMPLATES_COLLECTION:
                            return <EmailTemplates elem={elem} />;
                          case DOMAINS_MAILING_COLLECTION:
                            return <Domains elem={elem} />;
                          case CONSTRUCTOR_DATA_CLIENTS_COLLECTION:
                            return <ConstructorDataClients elem={elem} />;
                          case DOMAINS_MAILING_ADDRESS_COLLECTION:
                            return <DomainsMailingAddress elem={elem} />;
                          case USERS_COLLECTION:
                            return <Users elem={elem} />;
                          case PIPELINE_COLLECTION:
                            return <Pipeline elem={elem} />;
                          case PIPELINE_TRIGGERS_COLLECTION:
                            return <PipelineTrigger elem={elem} />;
                          case AGILE_BOARD_TASK_COLLECTION:
                            return <AgileBoardTask elem={elem} />;
                          case USER_CALENDAR_EVENT_COLLECTION:
                            return <UserCalendarEvent elem={elem} />;
                          case CONSTRUCTOR_DATA_COMPANIES_COLLECTION:
                            return <ConstructorDataCompanies elem={elem} />;
                          case CONSTRUCTOR_SETTINGS_COLLECTION:
                            return <ConstructorSettings elem={elem} />;
                          case ROLES_COLLECTION:
                            return <Roles elem={elem} />;
                          case TAX_COLLECTION:
                            return <Tax elem={elem} />;
                          case CURRENCY_COLLECTION:
                            return <Currency elem={elem} />;
                          case USER_CALENDAR_COLLECTION:
                            return <UserCalendar elem={elem} />;
                          default:
                            return SEARCH_HEADERS[item];
                        }
                      })()}
                    </div>
                  ))}
                </div>
              </div>
            ))
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SearchBox;
