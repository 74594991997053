import * as zoomActions from "../../actions/zoomActions/constants";

const initialState = {
  zoomOauth: false,
  zoomSettings: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case zoomActions.ZOOM_SETTINGS:
      return {
        ...state,
        zoomOauth: action.payload,
      };
    case zoomActions.SET_ZOOM_SETTINGS:
      return {
        ...state,
        zoomSettings: action.payload,
      };
    default:
      return state;
  }
};
