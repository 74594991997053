import * as filterActions from "../../actions/filterActions/constants";

const initialState = {
  client_items: [],
  company_items: [],
  product_items: [],
  contract_items: [],
  custom_items: [],
  filters: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case filterActions.SET_CLIENT_ITEMS:
      return {
        ...state,
        client_items: action.payload,
      };
    case filterActions.SET_CUSTOM_ITEMS:
      return {
        ...state,
        custom_items: action.payload,
      };
    case filterActions.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case filterActions.SET_COMPANY_ITEMS:
      return {
        ...state,
        company_items: action.payload,
      };
    case filterActions.SET_PRODUCT_ITEMS:
      return {
        ...state,
        product_items: action.payload,
      };
    case filterActions.SET_CONTRACT_ITEMS:
      return {
        ...state,
        contract_items: action.payload,
      };
    default:
      return state;
  }
};
