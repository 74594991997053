import React, { useContext } from "react";
import AppContext, { RouterContext } from "../context/Context";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const RoutesProvider = ({ children }) => {
  const {
    config: { myRole },
  } = useContext(AppContext);
  const pages = useSelector((store) => store.navbar.pagesRoutes);
  const settings = useSelector((store) => store.navbar.settingsRoutes);
  function filterNavbar(pages, settings) {
    const newPagesRoutes = pages.children.filter((item) => {
      if (
        myRole &&
        myRole.permissions["agile boards"] === 0 &&
        item.name === "Tasks"
      ) {
        return false;
      } else if (
        myRole &&
        myRole.permissions.opportunities === 0 &&
        item.name === "Opportunities"
      ) {
        return false;
      } else if (
        myRole &&
        myRole.permissions.calendars === 0 &&
        item.name === "Calendar"
      ) {
        return false;
      } else if (
        myRole &&
        myRole.permissions.clients === 0 &&
        item.name === "Clients"
      ) {
        return false;
      } else {
        return true;
      }
    });
    const newSettingsRoutes = settings.children[0].children?.filter((item) => {
      if (myRole && myRole.permissions.roles === 0 && item.name === "Roles") {
        return false;
      } else if (
        myRole &&
        myRole.permissions.accounts === 0 &&
        item.name === "Organisation"
      ) {
        return false;
      } else if (
        myRole &&
        myRole.permissions["invite user"] === 0 &&
        item.name === "Users"
      ) {
        return false;
      } else if (
        myRole &&
        myRole.permissions.calendars === 0 &&
        item.name === "Calendar"
      ) {
        return false;
      } else if (
        myRole &&
        myRole.permissions.taxes === 0 &&
        item.name === "Taxes"
      ) {
        return false;
      } else if (
        myRole &&
        myRole.permissions.pipelines === 0 &&
        item.name === "Pipelines"
      ) {
        return false;
      } else {
        return true;
      }
    });
    const newConstructorList = settings.children[0].children
      ?.find((item) => item.name === "Constructors")
      .children.filter((elem) => {
        if (
          myRole &&
          myRole.permissions.clients === 0 &&
          elem.name === "Clients"
        ) {
          return false;
        } else {
          return true;
        }
      });
    return [
      { ...pages, children: newPagesRoutes },
      newSettingsRoutes?.length === 0
        ? {
            ...settings,
            children: [...settings.children].filter(
              (item) => item.name !== "Settings"
            ),
          }
        : {
            ...settings,
            children: [...settings.children].map((item) => {
              if (item.name === "Settings") {
                return {
                  ...item,
                  children: [...newSettingsRoutes].map((elem) => {
                    if (elem.name === "Constructors") {
                      return { ...elem, children: newConstructorList };
                    } else {
                      return elem;
                    }
                  }),
                };
              } else {
                return item;
              }
            }),
          },
    ];
  }
  return (
    <RouterContext.Provider value={filterNavbar(pages, settings)}>
      {children}
    </RouterContext.Provider>
  );
};

RoutesProvider.propTypes = { children: PropTypes.node };

export default RoutesProvider;
