import React from "react";
import { CONSTRUCTOR_DATA_CLIENTS_COLLECTION, SEARCH_URLS } from "../constants";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const ConstructorDataClients = ({ elem }) => {
  return (
    <Link
      className={"search_link"}
      to={SEARCH_URLS[CONSTRUCTOR_DATA_CLIENTS_COLLECTION](elem.mongo_id)}
    >
      Client:{" "}
      {`${elem.fields.first_name} ${
        elem.fields.last_name ? elem.fields.last_name : ""
      }`}
    </Link>
  );
};

ConstructorDataClients.propTypes = {
  elem: PropTypes.object,
};

export default ConstructorDataClients;
