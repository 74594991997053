export const GET_MAIL = "GET_MAIL";
export const MAIL_VERIFY = "MAIL_VERIFY";
export const SET_MAIL_STATUS = "SET_MAIL_STATUS";
export const EMAIL_SETTINGS = "EMAIL_SETTINGS";
export const OAUTH_SETTINGS = "OAUTH_SETTINGS";
export const MAIL_TEMPLATES = "MAIL_TEMPLATES";
export const ADD_TEMPLATES = "ADD_TEMPLATES";
export const SET_MESSAGES = "SET_MESSAGES";
export const SET_MESSAGE = "SET_MESSAGE";
export const SET_TEMPLATE_DATA = "SET_TEMPLATE_DATA";
export const DELETE_TEMPLATE_DATA = "DELETE_TEMPLATE_DATA";
export const SET_BB_CODE = "SET_BB_CODE";
