import React from "react";
import { CURRENCY_COLLECTION, SEARCH_URLS } from "../constants";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Currency = ({ elem }) => {
  return (
    <Link className={"search_link"} to={SEARCH_URLS[CURRENCY_COLLECTION]}>
      Currency: {elem.name}
    </Link>
  );
};

Currency.propTypes = {
  elem: PropTypes.object,
};

export default Currency;
