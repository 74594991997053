import * as formActions from "../../actions/formActions/constants";

const initialState = {
  formData: { dontCreated: true },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case formActions.SET_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };
    case formActions.DELETE_FORM_DATA:
      return {
        ...state,
        formData: { dontCreated: true },
      };
    default:
      return state;
  }
};
