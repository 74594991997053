import * as companyActions from "./constants";

export const SetSingleCompany = (data) => {
  return {
    type: companyActions.SET_COMPANY_SINGLE,
    payload: data,
  };
};
export const AddCompanyConstructor = (data) => {
  return {
    type: companyActions.SET_COMPANY_CONSTRUCTOR,
    payload: data,
  };
};
export const AddCompany = (data) => {
  return {
    type: companyActions.SET_COMPANY,
    payload: data,
  };
};
