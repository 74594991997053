import * as mailActions from "./constants";

export const SetBbCode = (data) => {
  return {
    type: mailActions.SET_BB_CODE,
    payload: data,
  };
};
export const SetTemplateData = (data) => {
  return {
    type: mailActions.SET_TEMPLATE_DATA,
    payload: data,
  };
};

export const DeleteTemplateData = () => {
  return {
    type: mailActions.DELETE_TEMPLATE_DATA,
  };
};

export const SetMessage = (data) => {
  return {
    type: mailActions.SET_MESSAGE,
    payload: data,
  };
};

export const SetMessages = (data) => {
  return {
    type: mailActions.SET_MESSAGES,
    payload: data,
  };
};

export const AddTemplateAction = (data) => {
  return {
    type: mailActions.ADD_TEMPLATES,
    payload: data,
  };
};

export const GetTemplateAction = (data) => {
  return {
    type: mailActions.MAIL_TEMPLATES,
    payload: data,
  };
};

export const EmailSettings = (data) => {
  return {
    type: mailActions.EMAIL_SETTINGS,
    payload: data,
  };
};

export const OauthSettings = (data) => {
  return {
    type: mailActions.OAUTH_SETTINGS,
    payload: data,
  };
};

export const setMailStatus = (data) => {
  return {
    type: mailActions.SET_MAIL_STATUS,
    payload: data,
  };
};

export const MailVerify = (data) => {
  return {
    type: mailActions.MAIL_VERIFY,
    payload: data,
  };
};

export const GetMailAction = (data) => {
  return {
    type: mailActions.GET_MAIL,
    payload: data,
  };
};
