import * as domainActions from "../../actions/domainActions/constants";

const initialState = {
  domains: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case domainActions.GET_DOMAIN:
      return {
        ...state,
        domains: action.payload,
      };
    case domainActions.SET_DOMAIN:
      return {
        ...state,
        domains: [...state.domains, ...action.payload],
      };
    case domainActions.DELETE_DOMAIN:
      return {
        ...state,
        domains: state.domains.filter(
          (item) => item.hostname !== action.payload
        ),
      };
    default:
      return state;
  }
};
