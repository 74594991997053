import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Card, Dropdown, ListGroup } from "react-bootstrap";
import { notifications } from "../../../data/notification/notification";
import { isIterableArray } from "helpers/utils";
import useFakeFetch from "../../../hooks/useFakeFetch";
import FalconCardHeader from "../../common/FalconCardHeader";
import Notification from "../../notification/Notification";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import NotificationContextButtons from "../../common/NotificationContextButtons";
import ConfirmButton from "../../common/ConfirmButton";
import { useNavigate } from "react-router";
import { URLS_LOCAL } from "../../../utils/config/URLS";

const NotificationDropdown = () => {
  const navigate = useNavigate();
  // State
  const { data: notificationsList, setData: setNotificationsList } =
    useFakeFetch(notifications);
  const [isOpen, setIsOpen] = useState(false);
  const [isAllRead, setIsAllRead] = useState(false);

  // Handler
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });
  }, []);

  const markAsRead = (e) => {
    e.preventDefault();

    const updatedNewNotifications = notificationsList.map((notification) =>
      Object.prototype.hasOwnProperty.call(notification, "unread")
        ? { ...notification, unread: false }
        : notification
    );

    setIsAllRead(true);
    setNotificationsList(updatedNewNotifications);
  };

  return (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className={classNames("px-0 nav-link", {
          "notification-indicator notification-indicator-green": !isAllRead,
        })}
      >
        <FontAwesomeIcon icon={faBell} transform="shrink-6" className="fs-4" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg">
        <Card className="dropdown-menu-notification dropdown-menu-end">
          <FalconCardHeader
            className="card-header"
            title="Notifications"
            titleTag="h6"
            light={false}
            endEl={<NotificationContextButtons />}
          />
          <ListGroup
            variant="flush"
            className="fw-normal fs--1 scrollbar"
            style={{ maxHeight: "19rem" }}
          >
            {isIterableArray(notificationsList) &&
              notificationsList.map((notification) => (
                <ListGroup.Item key={notification.id} onClick={handleToggle}>
                  <Notification {...notification} flush />
                </ListGroup.Item>
              ))}
          </ListGroup>
          <div className="card-footer text-center" onClick={handleToggle}>
            <ConfirmButton
              text={"See all notifications"}
              classNames={"w-100"}
              handler={() => navigate(URLS_LOCAL.USER.NOTIFICATIONS)}
            />
          </div>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationDropdown;
