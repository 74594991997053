import React from "react";
import { CONSTRUCTOR_SETTINGS_COLLECTION, SEARCH_URLS } from "../constants";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const ConstructorSettings = ({ elem }) => {
  const constructor = elem.type.replace("_", "-");
  return (
    <Link
      className={"search_link"}
      to={SEARCH_URLS[CONSTRUCTOR_SETTINGS_COLLECTION](constructor)}
    >
      Constructor: {elem.title}
    </Link>
  );
};

ConstructorSettings.propTypes = {
  elem: PropTypes.object,
};

export default ConstructorSettings;
