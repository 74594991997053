import React, { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import AppContext from "./context/Context";
import { settings } from "./config";
import { getColor, getItemFromStore } from "helpers/utils";
import { configReducer } from "./store/reducers/configReducer";
import useToggleStyle from "./hooks/useToggleStyle";
import { useSelector } from "react-redux";

const Main = (props) => {
  const role = useSelector((store) => store.role?.role);
  const user = useSelector((store) => store.user?.user);
  const myRole = role.find((item) => item.id === user?.role);
  const configState = {
    isRTL: getItemFromStore("isRTL", settings.isRTL),
    isDark: getItemFromStore("isDark", settings.isDark),
    isNavbarVerticalCollapsed: getItemFromStore(
      "isNavbarVerticalCollapsed",
      settings.isNavbarVerticalCollapsed
    ),
    navbarStyle: getItemFromStore("navbarStyle", settings.navbarStyle),
    showBurgerMenu: settings.showBurgerMenu,
    navbarCollapsed: false,
    myRole,
  };
  const [config, configDispatch] = useReducer(configReducer, configState);
  const { isLoaded } = useToggleStyle(
    config.isRTL,
    config.isDark,
    configDispatch
  );
  const setConfig = (key, value) => {
    configDispatch({
      type: "SET_CONFIG",
      payload: {
        key,
        value,
        setInStore: [
          "isRTL",
          "isDark",
          "isNavbarVerticalCollapsed",
          "navbarStyle",
        ].includes(key),
      },
    });
  };
  useEffect(() => {
    configDispatch({
      type: "CHANGE_ROLE",
      payload: myRole,
    });
  }, [role]);
  if (!isLoaded) {
    return (
      <div
        style={{
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundColor: config.isDark ? getColor("dark") : getColor("light"),
        }}
      />
    );
  }
  return (
    <AppContext.Provider value={{ config, setConfig, configDispatch }}>
      {props.children}
    </AppContext.Provider>
  );
};

Main.propTypes = { children: PropTypes.node };

export default Main;
