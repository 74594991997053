import * as cardActions from "./constants";
import React from "react";

export const AddCard = (data) => {
  return {
    type: cardActions.GET_CARD,
    payload: data,
  };
};
export const AddCustomFields = (data) => {
  return {
    type: cardActions.SET_CUSTOM_FIELDS,
    payload: data,
  };
};
export const AddCustomFieldsClient = (data) => {
  return {
    type: cardActions.SET_CUSTOM_FIELDS_CLIENT,
    payload: data,
  };
};
export const AddCustomFieldsProduct = (data) => {
  return {
    type: cardActions.SET_CUSTOM_FIELDS_PRODUCT,
    payload: data,
  };
};
export const AddCustomFieldsCompany = (data) => {
  return {
    type: cardActions.SET_CUSTOM_FIELDS_COMPANY,
    payload: data,
  };
};
export const AddCustomFieldsContract = (data) => {
  return {
    type: cardActions.SET_CUSTOM_FIELDS_CONTRACT,
    payload: data,
  };
};
