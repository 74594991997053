export const ACCOUNTS_COLLECTIONS = "accounts";
export const ADMINS_COLLECTIONS = "administrators";
export const ADMIN_INVITATION_ATTEMPTS_COLL = "admin_invitation_attempts";
export const ACTIVITY_LOG_COLLECTION = "activity_log";
export const COUNTERS_COLLECTION = "counters";
export const CURRENCY_COLLECTION = "currencies";
export const LOGIN_ATTEMPTS_COLLECTION = "login_attempts";
export const USER_INVITATION_ATTEMPTS_COLLECTION = "user_invitation_attempts";
export const USERS_COLLECTION = "users";
export const USER_DEVICES_COLLECTION = "user_devices";
export const DOMAINS_MAILING_COLLECTION = "domains_mailing";
export const DOMAINS_MAILING_ADDRESS_COLLECTION = "domains_mailing_address";
export const DIRECTORS_COLLECTION = "directors";
export const OPERATIONS_COLLECTION = "operations";
export const ROLES_COLLECTION = "roles";
export const ORGANISATIONS_COLLECTION = "organisations";
export const RATES_COLLECTION = "rates";
export const USER_EMAIL_SETTINGS_COLLECTION = "user_email_settings";
export const USER_CALENDAR_COLLECTION = "user_calendar";
export const USER_CALENDAR_EVENT_COLLECTION = "user_calendar_event";
export const USER_OAUTH_SETTINGS_COLLECTION = "user_oauth_settings";
export const VIDEO_CONVERSATIONS_COLLECTION = "video_conversations";
export const MESSAGES_COLLECTION = "messages";
export const FILES_COLLECTION = "files";
export const CONSTRUCTOR_SETTINGS_COLLECTION = "constructor_settings";
export const EMAIL_TEMPLATES_COLLECTION = "email_templates";
export const AGILE_BOARDS_COLLECTION = "agile_boards";
export const AGILE_BOARD_TASK_COLLECTION = "agile_board_tasks";
export const AGILE_BOARD_TASKS_ACTIVITY_LOG_COLLECTION =
  "agile_board_tasks_activity_log";
export const COMMENTS_COLLECTION = "comments";
export const AGILE_BOARD_TASKS_WEIGHT_COLLECTION = "agile_board_tasks_weight";
export const CONSTRUCTOR_DATA_CLIENTS_COLLECTION = "constructor_data_clients";
export const CONSTRUCTOR_DATA_COMPANIES_COLLECTION =
  "constructor_data_companies";
export const CONSTRUCTOR_DATA_CONTRACTS_COLLECTION =
  "constructor_data_contracts";
export const CONSTRUCTOR_DATA_PRODUCTS_COLLECTION = "constructor_data_products";
export const TASK_QUEUE_COLLECTIONS = "task_queue";
export const PIPELINE_COLLECTION = "pipelines";
export const PIPELINE_TRIGGERS_COLLECTION = "pipeline_triggers";
export const OPPORTUNITY_COLLECTION = "opportunities";
export const OPPORTUNITY_DEAL_COLLECTION = "opportunity_deal";
export const OPPORTUNITY_DEALS_WEIGHT_COLLECTION = "opportunity_deals_weight";
export const OPPORTUNITY_DEAL_TRANSACTION_COLLECTION =
  "opportunity_deal_transaction";
export const OPPORTUNITY_DEAL_ACTIVITY_LOG_COLLECTION =
  "opportunity_deal_activity_log";
export const TRANSACTION_COLLECTION = "transaction";
export const TAX_COLLECTION = "tax";
export const TAX_ACTIVITY_LOG_COLLECTION = "tax_activity_log";

export const SEARCH_HEADERS = {
  [ACCOUNTS_COLLECTIONS]: "Accounts",
  [ADMINS_COLLECTIONS]: "Administrators",
  [ADMIN_INVITATION_ATTEMPTS_COLL]: "Admin invitation attempts",
  [ACTIVITY_LOG_COLLECTION]: "Activity log",
  [COUNTERS_COLLECTION]: "Counters",
  [CURRENCY_COLLECTION]: "Currencies",
  [LOGIN_ATTEMPTS_COLLECTION]: "Login attempts",
  [USER_INVITATION_ATTEMPTS_COLLECTION]: "User invitation attempts",
  [USERS_COLLECTION]: "Users",
  [USER_DEVICES_COLLECTION]: "User devices",
  [DOMAINS_MAILING_COLLECTION]: "Domains mailing",
  [DOMAINS_MAILING_ADDRESS_COLLECTION]: "Domains mailing address",
  [DIRECTORS_COLLECTION]: "Directors",
  [OPERATIONS_COLLECTION]: "Operations",
  [ROLES_COLLECTION]: "Roles",
  [ORGANISATIONS_COLLECTION]: "Organisations",
  [RATES_COLLECTION]: "Rates",
  [USER_EMAIL_SETTINGS_COLLECTION]: "User email settings",
  [USER_CALENDAR_COLLECTION]: "User calendar",
  [USER_CALENDAR_EVENT_COLLECTION]: "User calendar event",
  [USER_OAUTH_SETTINGS_COLLECTION]: "User oauth event",
  [VIDEO_CONVERSATIONS_COLLECTION]: "Video conversations",
  [MESSAGES_COLLECTION]: "Messages",
  [FILES_COLLECTION]: "Files",
  [CONSTRUCTOR_SETTINGS_COLLECTION]: "Constructor settings",
  [EMAIL_TEMPLATES_COLLECTION]: "Email templates",
  [AGILE_BOARDS_COLLECTION]: "Agile boards",
  [AGILE_BOARD_TASK_COLLECTION]: "Agile board tasks",
  [AGILE_BOARD_TASKS_ACTIVITY_LOG_COLLECTION]: "Agile board tasks activity log",
  [COMMENTS_COLLECTION]: "Comments",
  [AGILE_BOARD_TASKS_WEIGHT_COLLECTION]: "Agile board tasks weight",
  [CONSTRUCTOR_DATA_CLIENTS_COLLECTION]: "Constructor data clients",
  [CONSTRUCTOR_DATA_COMPANIES_COLLECTION]: "Constructor data companies",
  [CONSTRUCTOR_DATA_CONTRACTS_COLLECTION]: "Constructor data contracts",
  [CONSTRUCTOR_DATA_PRODUCTS_COLLECTION]: "Constructor data products",
  [TASK_QUEUE_COLLECTIONS]: "Task queue",
  [PIPELINE_COLLECTION]: "Pipelines",
  [PIPELINE_TRIGGERS_COLLECTION]: "Pipeline triggers",
  [OPPORTUNITY_COLLECTION]: "Opportunities",
  [OPPORTUNITY_DEAL_COLLECTION]: "Opportunity deal",
  [OPPORTUNITY_DEALS_WEIGHT_COLLECTION]: "Opportunity deals weight",
  [OPPORTUNITY_DEAL_TRANSACTION_COLLECTION]: "Opportunity deal transaction",
  [OPPORTUNITY_DEAL_ACTIVITY_LOG_COLLECTION]: "Opportunity deal activity log",
  [TRANSACTION_COLLECTION]: "Transaction",
  [TAX_COLLECTION]: "Tax",
  [TAX_ACTIVITY_LOG_COLLECTION]: "Tax activity log",
};
export const SEARCH_URLS = {
  [CONSTRUCTOR_DATA_PRODUCTS_COLLECTION]: (id) =>
    `/settings/product/${id}/edit`,
  [CONSTRUCTOR_DATA_COMPANIES_COLLECTION]: (id) =>
    `/settings/company-card/${id}/edit`,
  [CONSTRUCTOR_DATA_CLIENTS_COLLECTION]: (id) =>
    `/settings/client-card/${id}/edit`,
  [OPPORTUNITY_DEAL_COLLECTION]: (board, deal) =>
    `/deals/${board}/deal/${deal}`,
  [EMAIL_TEMPLATES_COLLECTION]: (id) => `/settings/template/${id}/edit`,
  [DOMAINS_MAILING_COLLECTION]: "/settings/domains",
  [DOMAINS_MAILING_ADDRESS_COLLECTION]: "/settings/mail",
  [USERS_COLLECTION]: "/settings/users",
  [PIPELINE_COLLECTION]: (id) => `/settings/work-flow/${id}`,
  [PIPELINE_TRIGGERS_COLLECTION]: (id) => `/settings/work-flow/trigger/${id}`,
  [AGILE_BOARD_TASK_COLLECTION]: (board, task) =>
    `/tasks/${board}/task/${task}`,
  [USER_CALENDAR_EVENT_COLLECTION]: () => `/calendar`,
  [USER_CALENDAR_COLLECTION]: "/calendar",
  [CONSTRUCTOR_SETTINGS_COLLECTION]: (constructor) =>
    `/settings/${constructor}`,
  [ROLES_COLLECTION]: (role) => `/settings/roles/${role}/edit`,
  [TAX_COLLECTION]: "/taxes",
  [CURRENCY_COLLECTION]: "/currencies",
};
