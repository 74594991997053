export const URL_PROTOCOL =
  process.env.HTTP_PROTOCOL ||
  location.protocol.substring(0, location.protocol.length - 1);
export const URL_HOST = process.env.HTTP_HOST || location.host;
export const URL_PORT = +process.env.HTTP_PORT || location.port;

export const URL_FULL = URL_PROTOCOL + "://" + URL_HOST;

export const API_URLS = {
  SIGN_UP: "/api/sign-up/",
  INVITE_UP: "/api/activate",
  SIGN_IN: "/api/sign-in/",
  FORGOT_PASSWORD: "/api/user/password/send-reset-email",
  RESET_PASSWORD: "/api/user/password/reset",
  GET_CONSTRUCTOR_FILTERS: "/api/options/filters",
  USER_PING: "/api/user/ping",
  REFRESH_TOKEN: "/api/user/refresh-token",
  LOGOUT: "/api/user/logout",
  CLOSE_SESSION: "/api/user/close-other-sessions",
  GET_DATA_URL: "/api/profile",
  GET_ORGANIZATION_DATA_URL: "/api/settings/organisation",
  SET_FINANCE: "/api/settings/organisation/finance-settings",
  GET_PIPELINE: "/api/pipelines",
  OPTION_RATES: "/api/options/rates",
  GET_PIPELINE_OPTIONS: "/api/options/pipeline",
  GET_DOMAINS: "/api/settings/domains",
  SET_DOMAINS: "/api/settings/domains",
  DOMAIN_VERIFY: "/api/settings/domains/verify",
  GET_MAIL: "/api/settings/mail-addresses",
  GET_TEMPLATES: "/api/settings/email-templates",
  GET_ROLE: "/api/roles",
  SEARCH: "/api/search",
  GET_PERMISSIONS: "/api/roles/operations",
  GET_SESSIONS: "/api/user/active-sessions",
  MAIL_SETTINGS_DELETE: "/api/user/email-settings/revoke",
  MAIL_SETTINGS_ADD: "/api/user/email-settings",
  MAIL_DELETE_OAUTH: "/api/user/email-settings/oauth/gmail",
  MAIL_OAUTH_CONFIRM: "/api/user/email-settings/oauth/gmail/add-token",
  ATTACHMENT: "/api/files/attachment",
  CALENDAR_CONFIRM: "/api/calendar/oauth/google/add-token",
  GET_CALENDAR: "/api/calendar/",
  PIPELINE_TRIGGER: "/api/pipelines/:id/triggers",
  GET_LIST: "/api/calendar/oauth/google/get-calendar-list",
  CALENDAR_SYNC: "/api/calendar/oauth/google/sync-calendars",
  GET_EVENTS: "/api/calendar/events/",
  GET_VIDEO: "/api/video-conversations",
  MAIL_STATUS: "/api/user/email-settings/get-status",
  CALENDAR_STATUS: "/api/calendar/oauth/google/get-status",
  GET_GOOGLE_URL: "/api/user/email-settings/oauth/gmail/get-url",
  GET_GOOGLE_URL_CALENDAR: "/api/calendar/oauth/google/get-url",
  GET_URL_ZOOM: "/api/calendar/oauth/zoom/get-url",
  ADD_ZOOM_TOKEN: "/api/calendar/oauth/zoom/add-token",
  GET_ZOOM_STATUS: "/api/calendar/oauth/zoom/get-status",
  DELETE_ZOOM_INTEGRATION: "/api/calendar/oauth/zoom/revoke",
  DELETE_CALENDAR: "/api/calendar/oauth/google/revoke",
  REMOVE_SYNC_CALENDAR: "/api/calendar/oauth/google/remove-sync",
  MAIL_VERIFY: "/api/settings/mail-addresses/send-verification",
  REGISTER_MAIL_VERIFY: "/api/verify-email",
  CHANGE_EMAIL_VERIFY: "/api/email-change/verify",
  MAIL_CHANGE_CONFIRM: "/api/email-change/confirm",
  VERIFY_CONFIRM: "/api/settings/mail-addresses/confirm",
  CONSTRUCTOR_CARD: "/api/settings/constructor/client_card",
  CONSTRUCTOR_CUSTOM_LIST: "/api/options/constructor-settings/custom-list",
  CONSTRUCTOR_CUSTOM_CARD: "/api/settings/constructor/custom",
  CONSTRUCTOR_CUSTOM_GET: "/api/constructor/custom",
  CONSTRUCTOR_COMPANY: "/api/settings/constructor/company_card",
  CONSTRUCTOR_PRODUCT: "/api/settings/constructor/product",
  CONSTRUCTOR_CONTRACT: "/api/settings/constructor/contract",
  CONSTRUCTOR_FILTERS: "constructor/:type/apply-filters",
  GET_CURRENCIES: "/api/settings/currencies",
  CONSTRUCTOR: "/api/settings/constructor",
  CONSTRUCTOR_CUSTOM: "/api/settings/constructor/custom",
  LOOKUP: "/api/options/lookup-items",
  GET_DASHBOARD: "/api/agile-boards",
  GET_OPPORTUNITIES: "/api/opportunities",
  GET_TRANSACTIONS: "/api/transactions",
  GET_TAXES: "/api/taxes",
  GET_ENTITIES: "/api/options/commented-entities",
  GET_BOARD_OPTIONS: "/api/options/agile-board",
  GET_OPPORTUNITIES_OPTIONS: "/api/options/opportunity",
  GET_COMMENTS: "/api/comments",
  GET_CLIENTS: "/api/constructor/client_card",
  GET_COMPANY: "/api/constructor/company_card",
  GET_PRODUCT: "/api/constructor/product",
  GET_CONTRACT: "/api/constructor/contract",
  TWO_FACTOR_GENERATE: "/api/user/2fa/generate-secret",
  TWO_FACTOR_SET: "/api/user/2fa",
  TWO_FACTOR_CHECK: "/api/user/2fa/verify-otp",
  CHANGE_PASSWORD: "/api/user/password",
  CHANGE_EMAIL: "/api/email-change",
  USERS: "/api/settings/users",
  AVATAR_ADD: "/api/user/profile/avatar",
  RELATED_OPTIONS: "/api/options/file-relation-entities",
  OAUTH: "/api/auth/sso",
  BIND_AUTH: "/api/sso/bind",
  UNBIND_AUTH: "/api/sso/unbind",
  GET_USER_STATUS: "/api/options/user-statuses",
  BB_CODE_STATIC: "/api/options/bbcodes/static",
  BB_CODE: "/api/options/bbcodes",
  GET_COUNTRIES: "/api/options/countries",
  USERS_INVITE: "/api/settings/users/invite",
};

const URLS_LOCAL_GROUPS_AUTH = {
  LOGIN: "/login",
  INVITATION: "/users/activate",
  SERVER_ERROR: "/errors/500",
  REGISTER: "/register",
  VERIFY: "/verify",
  MAIL_CONFIRM: "/users/verify-email",
  RESET: "/password/reset",
  FORGOT_PASSWORD: "/forgot-password",
  PASSWORD_RESET: "/forgot-password/reset",
  TWO_FACTOR: "/two-factor",
  AUTH: "/oauth",
};

const URLS_LOCAL_GROUPS_USER = {
  DASHBOARD: "/",
  PROFILE: "/settings/user/:params",
  CLIENTS: "/clients",
  CREATE_CLIENT: "/clients/create",
  CREATE_CUSTOM_CARD: "/custom-constructor/create/:id",
  CREATE_COMPANY: "/company/create",
  CREATE_PRODUCT: "/product/create",
  TAXES: "/taxes",
  CREATE_CONTRACT: "/contract/create",
  AGILE_BOARD: "/settings/agile-board",
  NOTIFICATIONS: "/notifications",
  DASHBOARDS: "/dashboards",
  COMPANY: "/company",
  PRODUCT: "/product",
  CURRENCIES: "/currencies",
  FORMS: "/constructor-forms",
  CONTRACT: "/contract",
  TASKS_BOARD: "/tasks/:board",
  TASKS: "/tasks",
  OPPORTUNITIES_BOARD: "/opportunities/:board",
  OPPORTUNITIES: "/opportunities",
  CALENDAR: "/calendar",
  CLIENTS_EDIT: "/clients/:id/:param",
  COMPANY_EDIT: "/company/:id/:param",
  PRODUCT_EDIT: "/product/:id/:param",
  CALENDAR_READ: "/calendar/:id",
  TASK_BOARD_READ: "/tasks/:url/task/:id",
  DEAL_READ: "/deals/:url/deal/:id",
  CALENDAR_EDIT: "/calendar/:id/edit",
  CLIENTS_READ_MESSAGE: "/clients/:id/:param/read/:messageId",
  CLIENTS_COMPOSE: "/clients/:id/:param/compose",
  USERS: "/settings/users",
  ORGANIZATION: "/settings/organization",
  USER_PROFILE: "/settings/user/profile",
  DOMAIN: "/settings/domains",
  INVOICE: "/settings/invoice",
  INVOICE_EDIT: "/settings/invoice/:id",
  WORK_FLOW: "/settings/work-flow",
  WORK_FLOW_PAGES: "/settings/work-flow/:id",
  WORK_FLOW_CREATE: "/settings/work-flow/create",
  WORK_FLOW_TRIGGERS: "/settings/work-flow/trigger/:id",
  CLIENT_CARD: "/settings/client-card",
  CUSTOM_CONSTRUCTOR_EDIT: "/custom-constructor/:id",
  CUSTOM_CONSTRUCTOR_SETTINGS_EDIT: "/settings/custom-constructor/:id",
  COMPANY_CARD: "/settings/company-card",
  PRODUCT_CARD: "/settings/product-card",
  CONTRACT_CARD: "/settings/contract-card",
  ADD_CONSTRUCTOR: "/settings/add-constructor",
  CARD_WRAPPER: "/settings/client-card/:id/edit",
  CARD_CUSTOM_EDIT_CARD: "/settings/custom-card/:id/edit-card/:card",
  COMPANY_WRAPPER: "/settings/company-card/:id/edit",
  PRODUCT_WRAPPER: "/settings/product/:id/edit",
  MAIL: "/settings/mail",
  MAIL_CHANGE: "/organisation/email-change/verify",
  MAIL_CHANGE_CONFIRM: "/organisation/email-change/confirm",
  EVENT_LIST: "/events/event-list",
  MAIL_OAUTH: "/settings/mail-settings/oauth",
  MAIL_SETTINGS: "/settings/mail-settings",
  MAIL_TEMPLATES: "/settings/mail-templates",
  ROLE_CREATE: "/settings/roles/create",
  FORM_CREATE: "/settings/constructor-forms/create",
  FORM_EDIT: "/settings/constructor-forms/edit/:id",
  CALENDAR_OAUTH_CONFIRM: "settings/calendar/oauth/google/confirm",
  ROLE_LIST: "/settings/roles",
  CALENDAR_SETTINGS: "/settings/calendar",
  ZOOM: "/settings/zoom-integration",
  ZOOM_AUTHORIZATION: "/settings/zoom/authorization",
  ROLE_EDIT: "/settings/roles/:id/edit",
  TEMPLATE_EDIT: "/settings/template/:id/edit",
  TEMPLATE_CREATE: "/settings/template/create",
};

export const URLS_LOCAL = {
  AUTH: URLS_LOCAL_GROUPS_AUTH,
  USER: URLS_LOCAL_GROUPS_USER,
  NOT_FOUND: "/errors/404",
  NOT_PERMISSIONS: "/not-permissions",
  SERVER_ERROR: "/errors/500",
};

/**
 * @return {string}
 */
export function BuildAPIUrl(url) {
  if (URL_PORT === 80 || URL_PORT === 443) {
    return URL_FULL + url;
  }

  return URL_FULL + ":" + URL_PORT + url;
}
