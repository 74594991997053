import React from "react";
import { SEARCH_URLS, TAX_COLLECTION } from "../constants";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Tax = ({ elem }) => {
  return (
    <Link className={"search_link"} to={SEARCH_URLS[TAX_COLLECTION]}>
      Tax: {elem.title}
    </Link>
  );
};

Tax.propTypes = {
  elem: PropTypes.object,
};

export default Tax;
