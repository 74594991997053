import * as commentsActions from "../../actions/commentsActions/constants";

const initialState = {
  entities: [],
  comments: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case commentsActions.SET_ENTITY:
      return {
        ...state,
        entities: action.payload,
      };
    case commentsActions.SET_COMMENTS:
      return {
        ...state,
        comments: action.payload,
      };
    default:
      return state;
  }
};
