import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

export const isIterableArray = (array) =>
  Array.isArray(array) && !!array.length;

export const customStyles = {
  control: (provided) => ({
    ...provided,
    border: "1px solid #d8e2ef",
    borderRadius: "0.25rem",
    boxShadow: "inset 0 1px 2px rgba(0,0,0,0.075)",
  }),
};

export const colors = [
  {
    name: "Blue",
    value: "rgba(42,133,255, 0.4)",
  },
  {
    name: "Light blue",
    value: "rgba(39,188,254, 0.4)",
  },
  {
    name: "Green",
    value: "rgba(0,210,123, 0.4)",
  },
  {
    name: "Yellow",
    value: "rgba(255,197,84, 0.4)",
  },
  {
    name: "Orange",
    value: "rgba(255,106,85, 0.4)",
  },
  {
    name: "Red",
    value: "rgba(230,55,86, 0.4)",
  },
  {
    name: "Violet",
    value: "rgba(157,112,255, 0.4)",
  },
  {
    name: "Gray",
    value: "rgba(116,129,148, 0.4)",
  },
];

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1540,
};

export const createMarkup = (html) => ({ __html: html });

export const getItemFromStore = (key, defaultValue, store = localStorage) => {
  try {
    return JSON.parse(store.getItem(key)) || defaultValue;
  } catch {
    return store.getItem(key) || defaultValue;
  }
};

export const setItemToStore = (key, payload, store = localStorage) =>
  store.setItem(key, payload);

export const getColor = (name, dom = document.documentElement) => {
  return getComputedStyle(dom).getPropertyValue(`--falcon-${name}`).trim();
};

export const getProductsQuantity = (products) =>
  products.reduce((acc, product) => product.quantity + acc, 0);

export const capitalize = (str) =>
  (str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, " ");

export const camelize = (str) => {
  return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
    if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
    return index === 0 ? match.toLowerCase() : match.toUpperCase();
  });
};

export const flatRoutes = (childrens) => {
  const allChilds = [];

  const flatChild = (childrens) => {
    childrens.forEach((child) => {
      if (child.children) {
        flatChild(child.children);
      } else {
        allChilds.push(child);
      }
    });
  };
  flatChild(childrens);

  return allChilds;
};

export const getFlatRoutes = (children) =>
  children.reduce(
    (acc, val) => {
      if (val.children) {
        return {
          ...acc,
          [camelize(val.name)]: flatRoutes(val.children),
        };
      } else {
        return {
          ...acc,
          unTitled: [...acc.unTitled, val],
        };
      }
    },
    { unTitled: [] }
  );

export const copyToClipBoard = (textFieldRef) => {
  const textField = textFieldRef.current;
  textField.focus();
  textField.select();
  document.execCommand("copy");
};

export const pagination = (currentPage, size) => {
  const pages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  let prev = currentPage - 1 - Math.floor(size / 2);

  if (currentPage - 1 - Math.floor(size / 2) < 0) {
    prev = 0;
  }
  if (currentPage - 1 - Math.floor(size / 2) > pages.length - size) {
    prev = pages.length - size;
  }
  const next = prev + size;

  return pages.slice(prev, next);
};

export const chunk = (arr, chunkSize = 1, cache = []) => {
  const tmp = [...arr];
  if (chunkSize <= 0) return cache;
  while (tmp.length) cache.push(tmp.splice(0, chunkSize));
  return cache;
};
