import * as mailActions from "../../actions/mailActions/constants";

const initialState = {
  mails: [],
  templates: [],
  bbCode: {},
  mailStatus: {},
  emailSettings: false,
  oauthSettings: false,
  templateData: { dontCreated: true },
  messages: {
    data: [],
    pages: 1,
    total: 0,
  },
  message: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case mailActions.SET_MESSAGES:
      return {
        ...state,
        messages: action.payload,
      };
    case mailActions.SET_TEMPLATE_DATA:
      return {
        ...state,
        templateData: action.payload,
      };
    case mailActions.SET_BB_CODE:
      return {
        ...state,
        bbCode: action.payload,
      };
    case mailActions.DELETE_TEMPLATE_DATA:
      return {
        ...state,
        templateData: { dontCreated: true },
      };
    case mailActions.SET_MESSAGE:
      return {
        ...state,
        message: {
          ...action.payload,
          prevID: action.payload.prevID ?? false,
          nextID: action.payload.nextID ?? false,
        },
      };
    case mailActions.ADD_TEMPLATES:
      return {
        ...state,
        templates: [...state.templates, action.payload],
      };
    case mailActions.MAIL_TEMPLATES:
      return {
        ...state,
        templates: action.payload,
      };
    case mailActions.EMAIL_SETTINGS:
      return {
        ...state,
        emailSettings: action.payload,
      };
    case mailActions.OAUTH_SETTINGS:
      return {
        ...state,
        oauthSettings: action.payload,
      };
    case mailActions.SET_MAIL_STATUS:
      return {
        ...state,
        mailStatus: action.payload,
      };
    case mailActions.GET_MAIL:
      return {
        ...state,
        mails: action.payload,
      };
    case mailActions.MAIL_VERIFY:
      return {
        ...state,
        mails: state.mails.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              status: action.payload.status,
            };
          }
          return item;
        }),
      };
    default:
      return state;
  }
};
