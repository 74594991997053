import { AttachmentGetIdRequest } from "../requests/mailRequests/index";

export function sortItems(items, id, sort) {
  if (sort === undefined) {
    return [];
  } else {
    const newItems = items.filter((item) => item.agileBoardColumnID === id);
    const sortItems = [];
    for (let s = 0; s < sort.length; s++) {
      for (let i = 0; i < newItems.length; i++) {
        if (sort[s] === newItems[i].ID) {
          sortItems.push(newItems[i]);
        }
      }
    }
    return sortItems;
  }
}
export function getSelectedClients(list, activeList) {
  if (!activeList) return;
  const newData = [];
  for (let i = 0; i < list.length; i++) {
    for (let y = 0; y < activeList.length; y++) {
      if (list[i].id == activeList[y]) {
        newData.push(list[i]);
      }
    }
  }
  return newData;
}
export function newColumns(newItem, old) {
  const sortItems = newItem;
  for (let s = 0; s < sortItems.length; s++) {
    for (let i = 0; i < old.length; i++) {
      if (sortItems[s] === old[i]) {
        sortItems.splice(s, 1);
      }
    }
  }
  return sortItems;
}
export function sortColumns(items, sort) {
  if (!sort) {
    return [];
  } else {
    const sortItems = [];
    for (let s = 0; s < sort.length; s++) {
      for (let i = 0; i < items.length; i++) {
        if (sort[s] === items[i].id) {
          sortItems.push(items[i]);
        }
      }
    }
    return sortItems;
  }
}
export const handleAddAttachment = (
  files,
  fileRelation,
  attachmentsIds,
  setAttachmentsIds,
  setAttachmentProgress,
  progressData,
  setProgressData,
  attachments,
  setAttachments,
  handleSubmit = () => false,
  target = { value: "" }
) => {
  if (files.length === 0) return;
  AttachmentGetIdRequest(
    Object.values(files),
    fileRelation.id,
    attachmentsIds,
    setAttachmentsIds,
    setAttachmentProgress,
    progressData,
    setProgressData,
    attachments,
    handleSubmit,
    target
  );
  const fileArray = [];
  Array.from(files).forEach((file) => {
    const { name, size, type } = file;
    const newFile = {
      name,
      size,
      type,
      file,
    };
    fileArray.push(newFile);
  });
  setAttachments([...attachments, ...fileArray]);
};
export function onDropHandlerAttachments(
  e,
  fileRelation,
  attachmentsIds,
  setAttachmentsIds,
  setAttachmentProgress,
  progressData,
  setProgressData,
  attachments,
  setAttachments,
  handleSubmit = () => false,
  target = { value: "" }
) {
  e.preventDefault();
  let files = [e.dataTransfer.files][0];
  if (files.length === 0) return;
  AttachmentGetIdRequest(
    Object.values(files),
    fileRelation.id,
    attachmentsIds,
    setAttachmentsIds,
    setAttachmentProgress,
    progressData,
    setProgressData,
    attachments,
    handleSubmit,
    target
  );
  const fileArray = [];
  Array.from(files).forEach((file) => {
    const { name, size, type } = file;
    const newFile = {
      name,
      size,
      type,
      file,
    };
    fileArray.push(newFile);
  });
  setAttachments([...attachments, ...fileArray]);
}
export const handleDetachAttachment = (
  element,
  index,
  setAttachments,
  attachments,
  setAttachmentsIds,
  attachmentsIds,
  progressData = {},
  setProgressData = () => false
) => {
  const test = Object.entries(progressData);
  test.splice(index, 1);
  for (let i = 0; i < test.length; i++) {
    test[i][0] = i;
  }
  setProgressData(Object.fromEntries(test));
  setAttachments(
    attachments.filter(
      (attachment, attachmentIndex) => attachmentIndex !== index
    )
  );
  setAttachmentsIds(
    [...attachmentsIds].filter(
      (attachment, attachmentIndex) => attachmentIndex !== index
    )
  );
};
