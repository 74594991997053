import React from "react";
import { DOMAINS_MAILING_ADDRESS_COLLECTION, SEARCH_URLS } from "../constants";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const DomainsMailingAddress = ({ elem }) => {
  return (
    <Link
      className={"search_link"}
      to={SEARCH_URLS[DOMAINS_MAILING_ADDRESS_COLLECTION]}
    >
      Email: {elem.name}
    </Link>
  );
};

DomainsMailingAddress.propTypes = {
  elem: PropTypes.object,
};

export default DomainsMailingAddress;
