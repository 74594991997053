import { NewRequest } from "../../helpers/axios";
import { DELETE, GET, POST, PUT } from "../../helpers/axiosConst";
import { API_URLS, BuildAPIUrl } from "../../utils/config/URLS";
import { toast } from "react-toastify";
import {
  AddNewRole,
  ChangeRoleAction,
  SetOperations,
  SetRole,
} from "../../store/actions/roleActions";

export const GetRole = (setOnLoad = () => false) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(API_URLS.GET_ROLE),
      null,
      (res) => {
        setOnLoad(true);
        dispatch(SetRole(res.data));
      },
      () => {
        setOnLoad(true);
      }
    );
  };
};

export const ChangeRole = (data) => {
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(API_URLS.GET_ROLE),
      data,
      (res) => {
        toast.success("Role changed");
        window.scrollTo(0, 0);
        dispatch(ChangeRoleAction(res.data.role));
        dispatch(GetRole());
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const AddRole = (data, navigate) => {
  return (dispatch) => {
    NewRequest(
      POST,
      BuildAPIUrl(API_URLS.GET_ROLE),
      data,
      (res) => {
        dispatch(AddNewRole(res.data.role));
        toast.success("Role added");
        navigate(`/settings/roles/${res.data.role.id}/edit`);
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const DeleteRole = (data) => {
  return (dispatch) => {
    NewRequest(
      DELETE,
      BuildAPIUrl(API_URLS.GET_ROLE),
      data,
      () => {
        dispatch(GetRole());
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const GetOperations = () => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(API_URLS.GET_PERMISSIONS),
      null,
      (res) => {
        dispatch(SetOperations(res.data));
      },
      (err) => {
        console.log(err);
      }
    );
  };
};
