import React from "react";
import { PIPELINE_COLLECTION, SEARCH_URLS } from "../constants";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Pipeline = ({ elem }) => {
  return (
    <Link
      className={"search_link"}
      to={SEARCH_URLS[PIPELINE_COLLECTION](elem.mongo_id)}
    >
      Pipeline: {elem.title}
    </Link>
  );
};

Pipeline.propTypes = {
  elem: PropTypes.object,
};

export default Pipeline;
