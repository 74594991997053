import * as navbarActions from "../../actions/navbarActions/constants";
import { pagesRoutes, settingsRoutes } from "../../../routes/routes";
import { UPDATE_NAVBAR } from "../../actions/navbarActions/constants";

const initialState = {
  pagesRoutes: pagesRoutes,
  settingsRoutes: settingsRoutes,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case navbarActions.SET_NAVBAR_PAGES:
      return {
        ...state,
        pagesRoutes: {
          ...state.pagesRoutes,
          children: [
            ...state.pagesRoutes.children,
            ...action.payload.filter(
              (item) =>
                !state.pagesRoutes.children
                  .map((elem) => elem.name)
                  .includes(item.name)
            ),
          ],
        },
      };
    case navbarActions.SET_NAVBAR_SETTINGS:
      return {
        ...state,
        settingsRoutes: {
          ...state.settingsRoutes,
          children: state.settingsRoutes.children.map((item) => {
            if (item.name === "Settings") {
              return {
                ...item,
                children: item.children.map((child) => {
                  if (child.name === "Constructors") {
                    return {
                      ...child,
                      children: [
                        ...child.children,
                        ...action.payload.filter(
                          (action) =>
                            !state.settingsRoutes.children
                              .find((elem) => elem.name === "Settings")
                              .children.find(
                                (finder) => finder.name === "Constructors"
                              )
                              .children.map((mapper) => mapper.name)
                              .includes(action.name)
                        ),
                      ],
                    };
                  } else {
                    return child;
                  }
                }),
              };
            } else {
              return item;
            }
          }),
        },
      };
    case navbarActions.SET_DELETE_SETTINGS:
      return {
        ...state,
        pagesRoutes: {
          ...state.pagesRoutes,
          children: [
            ...state.pagesRoutes.children.filter(
              (item) => item.name !== action.payload
            ),
          ],
        },
        settingsRoutes: {
          ...state.settingsRoutes,
          children: state.settingsRoutes.children.map((item) => {
            if (item.name === "Settings") {
              return {
                ...item,
                children: item.children.map((child) => {
                  if (child.name === "Constructors") {
                    return {
                      ...child,
                      children: [
                        ...child.children.filter(
                          (item) => item.name !== action.payload
                        ),
                      ],
                    };
                  } else {
                    return child;
                  }
                }),
              };
            } else {
              return item;
            }
          }),
        },
      };
    case navbarActions.UPDATE_NAVBAR:
      return {
        ...state,
        pagesRoutes: {
          ...state.pagesRoutes,
          children: [
            ...state.pagesRoutes.children.map((item) => {
              if (item.name === action.payload.oldSubType) {
                return {
                  name: action.payload.subType,
                  icon: action.payload.icon,
                  active: true,
                  to: `/custom-constructor/${action.payload.subType}`,
                };
              } else {
                return item;
              }
            }),
          ],
        },
        settingsRoutes: {
          ...state.settingsRoutes,
          children: state.settingsRoutes.children.map((item) => {
            if (item.name === "Settings") {
              return {
                ...item,
                children: item.children.map((child) => {
                  if (child.name === "Constructors") {
                    return {
                      ...child,
                      children: [
                        ...child.children.map((item) => {
                          if (item.name === action.payload.oldSubType) {
                            return {
                              name: action.payload.subType,
                              active: true,
                              to: `/settings/custom-constructor/${action.payload.subType}`,
                            };
                          } else {
                            return item;
                          }
                        }),
                      ],
                    };
                  } else {
                    return child;
                  }
                }),
              };
            } else {
              return item;
            }
          }),
        },
      };
    default:
      return state;
  }
};
