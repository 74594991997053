import {
  faBuilding,
  faCalendar,
  faFileContract,
  faHome,
  faMemory,
  faPrint,
  faSitemap,
  faTasks,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { faIdCard } from "@fortawesome/free-regular-svg-icons";
import { URLS_LOCAL } from "../utils/config/URLS";
import { faDeskpro } from "@fortawesome/free-brands-svg-icons";
export const pagesRoutes = {
  label: "pages",
  labelDisable: true,
  children: [
    {
      name: "Home",
      icon: faHome,
      active: true,
      to: "/",
    },
    {
      name: "Tasks",
      icon: faTasks,
      active: true,
      to: URLS_LOCAL.USER.TASKS,
    },
    {
      name: "Opportunities",
      icon: faUsers,
      active: true,
      to: URLS_LOCAL.USER.OPPORTUNITIES,
    },
    {
      name: "Calendar",
      icon: faCalendar,
      active: true,
      to: URLS_LOCAL.USER.CALENDAR,
    },
    {
      name: "Clients",
      icon: faIdCard,
      active: true,
      to: URLS_LOCAL.USER.CLIENTS,
    },
    {
      name: "Companies",
      icon: faBuilding,
      active: true,
      to: URLS_LOCAL.USER.COMPANY,
    },
    {
      name: "Products",
      icon: faSitemap,
      active: true,
      to: URLS_LOCAL.USER.PRODUCT,
    },
    {
      name: "Contracts",
      icon: faFileContract,
      active: true,
      to: URLS_LOCAL.USER.CONTRACT,
    },
    {
      icon: faDeskpro,
      name: "Dashboards",
      to: URLS_LOCAL.USER.DASHBOARDS,
      active: true,
    },
  ],
};

export const settingsRoutes = {
  label: "settings",
  children: [
    {
      name: "Settings",
      icon: faMemory,
      active: true,
      children: [
        {
          name: "Profile",
          to: URLS_LOCAL.USER.USER_PROFILE,
          active: true,
        },
        {
          name: "Organisation",
          to: URLS_LOCAL.USER.ORGANIZATION,
          active: true,
        },
        {
          name: "Users",
          to: URLS_LOCAL.USER.USERS,
          active: true,
        },
        {
          name: "Calendar",
          active: true,
          to: URLS_LOCAL.USER.CALENDAR_SETTINGS,
        },
        {
          name: "Zoom",
          active: true,
          to: URLS_LOCAL.USER.ZOOM,
        },
        {
          name: "Constructors",
          active: true,
          children: [
            {
              name: "Add constructor",
              to: URLS_LOCAL.USER.ADD_CONSTRUCTOR,
              active: true,
            },
            {
              name: "Clients",
              to: URLS_LOCAL.USER.CLIENT_CARD,
              active: true,
            },
            {
              name: "Companies",
              to: URLS_LOCAL.USER.COMPANY_CARD,
              active: true,
            },
            {
              name: "Contracts",
              to: URLS_LOCAL.USER.CONTRACT_CARD,
              active: true,
            },
            {
              name: "Products",
              to: URLS_LOCAL.USER.PRODUCT_CARD,
              active: true,
            },
          ],
        },
        {
          name: "Roles",
          active: true,
          to: URLS_LOCAL.USER.ROLE_LIST,
        },
        {
          name: "Taxes",
          active: true,
          to: URLS_LOCAL.USER.TAXES,
        },
        {
          name: "Currencies",
          active: true,
          to: URLS_LOCAL.USER.CURRENCIES,
        },
        {
          name: "Constructor forms",
          active: true,
          to: URLS_LOCAL.USER.FORMS,
        },
        {
          name: "Mail",
          active: true,
          children: [
            {
              name: "Mail Address",
              to: URLS_LOCAL.USER.MAIL,
              active: true,
            },
            {
              name: "Settings",
              to: URLS_LOCAL.USER.MAIL_SETTINGS,
              active: true,
            },
            {
              name: "Templates",
              to: URLS_LOCAL.USER.MAIL_TEMPLATES,
              active: true,
            },
          ],
        },
        {
          name: "Domain",
          to: URLS_LOCAL.USER.DOMAIN,
          active: true,
        },
        {
          name: "Pipelines",
          to: URLS_LOCAL.USER.WORK_FLOW,
          active: true,
        },
      ],
    },
    {
      name: "Documentation",
      icon: faPrint,
      active: true,
      exact: true,
      target: "_docs",
      to: "//docs.sellbyx.com/",
    },
  ],
};

export default [pagesRoutes, settingsRoutes];
