import axios from "axios";
import { toast } from "react-toastify";

export function AxiosSetAuth(token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export function NewRequest(
  method,
  url,
  data = {},
  success = () => {},
  failure = () => {},
  params = {},
  onUploadProgress = () => {},
  onDownloadProgress = () => {}
) {
  return axios({
    method,
    url,
    data,
    onUploadProgress: onUploadProgress,
    onDownloadProgress: onDownloadProgress,
    headers: {
      "Content-Type": "application/json",
    },
    params,
  })
    .then(success)
    .catch((err) => {
      failure(err);
      if (failure() !== "not notify") {
        if (err?.response?.data?.error) {
          toast.error(err.response.data.error[0].message);
        } else if (err?.response?.data?.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.message);
        }
      }
    });
}

export function NewRequestSignal(
  method,
  url,
  data = {},
  success = () => {},
  failure = () => {},
  params = {},
  signal
) {
  return axios({
    method,
    url,
    data,
    headers: {
      "Content-Type": "application/json",
    },
    params,
    signal,
  })
    .then(success)
    .catch((err) => {
      failure(err);
    });
}

export function NewRequestProgress(
  method,
  url,
  data = {},
  success = () => {},
  failure = () => {},
  params = {},
  handler = () => false,
  progressData = {},
  setProgressData = () => false,
  files = [],
  attachments = []
) {
  return axios({
    method,
    url,
    data,
    headers: {
      "Content-Type": "application/json",
    },
    onUploadProgress: (progressEvent) => {
      const progress = (progressEvent.loaded / progressEvent.total) * 100;
      handler(progress);
      const initial = attachments.length;
      const start = files.length;
      if (start > 0) {
        let resultArray = files.map((item, index) => ({
          [initial + index]: Math.floor(progress),
        }));
        let result = progressData;
        for (let i = 0; i < resultArray.length; i++) {
          result[initial + i] = resultArray[i][initial + i];
        }
        setProgressData(result);
      }
    },
    params,
  })
    .then(success)
    .catch((err) => {
      failure(err);
      if (err?.response?.data?.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    });
}
