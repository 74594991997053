import * as commentsActions from "./constants";

export const SetEntities = (data) => {
  return {
    type: commentsActions.SET_ENTITY,
    payload: data,
  };
};
export const SetComments = (data) => {
  return {
    type: commentsActions.SET_COMMENTS,
    payload: data,
  };
};
