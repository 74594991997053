import * as userActions from "../../actions/userActions/constants";
import { AxiosSetAuth } from "../../../helpers/axios";

const initialState = {
  loggedIn: false,
  accessToken: null,
  mfaEnabled: false,
  user: { workTimeCheck: true },
  identity_details: {},
  avatar: "",
  countries: [],
  users: [],
  fileRelation: {},
  statusList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case userActions.SET_STATUS_LIST:
      return {
        ...state,
        statusList: [...action.payload],
      };
    case userActions.SET_RELATED_OPTIONS:
      return {
        ...state,
        fileRelation: [...action.payload],
      };
    case userActions.SET_USERS:
      return {
        ...state,
        users: [...action.payload],
      };
    case userActions.SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload,
      };
    case userActions.SET_AVATAR:
      return {
        ...state,
        avatar: action.payload,
      };
    case userActions.LOGGED_IN:
      return {
        ...state,
        loggedIn: true,
      };
    case userActions.MAIL_CHANGE:
      return {
        ...state,
        user: {
          ...state.user,
          email: action.payload,
        },
      };
    case userActions.USER_SIGN_IN_ACTION_SUCCESS:
      return {
        ...state,
        loggedIn: action.payload.isAuthorized,
        mfaEnabled: action.payload.mfaEnabled,
        accessToken: action.payload.token,
        user: action.payload.user,
      };
    case userActions.SET_USER_INFO:
      return {
        ...state,
        identity_details: action.payload,
      };
    case userActions.USER_UPDATE_INFO:
      return {
        ...state,
        identity_details: action.payload,
      };
    case userActions.TWO_FACTOR:
      return {
        ...state,
        user: {
          ...state.user,
          two_factor: action.payload.imgBase64,
          secret: action.payload.secret,
        },
      };
    case userActions.SET_TWO_FACTOR:
      return {
        ...state,
        mfaEnabled: true,
      };
    case userActions.DELETE_TWO_FACTOR:
      return {
        ...state,
        mfaEnabled: false,
      };
    case userActions.USER_UPDATE_TOKEN:
      AxiosSetAuth(state.accessToken);
      return {
        ...state,
      };
    case userActions.USER_REFRESH_TOKEN:
      return {
        ...state,
        accessToken: action.payload.token,
        loggedIn: action.payload.isAuthorized,
      };
    case userActions.WORK_TIME_SWITCH:
      return {
        ...state,
        user: {
          ...state.user,
          workTimeCheck: action.payload,
        },
      };
    case userActions.CHANGE_EMAIL:
      return {
        ...state,
        user: {
          ...state.user,
          email: action.payload.email,
        },
      };
    case userActions.REGISTER_IN_OAUTH:
      return {
        ...state,
        loggedIn: true,
        accessToken: action.payload,
        mfaEnabled: false,
      };
    default:
      return state;
  }
};
