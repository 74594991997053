import * as contractActions from "./constants";

export const SetContractList = (data) => {
  return {
    type: contractActions.SET_CONTRACT_LIST,
    payload: data,
  };
};
export const AddContract = (data) => {
  return {
    type: contractActions.SET_CONTRACT,
    payload: data,
  };
};
