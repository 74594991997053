import * as filterActions from "../filterActions/constants";

export const SetClientItems = (data) => {
  return {
    type: filterActions.SET_CLIENT_ITEMS,
    payload: data,
  };
};
export const SetCustomItems = (data) => {
  return {
    type: filterActions.SET_CUSTOM_ITEMS,
    payload: data,
  };
};
export const SetFilters = (data) => {
  return {
    type: filterActions.SET_FILTERS,
    payload: data,
  };
};
export const SetCompanyItems = (data) => {
  return {
    type: filterActions.SET_COMPANY_ITEMS,
    payload: data,
  };
};
export const SetProductItems = (data) => {
  return {
    type: filterActions.SET_PRODUCT_ITEMS,
    payload: data,
  };
};
export const SetContractItems = (data) => {
  return {
    type: filterActions.SET_CONTRACT_ITEMS,
    payload: data,
  };
};
