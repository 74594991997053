import { NewRequest, NewRequestProgress } from "../../helpers/axios";
import { GET, POST, PUT } from "../../helpers/axiosConst";
import { API_URLS, BuildAPIUrl, URLS_LOCAL } from "../../utils/config/URLS";
import {
  ChangeMail,
  LoggedIn,
  DeleteTwoFactor,
  GetTwoFactor,
  setAvatar,
  setCountries,
  SetRelatedOptions,
  SetTwoFactor,
  SetUserInfo,
  setUsersAction,
  UpdateAuthToken,
  UserRefreshTokenActionSuccess,
  UserSignInActionFailure,
  UserSignInActionSuccess,
  UserStatusList,
  UserUpdateInfo,
  ChangeEmail,
} from "../../store/actions/userActions";
import axios from "axios";
import { handleRespErr } from "../../helpers/respErrorsHandler";
import { toast } from "react-toastify";
import { indexDay } from "../../helpers/calendarHelpers";
import { store } from "../../store";

export const UserSignUpAction = (user) => {
  return (dispatch) => {
    const userData = {
      confirmPassword: user.confirmPassword,
      email: user.email,
      name: user.name,
      password: user.password,
    };
    NewRequest(
      POST,
      BuildAPIUrl(API_URLS.SIGN_UP),
      JSON.stringify(userData),
      (res) => {
        dispatch(UserSignInActionSuccess(res.data));
        dispatch(UpdateAuthToken());
        toast.success(
          "You have successfully registered. For access to your account, please confirm your email"
        );
      },
      () => true
    );
  };
};

export const UserSignUpInvite = (user) => {
  return (dispatch) => {
    const userData = {
      code: user.code,
      confirmPassword: user.confirmPassword,
      email: user.email,
      password: user.password,
    };
    NewRequest(
      POST,
      BuildAPIUrl(API_URLS.INVITE_UP),
      JSON.stringify(userData),
      (res) => {
        dispatch(UserSignInActionSuccess(res.data));
        dispatch(UpdateAuthToken());
      },
      (err) => {
        console.log(err);
      }
    );
  };
};

export const ForgetPassword = (email) => {
  NewRequest(POST, BuildAPIUrl(API_URLS.FORGOT_PASSWORD), email, (res) => {
    toast.success(
      `An email is sent to ${email.email} with password reset link`,
      {
        theme: "colored",
      }
    );
  });
};

export const PasswordChange = (data, navigate) => {
  NewRequest(POST, BuildAPIUrl(API_URLS.RESET_PASSWORD), data, () => {
    toast.success("Password changed");
    navigate(URLS_LOCAL.AUTH.LOGIN);
  });
};

export const PasswordChangeCabinet = (data, setFormData, formData) => {
  NewRequest(POST, BuildAPIUrl(API_URLS.CHANGE_PASSWORD), data, () => {
    setFormData({
      ...formData,
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    toast.success(`Successfully change password`);
  });
};

export const UserUpdate = (user) => {
  return (dispatch) => {
    const data = {
      address: {
        city: user.city,
        country: user.country,
        line1: user.street,
        line2: "some house number and app",
        state: user.state,
        zipCode: user.zipCode,
      },
      dateOfBirth: user.dateOfBirth,
      fax: user.fax,
      firstName: user.firstName,
      lastName: user.lastName,
      mobileNumber: user.mobileNumber,
      phone: user.phone,
      website: user.website,
      weekStartDay: user.weekStartDay,
      workingSchedule: user.workingSchedule.map((item) => ({
        day: indexDay(item.day),
        start: item.start,
        end: item.end,
        active: item.active,
      })),
    };
    NewRequest(POST, BuildAPIUrl(API_URLS.GET_DATA_URL), data, (res) => {
      dispatch(UserUpdateInfo(res.data));
      toast.success(`Successfully update`);
      dispatch(GetAllUsers());
    });
  };
};

export const TwoFactor = () => {
  return (dispatch) => {
    NewRequest(POST, BuildAPIUrl(API_URLS.TWO_FACTOR_GENERATE), null, (res) => {
      dispatch(GetTwoFactor(res.data));
    });
  };
};

export const TwoFactorSuccess = (data) => {
  return (dispatch) => {
    NewRequest(
      POST,
      BuildAPIUrl(API_URLS.TWO_FACTOR_CHECK),
      data,
      (res) => {
        dispatch(UserSignInActionSuccess(res.data));
      },
      () => {
        toast.error("Сode is not correct");
      }
    );
  };
};

export const TwoFactorSet = (code, setModal, setErr, errors) => {
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(API_URLS.TWO_FACTOR_SET),
      code,
      (res) => {
        dispatch(SetTwoFactor(res.data));
        setModal(false);
      },
      () => {
        setErr({
          ...errors,
          token: "Token invalid",
        });
      }
    );
  };
};

export const TwoFactorDelete = (code, setModal, setErr, errors) => {
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(API_URLS.TWO_FACTOR_SET),
      code,
      (res) => {
        dispatch(DeleteTwoFactor(res.data));
        setModal(false);
      },
      () => {
        setErr({
          ...errors,
          token: "Token invalid",
        });
      }
    );
  };
};

export const UserSignInAction = (user, navigate) => {
  return (dispatch) => {
    const userData = {
      email: user.email,
      password: user.password,
    };
    NewRequest(
      POST,
      BuildAPIUrl(API_URLS.SIGN_IN),
      userData,
      (res) => {
        dispatch(UserSignInActionSuccess(res.data));
        dispatch(UpdateAuthToken());
        if (res.data.mfaEnabled) {
          navigate(URLS_LOCAL.AUTH.VERIFY);
        }
      },
      () => {
        handleRespErr(dispatch, toast.error, UserSignInActionFailure);
      }
    );
  };
};

export function UserSignOutAction() {
  NewRequest(POST, BuildAPIUrl(API_URLS.LOGOUT)).finally(() => {
    localStorage.clear();
    location.href = "/";
  });
}

export function UserRefreshTokenAction(token) {
  return (dispatch) => {
    const userData = {
      token: "Bearer " + token,
    };

    axios
      .post(BuildAPIUrl(API_URLS.REFRESH_TOKEN), userData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        dispatch(UserRefreshTokenActionSuccess(res.data));
      })
      .catch((res) => {
        if (res && res.response && res.response.status === 401) {
          if (store.getState().user.loggedIn) {
            UserSignOutAction();
          }
        }
      });
  };
}

export function UserPingAction() {
  return () => {
    if (!store.getState().user.loggedIn) {
      return;
    }
    NewRequest(
      GET,
      BuildAPIUrl(API_URLS.USER_PING),
      null,
      () => {},
      (res) => {
        if (res && res.response && res.response.status === 401) {
          if (store.getState().user.loggedIn) {
            UserSignOutAction();
          }
        }
      }
    );
  };
}

export const GetUserData = (setFormData, formData) => {
  return (dispatch) => {
    NewRequest(GET, BuildAPIUrl(API_URLS.GET_DATA_URL), null, (resp) => {
      setFormData({
        ...formData,
        dateOfBirth: resp.data?.dateOfBirth,
        fax: resp.data?.fax,
        firstName: resp.data.firstName,
        lastName: resp.data?.lastName,
        mobileNumber: resp.data?.mobileNumber,
        website: resp.data?.website,
        city: resp.data?.address?.city,
        country: resp.data?.address?.country,
        street: resp.data?.address?.street,
        zipCode: resp.data?.address?.zipCode,
        state: resp.data?.address?.state,
        phone: resp.data?.phone,
        mainRole: resp.data?.role,
      });
      dispatch(SetUserInfo(resp.data));
    });
  };
};

export const CheckMailCode = (code) => {
  return (dispatch) => {
    NewRequest(POST, BuildAPIUrl(API_URLS.REGISTER_MAIL_VERIFY), code, () => {
      dispatch(LoggedIn());
    });
  };
};

export const CompleteMailChanged = (code, navigate) => {
  return (dispatch) => {
    NewRequest(POST, BuildAPIUrl(API_URLS.MAIL_CHANGE_CONFIRM), code, (res) => {
      toast.success("Mail Changed");
      dispatch(ChangeMail(res.data.email));
      navigate(`/settings/user/profile`);
    });
  };
};

export const NewMailCode = (code, navigate) => {
  return (dispatch) => {
    NewRequest(POST, BuildAPIUrl(API_URLS.CHANGE_EMAIL_VERIFY), code, (res) => {
      toast.success("Check your new email");
      dispatch(ChangeEmail(res.data));
      navigate(`/settings/user/profile`);
    });
  };
};

export const SendNewEmail = (data, setFormData, formData, setSend) => {
  NewRequest(POST, BuildAPIUrl(API_URLS.CHANGE_EMAIL), data, () => {
    setFormData({
      ...formData,
      newEmail: "",
    });
    setSend(true);
    toast.success(`Checked your email`);
  });
};

export const GetMyUsers = (
  params,
  setState,
  state,
  setOnLoad = () => false
) => {
  NewRequest(
    GET,
    BuildAPIUrl(API_URLS.USERS),
    {},
    (resp) => {
      setOnLoad(true);
      const { data, pages, total } = resp.data;
      setState({
        ...state,
        isFetchData: false,
        data,
        pages,
        total,
        params: {
          ...state.params,
          page: Number(params.page),
          perPage: Number(params.perPage),
          filtered: params.filtered,
          sorted: params.sorted,
        },
      });
    },
    () => {
      setOnLoad(true);
    },
    {
      page: Number(params.page),
      perPage: Number(params.perPage),
      sorted: params.sorted,
      filtered: params.filtered,
    }
  );
};

export const GetAllUsers = () => {
  return (dispatch) => {
    NewRequest(GET, BuildAPIUrl(API_URLS.USERS), {}, (resp) => {
      dispatch(setUsersAction(resp.data.data));
    });
  };
};

export const ChangeUserStatus = (data, id, onPageClick, state) => {
  NewRequest(
    POST,
    BuildAPIUrl(`/api/settings/users/${id}/status`),
    data,
    () => {
      toast.success(`Status changed`);
      onPageClick({
        ...state.params,
        page: 1,
      });
    }
  );
};

export const ChangeUserRole = (data, id, onPageClick, state) => {
  NewRequest(POST, BuildAPIUrl(`/api/settings/users/${id}/role`), data, () => {
    toast.success(`Role Changed`);
    onPageClick({
      ...state.params,
      page: 1,
    });
  });
};

export const InviteUser = (data, onPageClick, state) => {
  return (dispatch) => {
    NewRequest(
      POST,
      BuildAPIUrl(API_URLS.USERS_INVITE),
      JSON.stringify(data),
      (res) => {
        const channel = new BroadcastChannel("user");
        channel.postMessage(res.data);
        toast.success(`Invited send`);
        dispatch(GetAllUsers());
        onPageClick({
          ...state.params,
          page: 1,
        });
      }
    );
  };
};

export function sendAvatar(
  files,
  setProgress,
  setAvatarModal,
  setNewAvatar,
  setEditorData = () => false
) {
  return (dispatch) => {
    let data = new FormData();
    data.append("file", files);
    data.append("subType", 1);
    NewRequestProgress(
      POST,
      BuildAPIUrl(API_URLS.AVATAR_ADD),
      data,
      (resp) => {
        dispatch(setAvatar(`${resp.data.file_path}?t=${Date.now()}`));
        setProgress(false);
        setNewAvatar(false);
        setEditorData({
          scale: 1,
        });
        setAvatarModal(false);
      },
      () => {
        setProgress(false);
      },
      {},
      setProgress
    );
  };
}

export function getAvatarRequest() {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(API_URLS.AVATAR_ADD),
      null,
      (resp) => {
        dispatch(setAvatar(resp.data.file_path));
      },
      () => {
        return "not notify";
      }
    );
  };
}

export function getCountries() {
  return (dispatch) => {
    NewRequest(GET, BuildAPIUrl(API_URLS.GET_COUNTRIES), null, (resp) => {
      dispatch(setCountries(resp.data));
    });
  };
}

export function GetRelationOptions() {
  return (dispatch) => {
    NewRequest(GET, BuildAPIUrl(API_URLS.RELATED_OPTIONS), null, (res) => {
      dispatch(SetRelatedOptions(res.data));
    });
  };
}

export function GetStatus() {
  return (dispatch) => {
    NewRequest(GET, BuildAPIUrl(API_URLS.GET_USER_STATUS), null, (resp) => {
      dispatch(UserStatusList(resp.data));
    });
  };
}

export function Oauth(provider) {
  return (dispatch) => {
    NewRequest(
      GET,
      `${BuildAPIUrl(API_URLS.OAUTH)}/${provider}`,
      null,
      (resp) => {
        console.log(resp.data);
      }
    );
  };
}
export function Bind(params) {
  return (dispatch) => {
    NewRequest(
      POST,
      `${BuildAPIUrl(API_URLS.BIND_AUTH)}`,
      null,
      (resp) => {
        console.log(resp.data);
      },
      (err) => {
        console.log(err);
      },
      params
    );
  };
}
export function Unbind(params) {
  return (dispatch) => {
    NewRequest(
      POST,
      `${BuildAPIUrl(API_URLS.UNBIND_AUTH)}`,
      null,
      (resp) => {
        console.log(resp.data);
      },
      (err) => {
        console.log(err);
      },
      params
    );
  };
}
