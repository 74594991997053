import React from "react";
import { EMAIL_TEMPLATES_COLLECTION, SEARCH_URLS } from "../constants";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const EmailTemplates = ({ elem }) => {
  return (
    <Link
      className={"search_link"}
      to={SEARCH_URLS[EMAIL_TEMPLATES_COLLECTION](elem.mongo_id)}
    >
      Template: {elem.name}
    </Link>
  );
};

EmailTemplates.propTypes = {
  elem: PropTypes.object,
};

export default EmailTemplates;
