import React from "react";
import { OPPORTUNITY_DEAL_COLLECTION, SEARCH_URLS } from "../constants";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const OpportunityDeal = ({ elem }) => {
  return (
    <Link
      className={"search_link"}
      to={SEARCH_URLS[OPPORTUNITY_DEAL_COLLECTION](
        elem.opportunityBoardID,
        elem.publicID
      )}
    >
      Deal: {elem.title}
    </Link>
  );
};

OpportunityDeal.propTypes = {
  elem: PropTypes.object,
};

export default OpportunityDeal;
