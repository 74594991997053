import { NewRequest } from "../../helpers/axios";
import { DELETE, GET, POST, PUT } from "../../helpers/axiosConst";
import { API_URLS, BuildAPIUrl, URLS_LOCAL } from "../../utils/config/URLS";
import {
  AddBoard,
  AddSelectedBoard,
  SetBoardOptions,
  SetWeightAction,
} from "../../store/actions/dashboardActions";
import { toast } from "react-toastify";
import { newColumns } from "../../helpers/kanbanHelpers";

export const GetBoard = () => {
  return (dispatch) => {
    NewRequest(GET, BuildAPIUrl(API_URLS.GET_DASHBOARD), null, (res) => {
      dispatch(AddBoard(res.data.length !== 0 ? res.data : []));
    });
  };
};

export const GetTasksAll = (id, setTasks) => {
  NewRequest(
    GET,
    BuildAPIUrl(`${API_URLS.GET_DASHBOARD}/${id}/tasks`),
    null,
    (res) => {
      setTasks(res.data.data);
    }
  );
};

export const GetBoardStatuses = () => {
  return (dispatch) => {
    NewRequest(GET, BuildAPIUrl(API_URLS.GET_BOARD_OPTIONS), null, (res) => {
      dispatch(SetBoardOptions(res.data));
    });
  };
};

export const GetSelectedBoard = (id) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(`${API_URLS.GET_DASHBOARD}/${id}`),
      null,
      (res) => {
        dispatch(AddSelectedBoard(res.data));
      }
    );
  };
};

export const SetDashboard = (data, navigate, params) => {
  const newDashboard = {
    title: data.name,
  };
  return (dispatch) => {
    NewRequest(
      POST,
      BuildAPIUrl(API_URLS.GET_DASHBOARD),
      newDashboard,
      (res) => {
        toast.success("Dashboard created");
        dispatch(GetBoard());
        if (params === "create-agile-board") {
          navigate(`/tasks/${res.data.ID}`);
        }
      }
    );
  };
};

export const EditDashboardAction = (data, id) => {
  const newDashboard = {
    title: data.name,
  };
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(`${API_URLS.GET_DASHBOARD}/${id}`),
      newDashboard,
      (res) => {
        toast.success("Dashboard edited");
        dispatch(GetBoard());
        dispatch(AddSelectedBoard(res.data));
      }
    );
  };
};

export const DeleteDashboardAction = (id, board, navigate) => {
  return (dispatch) => {
    NewRequest(
      DELETE,
      BuildAPIUrl(`${API_URLS.GET_DASHBOARD}/${id}`),
      null,
      () => {
        toast.success("Dashboard deleted");
        dispatch(
          SetWeightAction({
            weight: {},
          })
        );
        dispatch(GetBoard());
        let newSelected = board.filter((item) => item.ID !== id);
        dispatch(AddSelectedBoard(newSelected[0]));
        navigate(
          `/tasks/${
            newSelected.length > 0 ? newSelected[0].ID : "create-agile-board"
          }`
        );
      }
    );
  };
};

export const AddColumnAction = (id, data, columnsWeight, kanbanItems) => {
  const oldItems = kanbanItems.map((item) => item.id);
  return (dispatch) => {
    NewRequest(
      POST,
      BuildAPIUrl(`${API_URLS.GET_DASHBOARD}/${id}/columns`),
      data,
      (res) => {
        toast.success("Columns added");
        dispatch(
          SetColumnWeight(id, {
            weight: [
              ...columnsWeight,
              ...newColumns(Object.keys(res.data.columns), oldItems),
            ],
          })
        );
        dispatch(GetBoard());
        dispatch(AddSelectedBoard(res.data));
      }
    );
  };
};

export const SetColumnWeight = (id, data) => {
  return (dispatch) => {
    NewRequest(
      POST,
      BuildAPIUrl(`${API_URLS.GET_DASHBOARD}/${id}/columns/weight`),
      data,
      (res) => {
        dispatch(AddSelectedBoard(res.data));
      }
    );
  };
};

export const EditColumnAction = (id, data, columnsId) => {
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(`${API_URLS.GET_DASHBOARD}/${id}/columns/${columnsId}`),
      data,
      (res) => {
        toast.success("Columns changed");
        dispatch(GetBoard());
        dispatch(AddSelectedBoard(res.data));
      }
    );
  };
};

export const DeleteColumnAction = (
  id,
  columnsId,
  transferId,
  columnsWeight,
  noEmpty,
  weight
) => {
  const data = { transferColumnID: transferId };
  const transferData = weight.weight[data.transferColumnID] ?? [];
  return (dispatch) => {
    NewRequest(
      DELETE,
      BuildAPIUrl(`${API_URLS.GET_DASHBOARD}/${id}/columns/${columnsId}`),
      data,
      (res) => {
        toast.success("Columns deleted");
        dispatch(
          SetColumnWeight(id, {
            weight: [...columnsWeight].filter((item) => item !== columnsId),
          })
        );
        if (noEmpty) {
          dispatch(
            SetWeight(
              {
                weight: {
                  ...weight.weight,
                  [data.transferColumnID]: [
                    ...transferData,
                    ...weight.weight[columnsId],
                  ],
                },
              },
              id
            )
          );
        }
        dispatch(GetBoard());
        dispatch(AddSelectedBoard(res.data));
      }
    );
  };
};

export const GetWeight = (id) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(`${API_URLS.GET_DASHBOARD}/${id}/tasks/weight`),
      null,
      (res) => {
        if (res.data === null) {
          dispatch(
            SetWeightAction({
              weight: {},
            })
          );
        } else {
          dispatch(SetWeightAction(res.data));
        }
      },
      () => {
        dispatch(
          SetWeightAction({
            weight: {},
          })
        );
      }
    );
  };
};

export const SetWeight = (data, id) => {
  return (dispatch) => {
    NewRequest(
      POST,
      BuildAPIUrl(`${API_URLS.GET_DASHBOARD}/${id}/tasks/weight`),
      data,
      (res) => {
        dispatch(SetWeightAction(res.data));
      }
    );
  };
};

export const AddTaskAttachment = (data, id, taskId, setTasks, setFormData) => {
  return (dispatch) => {
    NewRequest(
      POST,
      BuildAPIUrl(
        `${API_URLS.GET_DASHBOARD}/${id}/tasks/${taskId}/attachments`
      ),
      data,
      (res) => {
        toast.success("Attachments added");
        GetTasksAll(id, setTasks);
        dispatch(GetTaskAction(id, taskId, setFormData));
      }
    );
  };
};
export const DeleteTaskAttachment = (
  id,
  taskId,
  attachment,
  setTasks,
  setFormData
) => {
  return (dispatch) => {
    NewRequest(
      DELETE,
      BuildAPIUrl(
        `${API_URLS.GET_DASHBOARD}/${id}/tasks/${taskId}/attachments/${attachment}`
      ),
      null,
      () => {
        toast.success("Attachments deleted");
        GetTasksAll(id, setTasks);
        dispatch(GetTaskAction(id, taskId, setFormData));
      }
    );
  };
};
export const SetTask = (data, id, weight, tasks, setTasks) => {
  return (dispatch) => {
    NewRequest(
      POST,
      BuildAPIUrl(`${API_URLS.GET_DASHBOARD}/${id}/tasks`),
      data,
      (res) => {
        toast.success("Task created");
        setTasks([...tasks, res.data]);
        const oldData = weight.weight[res.data.agileBoardColumnID] ?? [];
        dispatch(
          SetWeight(
            {
              weight: {
                ...weight.weight,
                [res.data.agileBoardColumnID]: [...oldData, res.data.ID],
              },
            },
            res.data.agileBoardID
          )
        );
      }
    );
  };
};

export const DeleteTask = (id, taskId, weight, columnId, tasks, setTasks) => {
  return (dispatch) => {
    NewRequest(
      DELETE,
      BuildAPIUrl(`${API_URLS.GET_DASHBOARD}/${id}/tasks/${taskId}`),
      null,
      () => {
        toast.success("Dashboard deleted");
        setTasks([...tasks].filter((item) => item.ID !== taskId));
        dispatch(
          SetWeight(
            {
              weight: {
                ...weight.weight,
                [columnId]: weight.weight[columnId].filter(
                  (item) => item !== taskId
                ),
              },
            },
            id
          )
        );
      }
    );
  };
};

export const EditTaskAction = (
  data,
  id,
  taskId,
  tasks,
  setTasks,
  setFormData,
  kanbanDispatch = () => false,
  kanbanState = false
) => {
  NewRequest(
    PUT,
    BuildAPIUrl(`${API_URLS.GET_DASHBOARD}/${id}/tasks/${taskId}`),
    data,
    (res) => {
      toast.success("Task updated");
      setFormData(res.data);
      kanbanDispatch({
        ...kanbanState,
        currentTask: res.data,
      });
      setTasks(
        [...tasks].map((item) => {
          if (item.ID === taskId) {
            return res.data;
          } else {
            return item;
          }
        })
      );
    }
  );
};

export const GetTaskAction = (
  id,
  taskId,
  setFormData,
  setLoaded = () => false
) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(`${API_URLS.GET_DASHBOARD}/${id}/tasks/${taskId}`),
      null,
      (res) => {
        setFormData(res.data);
        setLoaded(false);
      }
    );
  };
};

export const UpdateTaskAction = (
  data,
  id,
  taskId,
  source,
  destination,
  kanbanDispatch,
  kanbanState,
  setTasks,
  tasks,
  weight
) => {
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(`${API_URLS.GET_DASHBOARD}/${id}/tasks/${taskId}/move`),
      data,
      (res) => {
        dispatch(SetWeight(weight, res.data.agileBoardID));
      },
      () => {
        setTasks(
          tasks.map((item) => {
            if (item.ID === taskId) {
              return {
                ...item,
                agileBoardColumnID: source,
              };
            } else {
              return item;
            }
          })
        );
        kanbanDispatch({
          ...kanbanState,
          weight: {
            weight: {
              ...kanbanState.weight.weight,
              [destination]: kanbanState.weight.weight[destination]?.filter(
                (item) => item !== taskId
              ),
              [source]: [...kanbanState.weight.weight[source]],
            },
          },
        });
      }
    );
  };
};

export const SendDisplayOptions = (id, data) => {
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(`${API_URLS.GET_DASHBOARD}/${id}`),
      data,
      (res) => {
        dispatch(AddSelectedBoard(res.data));
      }
    );
  };
};
