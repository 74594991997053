import * as calendarActions from "../../actions/calendarActions/constants";

const initialState = {
  googleOauth: false,
  wizardStep: 1,
  calendar: {
    ExternalCalendarData: {},
  },
  calendarStatus: {},
  calendarList: [],
  events: [],
  videoList: [],
  wizardConfirm: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case calendarActions.INCREMENT:
      return {
        ...state,
        wizardStep: state.wizardStep + 1,
      };
    case calendarActions.DECREMENT:
      return {
        ...state,
        wizardStep: state.wizardStep - 1,
      };
    case calendarActions.STEP_START:
      return {
        ...state,
        wizardStep: 1,
      };
    case calendarActions.SET_CALENDAR_STATUS:
      return {
        ...state,
        calendarStatus: action.payload,
      };
    case calendarActions.WIZARD_CHANGE:
      return {
        ...state,
        wizardConfirm: action.payload,
      };
    case calendarActions.VIDEO_LIST:
      return {
        ...state,
        videoList: action.payload,
      };
    case calendarActions.SET_CALENDAR_LIST:
      return {
        ...state,
        calendarList: action.payload,
      };
    case calendarActions.SET_EVENTS:
      return {
        ...state,
        events: action.payload,
      };
    case calendarActions.SET_CALENDAR:
      return {
        ...state,
        calendar: action.payload,
      };
    case calendarActions.ADD_GOOGLE_OAUTH:
      return {
        ...state,
        googleOauth: action.payload,
      };
    default:
      return state;
  }
};
