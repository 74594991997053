import * as productActions from "../../actions/productActions/constants";

const initialState = {
  data: {},
  products: [],
  singleProduct: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case productActions.SET_PRODUCT:
      return {
        ...state,
        data: action.payload,
      };
    case productActions.SET_PRODUCT_LIST:
      return {
        ...state,
        products: action.payload,
      };
    case productActions.SET_PRODUCT_SINGLE:
      return {
        ...state,
        singleProduct: action.payload,
      };
    default:
      return state;
  }
};
