import React from "react";
import { ROLES_COLLECTION, SEARCH_URLS } from "../constants";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Roles = ({ elem }) => {
  return (
    <Link
      className={"search_link"}
      to={SEARCH_URLS[ROLES_COLLECTION](elem.mongo_id)}
    >
      Role: {elem.name}
    </Link>
  );
};

Roles.propTypes = {
  elem: PropTypes.object,
};

export default Roles;
