import React from "react";
import { DOMAINS_MAILING_COLLECTION, SEARCH_URLS } from "../constants";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Domains = ({ elem }) => {
  return (
    <Link
      className={"search_link"}
      to={SEARCH_URLS[DOMAINS_MAILING_COLLECTION]}
    >
      Domains: {elem.hostname}
    </Link>
  );
};

Domains.propTypes = {
  elem: PropTypes.object,
};

export default Domains;
