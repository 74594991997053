import React from "react";
import PropTypes from "prop-types";

const HeaderCart = ({ color }) => {
  const styles = {
    width: "12px",
    opacity: "0.4",
    marginRight: color ? "20px" : "0px",
    height: "24px",
    background: color,
  };
  return <span style={styles}></span>;
};

HeaderCart.propTypes = {
  color: PropTypes.string,
};

export default HeaderCart;
