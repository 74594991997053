import React from "react";
import { AGILE_BOARD_TASK_COLLECTION, SEARCH_URLS } from "../constants";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const AgileBoardTask = ({ elem }) => {
  return (
    <Link
      className={"search_link"}
      to={SEARCH_URLS[AGILE_BOARD_TASK_COLLECTION](
        elem.agileBoardID,
        elem.publicID
      )}
    >
      Task: {elem.title}
    </Link>
  );
};

AgileBoardTask.propTypes = {
  elem: PropTypes.object,
};

export default AgileBoardTask;
