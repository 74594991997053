import * as opportunitiesActions from "../../actions/opportunitiesActions/constants";
import {
  ADD_TAXES,
  DELETE_TAXES,
  EDIT_TAXES,
  SET_TAXES,
} from "../../actions/opportunitiesActions/constants";

const initialState = {
  board: [],
  selectedBoard: {
    empty: true,
    Stages: {},
  },
  taxes: [],
  weight: {
    weight: {},
  },
  opportunitiesOptions: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case opportunitiesActions.SET_OPPORTUNITIES:
      return {
        ...state,
        board: action.payload,
        selectedBoard: state.selectedBoard.empty
          ? action.payload[0] !== undefined
            ? action.payload[0]
            : {
                empty: true,
                Stages: {},
              }
          : action.payload.length > 0
          ? state.selectedBoard
          : {
              empty: true,
              Stages: {},
            },
      };
    case opportunitiesActions.SET_WEIGHT_OPPORTUNITIES:
      return {
        ...state,
        weight: {
          ...action.payload,
        },
      };
    case opportunitiesActions.SET_OPPORTUNITIES_OPTIONS:
      return {
        ...state,
        opportunitiesOptions: action.payload,
      };
    case opportunitiesActions.SET_TAXES:
      return {
        ...state,
        taxes: action.payload,
      };
    case opportunitiesActions.ADD_TAXES:
      return {
        ...state,
        taxes: [...state.taxes, action.payload],
      };
    case opportunitiesActions.DELETE_TAXES:
      return {
        ...state,
        taxes: state.taxes.filter((item) => item.ID !== action.payload),
      };
    case opportunitiesActions.EDIT_TAXES:
      return {
        ...state,
        taxes: [...state.taxes].map((item) => {
          if (item.ID === action.payload.ID) {
            return action.payload;
          } else {
            return item;
          }
        }),
      };
    case opportunitiesActions.SET_SELECTED_OPPORTUNITIES:
      return {
        ...state,
        selectedBoard:
          action.payload !== undefined
            ? action.payload
            : {
                empty: true,
                Stages: {},
              },
      };
    default:
      return state;
  }
};
