import { NewRequest } from "../../helpers/axios";
import { GET, PUT } from "../../helpers/axiosConst";
import { API_URLS, BuildAPIUrl } from "../../utils/config/URLS";
import { SetFilters } from "../../store/actions/filterActions";
import { SetClients } from "../../store/actions/clientsActions";
import { SetProductList } from "../../store/actions/productActions";
import { AddCompany } from "../../store/actions/companyActions";

export const GetFilters = (data) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(API_URLS.GET_CONSTRUCTOR_FILTERS),
      data,
      (res) => {
        dispatch(SetFilters(res.data));
      }
    );
  };
};

export const SetClientFilters = (
  data,
  params = false,
  setState = () => false,
  state = {},
  setOnLoad = () => false
) => {
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(`/api/constructor/client_card/apply-filters`),
      data,
      (resp) => {
        setOnLoad(true);
        const { data, pages, total } = resp.data;
        setState({
          ...state,
          isFetchData: false,
          data,
          pages,
          total,
          params: {
            ...state.params,
            page: Number(params.page),
            perPage: Number(params.perPage),
            filtered: params.filtered,
            sorted: params.sorted,
          },
        });
        dispatch(SetClients(resp.data.data));
      },
      () => {
        setOnLoad(true);
      },
      params && {
        page: Number(params.page),
        perPage: Number(params.perPage),
        sorted: params.sorted,
        filtered: params.filtered,
      }
    );
  };
};

export const SetCustomFilters = (
  data,
  params = false,
  setState = () => false,
  state = {},
  setOnLoad = () => false,
  type
) => {
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(`/api/constructor/custom/${type}/apply-filters`),
      data,
      (resp) => {
        setOnLoad(true);
        const { data, pages, total } = resp.data;
        setState({
          ...state,
          isFetchData: false,
          data,
          pages,
          total,
          params: {
            ...state.params,
            page: Number(params.page),
            perPage: Number(params.perPage),
            filtered: params.filtered,
            sorted: params.sorted,
          },
        });
      },
      () => {
        setOnLoad(true);
      },
      params && {
        page: Number(params.page),
        perPage: Number(params.perPage),
        sorted: params.sorted,
        filtered: params.filtered,
      }
    );
  };
};

export const SetProductFilters = (
  data,
  params = false,
  setState = () => false,
  state = {},
  setOnLoad = () => false
) => {
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(`/api/constructor/product/apply-filters`),
      data,
      (resp) => {
        setOnLoad(true);
        const { data, pages, total } = resp.data;
        setState({
          ...state,
          isFetchData: false,
          data,
          pages,
          total,
          params: {
            ...state.params,
            page: Number(params.page),
            perPage: Number(params.perPage),
            filtered: params.filtered,
            sorted: params.sorted,
          },
        });
        dispatch(SetProductList(resp.data.data));
      },
      () => {
        setOnLoad(true);
      },
      params && {
        page: Number(params.page),
        perPage: Number(params.perPage),
        sorted: params.sorted,
        filtered: params.filtered,
      }
    );
  };
};

export const SetCompanyFilters = (
  data,
  params = false,
  setState = () => false,
  state = {},
  setOnLoad = () => false
) => {
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(`/api/constructor/company_card/apply-filters`),
      data,
      (resp) => {
        setOnLoad(true);
        const { data, pages, total } = resp.data;
        setState({
          ...state,
          isFetchData: false,
          data,
          pages,
          total,
          params: {
            ...state.params,
            page: Number(params.page),
            perPage: Number(params.perPage),
            filtered: params.filtered,
            sorted: params.sorted,
          },
        });
        dispatch(AddCompany(resp.data.data));
      },
      () => {
        setOnLoad(true);
      },
      params && {
        page: Number(params.page),
        perPage: Number(params.perPage),
        sorted: params.sorted,
        filtered: params.filtered,
      }
    );
  };
};

export const SetContractFilters = (data) => {
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(`/api/constructor/contract/apply-filters`),
      data,
      (res) => {
        console.log(res.data);
      }
    );
  };
};
