export function getActiveCalendar(list, activeList) {
  const newData = [];
  for (let i = 0; i < list.length; i++) {
    for (let y = 0; y < activeList.length; y++) {
      if (list[i].id === activeList[y]) {
        newData.push(list[i]);
      }
    }
  }
  return newData;
}
export function getActiveClient(list, activeList) {
  const newData = [];
  for (let i = 0; i < list.length; i++) {
    for (let y = 0; y < activeList.length; y++) {
      if (list[i].value === activeList[y]) {
        newData.push(list[i]);
      }
    }
  }
  return newData;
}
export function getUnusedCalendar(list, activeList) {
  const newData = [...list];
  for (let i = 0; i < list.length; i++) {
    for (let y = 0; y < activeList.length; y++) {
      if (list[i].id === activeList[y].id) {
        list.splice(i, 1);
      }
    }
  }
  return newData;
}
export function calendarSortable(a, b) {
  if (a.id > b.id) {
    return 1;
  }
  if (a.id < b.id) {
    return -1;
  }
  return 0;
}
export function addAttendees(item, setFormData, formData) {
  const attendees = new Set();
  const clientsList = new Set();
  item.forEach((client) => {
    attendees.add(client.value);
    clientsList.add(client.label);
  });
  setFormData({
    ...formData,
    clientsList: [...clientsList],
    attendeesID: [...attendees],
  });
}
export function indexDay(day) {
  switch (day) {
    case "Sunday":
      return 0;
    case "Monday":
      return 1;
    case "Tuesday":
      return 2;
    case "Wednesday":
      return 3;
    case "Thursday":
      return 4;
    case "Friday":
      return 5;
    case "Saturday":
      return 6;
    default:
      return day;
  }
}
export function today(date, start) {
  return new Date(
    `${start.getFullYear?.()}-${
      start.getMonth?.() + 1 < 10
        ? "0" + (start.getMonth?.() + 1)
        : start.getMonth?.() + 1
    }-${start.getDate?.() < 10 ? "0" + start.getDate?.() : start.getDate?.()}T${
      date.getHours?.() < 10 ? "0" + date.getHours?.() : date.getHours?.()
    }:${
      date.getMinutes?.() < 10 || date.getMinutes?.() == 0
        ? "0" + date.getMinutes?.()
        : date.getMinutes?.()
    }:00`
  );
}
export function setDataPickerDate(
  value,
  scheduleStartDate,
  setScheduleStartDate,
  formData,
  setFormData,
  scheduleEndDate,
  setScheduleEndDate,
  editScheduleStartDate,
  setEditScheduleStartDate,
  editScheduleEndDate,
  setEditScheduleEndDate,
  edit,
  setDuration,
  setDurationEdit
) {
  if (!edit) {
    const dataStart = new Date(
      `${scheduleStartDate.getFullYear()}-${
        scheduleStartDate.getMonth() + 1 < 10
          ? "0" + (scheduleStartDate.getMonth() + 1)
          : scheduleStartDate.getMonth() + 1
      }-${
        scheduleStartDate.getDate() < 10
          ? "0" + scheduleStartDate.getDate()
          : scheduleStartDate.getDate()
      }T${
        value.start.hours >= 0
          ? value.start.hours < 10
            ? "0" + value.start.hours
            : value.start.hours
          : "00"
      }:${
        value.start.minutes < 10 && value.start.minutes != 0
          ? "0" + value.start.minutes
          : value.start.minutes
      }:00`
    );
    setScheduleStartDate(dataStart);
    const dataEnd = new Date(
      Date.parse(
        `${scheduleEndDate.getFullYear()}-${
          scheduleEndDate.getMonth() + 1 < 10
            ? "0" + (scheduleEndDate.getMonth() + 1)
            : scheduleEndDate.getMonth() + 1
        }-${
          scheduleEndDate.getDate() < 10
            ? "0" + scheduleEndDate.getDate()
            : scheduleEndDate.getDate()
        }T${value.end.hours < 10 ? "0" + value.end.hours : value.end.hours}:${
          value.end.minutes < 10 && value.end.minutes != 0
            ? "0" + value.end.minutes
            : value.end.minutes
        }:00`
      )
    );
    let intermediate = (Date.parse(dataEnd) - Date.parse(dataStart)) / 900000;
    setDuration(intermediate * 15);
    setScheduleEndDate(dataEnd);
    setFormData({
      ...formData,
      startDateTime: dataStart,
      endDateTime: dataEnd,
      timeRange: selectTimeline(dataStart, dataEnd),
    });
  } else {
    const dataStart = new Date(
      `${editScheduleStartDate.getFullYear()}-${
        editScheduleStartDate.getMonth() + 1 < 10
          ? "0" + (editScheduleStartDate.getMonth() + 1)
          : editScheduleStartDate.getMonth() + 1
      }-${
        editScheduleStartDate.getDate() < 10
          ? "0" + editScheduleStartDate.getDate()
          : editScheduleStartDate.getDate()
      }T${
        value.start.hours < 10 ? "0" + value.start.hours : value.start.hours
      }:${
        value.start.minutes < 10 && value.start.minutes != 0
          ? "0" + value.start.minutes
          : value.start.minutes
      }:00`
    );
    setEditScheduleStartDate(dataStart);
    const dataEnd = new Date(
      `${editScheduleEndDate.getFullYear()}-${
        editScheduleEndDate.getMonth() + 1 < 10
          ? "0" + (editScheduleEndDate.getMonth() + 1)
          : editScheduleEndDate.getMonth() + 1
      }-${
        editScheduleEndDate.getDate() < 10
          ? "0" + editScheduleEndDate.getDate()
          : editScheduleEndDate.getDate()
      }T${value.end.hours < 10 ? "0" + value.end.hours : value.end.hours}:${
        value.end.minutes < 10 && value.end.minutes != 0
          ? "0" + value.end.minutes
          : value.end.minutes
      }:00`
    );
    let intermediate = (Date.parse(dataEnd) - Date.parse(dataStart)) / 900000;
    setDurationEdit(intermediate * 15);
    setEditScheduleEndDate(dataEnd);
    setFormData({
      ...formData,
      startDateTime: dataStart,
      endDateTime: dataEnd,
      timeRange: selectTimeline(dataStart, dataEnd),
    });
  }
}
export function dateStartPickerSingle(
  date,
  edit,
  setScheduleStartDate,
  setEditScheduleStartDate,
  formData,
  setFormData,
  setScheduleEndDate,
  setEditScheduleEndDate
) {
  if (edit) {
    setEditScheduleStartDate(date);
    setEditScheduleEndDate(date);
  } else {
    setScheduleStartDate(date);
    setScheduleEndDate(date);
  }
  setFormData({
    ...formData,
    startDateTime: date,
    endDateTime: date,
  });
}
export function dateEndPicker(
  date,
  edit,
  setScheduleEndDate,
  setEditScheduleEndDate,
  formData,
  setFormData,
  scheduleStartDate,
  editScheduleStartDate,
  setDuration,
  setDurationEdit
) {
  if (edit) {
    let intermediate =
      (Date.parse(date) - Date.parse(editScheduleStartDate)) / 900000;
    setDurationEdit(intermediate >= 0 ? intermediate * 15 : 0);
    setEditScheduleEndDate(date);
    setFormData({
      ...formData,
      endDateTime: date,
      value: {
        ...formData.value,
        end: !maxDayValue(editScheduleStartDate, date)
          ? maxEndRange(
              timeStringify(date),
              selectTimeline(
                editScheduleStartDate,
                new Date(
                  Date.parse(date) -
                    ((Date.parse(date) - Date.parse(editScheduleStartDate)) /
                      900000) *
                      900000
                )
              ).max
            )
          : selectTimeline(editScheduleStartDate, editScheduleStartDate).max,
      },
    });
  } else {
    let intermediate =
      (Date.parse(date) - Date.parse(scheduleStartDate)) / 900000;
    setDuration(intermediate >= 0 ? intermediate * 15 : 0);
    setScheduleEndDate(date);
    setFormData({
      ...formData,
      endDateTime: date,
      value: {
        ...formData.value,
        end: !maxDayValue(scheduleStartDate, date)
          ? maxEndRange(
              timeStringify(date),
              selectTimeline(
                scheduleStartDate,
                new Date(
                  Date.parse(date) -
                    ((Date.parse(date) - Date.parse(scheduleStartDate)) /
                      900000) *
                      900000
                )
              ).max
            )
          : selectTimeline(scheduleStartDate, scheduleStartDate).max,
      },
    });
  }
}
export function dateStartPicker(
  date,
  edit,
  setScheduleStartDate,
  setEditScheduleStartDate,
  formData,
  setFormData,
  setEditScheduleEndDate,
  setScheduleEndDate,
  duration,
  durationEdit
) {
  if (edit) {
    setEditScheduleStartDate(date);
    setEditScheduleEndDate(new Date(Date.parse(date)));
    setFormData({
      ...formData,
      startDateTime: date,
      endDateTime: new Date(Date.parse(date) + timeDuration(durationEdit)),
      value: {
        ...formData.value,
        start: timeStringify(date),
        end: timeStringify(
          new Date(Date.parse(date) + timeDuration(durationEdit))
        ),
      },
    });
  } else {
    setScheduleStartDate(date);
    setScheduleEndDate(new Date(Date.parse(date)));
    setFormData({
      ...formData,
      startDateTime: date,
      endDateTime: new Date(Date.parse(date) + timeDuration(duration)),
    });
  }
}
export function maxDayValue(dateStart, dateEnd) {
  let day = Date.parse(
    `${dateStart.getFullYear()}-${
      dateStart.getMonth() + 1 < 10
        ? "0" + (dateStart.getMonth() + 1)
        : dateStart.getMonth() + 1
    }-${
      dateStart.getDate() < 10 ? "0" + dateStart.getDate() : dateStart.getDate()
    }T23:59:59`
  );
  if (day < Date.parse(dateEnd)) {
    return true;
  } else {
    return false;
  }
}
export function minDayValue(dateStart, dateEnd, duration) {
  let dayMin = Date.parse(
    `${dateStart.getFullYear()}-${
      dateStart.getMonth() + 1 < 10
        ? "0" + (dateStart.getMonth() + 1)
        : dateStart.getMonth() + 1
    }-${
      dateStart.getDate() < 10 ? "0" + dateStart.getDate() : dateStart.getDate()
    }T${
      selectTimeline(
        new Date(dateStart.getTime() - timeDuration(duration)),
        new Date(dateStart.getTime() - timeDuration(duration))
      ).min
    }:00`
  );
  if (Date.parse(dateEnd) - timeDuration(duration) < dayMin) {
    return true;
  } else {
    return false;
  }
}
export function maxChangeDuration(dateStart, dateEnd) {
  let day = Date.parse(
    `${dateStart.getFullYear()}-${
      dateStart.getMonth() + 1 < 10
        ? "0" + (dateStart.getMonth() + 1)
        : dateStart.getMonth() + 1
    }-${
      dateStart.getDate() < 10 ? "0" + dateStart.getDate() : dateStart.getDate()
    }T${selectTimeline(dateStart, dateStart).max}:00`
  );
  let today = Date.parse(
    `${dateStart.getFullYear()}-${
      dateStart.getMonth() + 1 < 10
        ? "0" + (dateStart.getMonth() + 1)
        : dateStart.getMonth() + 1
    }-${
      dateStart.getDate() < 10 ? "0" + dateStart.getDate() : dateStart.getDate()
    }T23:59:59`
  );
  if (Date.parse(dateEnd) >= day && Date.parse(dateEnd) > today) {
    return true;
  } else {
    return false;
  }
}
export function timePickerStart(
  date,
  edit,
  setScheduleStartDate,
  setEditScheduleStartDate,
  formData,
  setFormData,
  duration,
  durationEdit,
  setEditScheduleEndDate,
  setScheduleEndDate,
  scheduleStartDate,
  editScheduleStartDate
) {
  if (edit) {
    let dateDay = new Date(
      Date.parse(
        `${editScheduleStartDate.getFullYear()}-${
          editScheduleStartDate.getMonth() + 1 < 10
            ? "0" + (editScheduleStartDate.getMonth() + 1)
            : editScheduleStartDate.getMonth() + 1
        }-${
          editScheduleStartDate.getDate() < 10
            ? "0" + editScheduleStartDate.getDate()
            : editScheduleStartDate.getDate()
        }T${
          date.getHours()
            ? date.getHours() < 10
              ? "0" + date.getHours()
              : date.getHours()
            : "00"
        }:${
          date.getMinutes()
            ? date.getMinutes() < 10 && date.getMinutes() != 0
              ? "0" + date.getMinutes()
              : date.getMinutes()
            : "00"
        }:00`
      )
    );
    setEditScheduleStartDate(dateDay);
    setEditScheduleEndDate(
      new Date(Date.parse(date) + timeDuration(durationEdit))
    );
    setFormData({
      ...formData,
      startDateTime: date,
      endDateTime: new Date(Date.parse(date) + timeDuration(durationEdit)),
      value: {
        ...formData.value,
        start: timeStringify(date),
        end: !maxDayValue(
          date,
          new Date(Date.parse(date) + timeDuration(durationEdit))
        )
          ? timeStringify(
              new Date(Date.parse(date) + timeDuration(durationEdit))
            )
          : "24:00",
      },
      timeRange: selectTimeline(
        date,
        new Date(Date.parse(date) + timeDuration(durationEdit))
      ),
    });
  } else {
    let dateDay = new Date(
      Date.parse(
        `${scheduleStartDate.getFullYear()}-${
          scheduleStartDate.getMonth() + 1 < 10
            ? "0" + (scheduleStartDate.getMonth() + 1)
            : scheduleStartDate.getMonth() + 1
        }-${
          scheduleStartDate.getDate() < 10
            ? "0" + scheduleStartDate.getDate()
            : scheduleStartDate.getDate()
        }T${
          date.getHours()
            ? date.getHours() < 10
              ? "0" + date.getHours()
              : date.getHours()
            : "00"
        }:${
          date.getMinutes()
            ? date.getMinutes() < 10 && date.getMinutes() != 0
              ? "0" + date.getMinutes()
              : date.getMinutes()
            : "00"
        }:00`
      )
    );
    setScheduleStartDate(dateDay);
    setScheduleEndDate(new Date(Date.parse(date) + timeDuration(duration)));
    setFormData({
      ...formData,
      startDateTime: date,
      endDateTime: new Date(Date.parse(date) + timeDuration(duration)),
    });
  }
}
export function timePickerEnd(
  date,
  edit,
  setScheduleEndDate,
  setEditScheduleEndDate,
  formData,
  setFormData,
  setDuration,
  scheduleStartDate,
  editScheduleStartDate,
  setDurationEdit
) {
  if (edit) {
    let intermediate =
      (Date.parse(
        `${editScheduleStartDate.getFullYear()}-${
          editScheduleStartDate.getMonth() + 1 < 10
            ? "0" + (editScheduleStartDate.getMonth() + 1)
            : editScheduleStartDate.getMonth() + 1
        }-${
          editScheduleStartDate.getDate() < 10
            ? "0" + editScheduleStartDate.getDate()
            : editScheduleStartDate.getDate()
        }T${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${
          date.getMinutes() < 10 || date.getMinutes() == 0
            ? "0" + date.getMinutes()
            : date.getMinutes()
        }:00`
      ) -
        Date.parse(editScheduleStartDate)) /
      900000;
    setDurationEdit((intermediate && intermediate > 0 ? intermediate : 0) * 15);
    setEditScheduleEndDate(
      Date.parse(today(date, editScheduleStartDate)) <
        Date.parse(editScheduleStartDate)
        ? new Date(Date.parse(editScheduleStartDate))
        : date
    );
  } else {
    let intermediate =
      (Date.parse(
        `${scheduleStartDate.getFullYear()}-${
          scheduleStartDate.getMonth() + 1 < 10
            ? "0" + (scheduleStartDate.getMonth() + 1)
            : scheduleStartDate.getMonth() + 1
        }-${
          scheduleStartDate.getDate() < 10
            ? "0" + scheduleStartDate.getDate()
            : scheduleStartDate.getDate()
        }T${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${
          date.getMinutes() < 10 || date.getMinutes() == 0
            ? "0" + date.getMinutes()
            : date.getMinutes()
        }:00`
      ) -
        Date.parse(scheduleStartDate)) /
      900000;
    setDuration((intermediate && intermediate > 0 ? intermediate : 0) * 15);
    setScheduleEndDate(
      Date.parse(today(date, scheduleStartDate)) < Date.parse(scheduleStartDate)
        ? new Date(Date.parse(scheduleStartDate))
        : date
    );
  }
  setFormData({
    ...formData,
    endDateTime: today(date, edit ? editScheduleStartDate : scheduleStartDate),
    value: {
      ...formData.value,
      end:
        Date.parse(
          today(date, edit ? editScheduleStartDate : scheduleStartDate)
        ) < Date.parse(!edit ? scheduleStartDate : editScheduleStartDate)
          ? timeStringify(!edit ? scheduleStartDate : editScheduleStartDate)
          : maxEndRange(
              timeStringify(date),
              selectTimeline(
                !edit ? scheduleStartDate : editScheduleStartDate,
                new Date(
                  Date.parse(date) -
                    ((Date.parse(date) -
                      Date.parse(
                        !edit ? scheduleStartDate : editScheduleStartDate
                      )) /
                      900000) *
                      900000
                )
              ).max
            ),
    },
  });
}
export function timePickerEndExtended(
  date,
  edit,
  setScheduleEndDate,
  setEditScheduleEndDate,
  formData,
  setFormData,
  setDuration,
  scheduleStartDate,
  editScheduleStartDate,
  setDurationEdit,
  scheduleEndDate,
  editScheduleEndDate
) {
  if (edit) {
    let intermediate =
      (Date.parse(
        `${editScheduleEndDate.getFullYear()}-${
          editScheduleEndDate.getMonth() + 1 < 10
            ? "0" + (editScheduleEndDate.getMonth() + 1)
            : editScheduleEndDate.getMonth() + 1
        }-${
          editScheduleEndDate.getDate() < 10
            ? "0" + editScheduleEndDate.getDate()
            : editScheduleEndDate.getDate()
        }T${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${
          date.getMinutes() < 10 || date.getMinutes() == 0
            ? "0" + date.getMinutes()
            : date.getMinutes()
        }:00`
      ) -
        Date.parse(editScheduleStartDate)) /
      900000;
    setDurationEdit((intermediate && intermediate > 0 ? intermediate : 0) * 15);
    setEditScheduleEndDate(date);
    setFormData({
      ...formData,
      endDateTime: today(date, editScheduleEndDate),
      value: {
        ...formData.value,
        end:
          Date.parse(today(date, editScheduleEndDate)) <
          Date.parse(
            `${editScheduleStartDate.getFullYear()}-${
              editScheduleStartDate.getMonth() + 1 < 10
                ? "0" + (editScheduleStartDate.getMonth() + 1)
                : editScheduleStartDate.getMonth() + 1
            }-${
              editScheduleStartDate.getDate() < 10
                ? "0" + editScheduleStartDate.getDate()
                : editScheduleStartDate.getDate()
            }T23:59:59`
          )
            ? Date.parse(today(date, editScheduleEndDate)) <
              Date.parse(editScheduleEndDate)
              ? timeStringify(today(date, editScheduleEndDate))
              : maxEndRange(
                  timeStringify(date),
                  selectTimeline(
                    editScheduleEndDate,
                    new Date(
                      Date.parse(date) -
                        ((Date.parse(date) - Date.parse(editScheduleEndDate)) /
                          900000) *
                          900000
                    )
                  ).max
                )
            : selectTimeline(editScheduleStartDate, editScheduleStartDate).max,
      },
    });
  } else {
    let intermediate =
      (Date.parse(
        `${scheduleEndDate.getFullYear()}-${
          scheduleEndDate.getMonth() + 1 < 10
            ? "0" + (scheduleEndDate.getMonth() + 1)
            : scheduleEndDate.getMonth() + 1
        }-${
          scheduleEndDate.getDate() < 10
            ? "0" + scheduleEndDate.getDate()
            : scheduleEndDate.getDate()
        }T${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${
          date.getMinutes() < 10 || date.getMinutes() == 0
            ? "0" + date.getMinutes()
            : date.getMinutes()
        }:00`
      ) -
        Date.parse(scheduleStartDate)) /
      900000;
    setDuration((intermediate && intermediate > 0 ? intermediate : 0) * 15);
    setScheduleEndDate(date);
    setFormData({
      ...formData,
      endDateTime: today(date, scheduleEndDate),
      value: {
        ...formData.value,
        end:
          Date.parse(today(date, scheduleEndDate)) < Date.parse(scheduleEndDate)
            ? timeStringify(scheduleEndDate)
            : maxEndRange(
                timeStringify(date),
                selectTimeline(
                  scheduleEndDate,
                  new Date(
                    Date.parse(date) -
                      ((Date.parse(date) - Date.parse(scheduleEndDate)) /
                        900000) *
                        900000
                  )
                ).max
              ),
      },
    });
  }
}
export function functionLessDay(
  edit,
  setScheduleStartDate,
  scheduleStartDate,
  setFormData,
  formData,
  setEditScheduleStartDate,
  editScheduleStartDate,
  setScheduleEndDate,
  scheduleEndDate,
  setEditScheduleEndDate,
  duration,
  durationEdit
) {
  if (!edit) {
    setScheduleStartDate(
      new Date(Date.parse(scheduleStartDate.toString()) - 86400000)
    );
    setScheduleEndDate(
      new Date(
        Date.parse(scheduleStartDate.toString()) -
          86400000 +
          timeDuration(duration)
      )
    );
    setFormData({
      ...formData,
      startDateTime: new Date(
        Date.parse(scheduleStartDate.toString()) - 86400000
      ),
      endDateTime: new Date(
        Date.parse(scheduleEndDate.toString()) -
          86400000 +
          timeDuration(duration)
      ),
    });
  } else {
    setEditScheduleStartDate(
      new Date(Date.parse(editScheduleStartDate.toString()) - 86400000)
    );
    setEditScheduleEndDate(
      new Date(
        Date.parse(editScheduleStartDate.toString()) -
          86400000 +
          timeDuration(durationEdit)
      )
    );
    setFormData({
      ...formData,
      startDateTime: new Date(
        Date.parse(editScheduleStartDate.toString()) - 86400000
      ),
      endDateTime: new Date(
        Date.parse(editScheduleStartDate.toString()) -
          86400000 +
          timeDuration(durationEdit)
      ),
    });
  }
}
export function functionAddDay(
  edit,
  setScheduleStartDate,
  scheduleStartDate,
  setFormData,
  formData,
  setEditScheduleStartDate,
  editScheduleStartDate,
  setScheduleEndDate,
  setEditScheduleEndDate,
  duration,
  durationEdit
) {
  if (!edit) {
    setScheduleStartDate(
      new Date(Date.parse(scheduleStartDate.toString()) + 86400000)
    );
    setScheduleEndDate(
      new Date(
        Date.parse(scheduleStartDate.toString()) +
          86400000 +
          timeDuration(duration)
      )
    );
    setFormData({
      ...formData,
      startDateTime: new Date(
        Date.parse(scheduleStartDate.toString()) + 86400000
      ),
      endDateTime: new Date(
        Date.parse(scheduleStartDate.toString()) +
          86400000 +
          timeDuration(duration)
      ),
    });
  } else {
    setEditScheduleStartDate(
      new Date(Date.parse(editScheduleStartDate.toString()) + 86400000)
    );
    setEditScheduleEndDate(
      new Date(
        Date.parse(editScheduleStartDate.toString()) +
          86400000 +
          timeDuration(durationEdit)
      )
    );
    setFormData({
      ...formData,
      startDateTime: new Date(
        Date.parse(editScheduleStartDate.toString()) + 86400000
      ),
      endDateTime: new Date(
        Date.parse(editScheduleStartDate.toString()) +
          86400000 +
          timeDuration(durationEdit)
      ),
    });
  }
}
export function timeDuration(duration) {
  return (duration / 15) * 900000;
}
export function addTime(
  edit,
  duration,
  setScheduleStartDate,
  setScheduleEndDate,
  setEditScheduleStartDate,
  setEditScheduleEndDate,
  scheduleStartDate,
  scheduleEndDate,
  editScheduleStartDate,
  editScheduleEndDate,
  setFormData,
  formData,
  durationEdit
) {
  if (!edit) {
    setScheduleStartDate(
      new Date(
        Date.parse(scheduleStartDate.toString()) + timeDuration(duration)
      )
    );
    setScheduleEndDate(
      new Date(Date.parse(scheduleEndDate.toString()) + timeDuration(duration))
    );
    setFormData({
      ...formData,
      startDateTime: new Date(
        Date.parse(scheduleStartDate.toString()) + timeDuration(duration)
      ),
      endDateTime: new Date(
        Date.parse(scheduleEndDate.toString()) + timeDuration(duration)
      ),
    });
  } else {
    setEditScheduleStartDate(
      new Date(
        Date.parse(editScheduleStartDate.toString()) +
          timeDuration(durationEdit)
      )
    );
    setEditScheduleEndDate(
      new Date(
        Date.parse(editScheduleEndDate.toString()) + timeDuration(durationEdit)
      )
    );
    setFormData({
      ...formData,
      startDateTime: new Date(
        Date.parse(editScheduleStartDate.toString()) +
          timeDuration(durationEdit)
      ),
      endDateTime: new Date(
        Date.parse(editScheduleEndDate.toString()) + timeDuration(durationEdit)
      ),
      value: {
        start: timeStringify(
          new Date(
            Date.parse(editScheduleStartDate.toString()) +
              timeDuration(durationEdit)
          )
        ),
        end: !maxDayValue(
          new Date(
            Date.parse(editScheduleStartDate.toString()) +
              timeDuration(durationEdit)
          ),
          new Date(
            Date.parse(editScheduleEndDate.toString()) +
              timeDuration(durationEdit)
          )
        )
          ? timeStringify(
              new Date(
                Date.parse(editScheduleEndDate.toString()) +
                  timeDuration(durationEdit)
              )
            )
          : "24:00",
      },
      timeRange: selectTimeline(
        new Date(
          Date.parse(editScheduleStartDate.toString()) +
            timeDuration(durationEdit)
        ),
        new Date(
          Date.parse(editScheduleEndDate.toString()) +
            timeDuration(durationEdit)
        )
      ),
    });
  }
}
export function lessTime(
  edit,
  duration,
  setScheduleStartDate,
  setScheduleEndDate,
  setEditScheduleStartDate,
  setEditScheduleEndDate,
  scheduleStartDate,
  scheduleEndDate,
  editScheduleStartDate,
  editScheduleEndDate,
  setFormData,
  formData,
  durationEdit
) {
  if (!edit) {
    setScheduleStartDate(
      new Date(
        Date.parse(scheduleStartDate.toString()) - timeDuration(duration)
      )
    );
    setScheduleEndDate(
      new Date(Date.parse(scheduleEndDate.toString()) - timeDuration(duration))
    );
    setFormData({
      ...formData,
      startDateTime: new Date(
        Date.parse(scheduleStartDate.toString()) - timeDuration(duration)
      ),
      endDateTime: new Date(
        Date.parse(scheduleEndDate.toString()) - timeDuration(duration)
      ),
    });
  } else {
    setEditScheduleStartDate(
      new Date(
        Date.parse(editScheduleStartDate.toString()) -
          timeDuration(durationEdit)
      )
    );
    setEditScheduleEndDate(
      new Date(
        Date.parse(editScheduleEndDate.toString()) - timeDuration(durationEdit)
      )
    );
    setFormData({
      ...formData,
      startDateTime: new Date(
        Date.parse(editScheduleStartDate.toString()) -
          timeDuration(durationEdit)
      ),
      endDateTime: new Date(
        Date.parse(editScheduleEndDate.toString()) - timeDuration(durationEdit)
      ),
      value: {
        start: timeStringify(
          new Date(
            Date.parse(editScheduleStartDate.toString()) -
              timeDuration(durationEdit)
          )
        ),
        end: !minDayValue(
          editScheduleStartDate,
          editScheduleEndDate,
          durationEdit
        )
          ? timeStringify(
              new Date(Date.parse(editScheduleStartDate.toString()))
            )
          : selectTimeline(
              new Date(
                Date.parse(editScheduleStartDate.toString()) -
                  timeDuration(durationEdit)
              ),
              new Date(
                Date.parse(editScheduleEndDate.toString()) -
                  timeDuration(durationEdit)
              )
            ).max,
      },
      timeRange: selectTimeline(
        new Date(
          Date.parse(editScheduleStartDate.toString()) -
            timeDuration(durationEdit)
        ),
        new Date(
          Date.parse(editScheduleEndDate.toString()) -
            timeDuration(durationEdit)
        )
      ),
    });
  }
}
export function timeStringify(date) {
  return `${
    date.getHours?.() < 10 ? "0" + date.getHours?.() : date.getHours?.()
  }:${
    date.getMinutes?.() < 10 ? "0" + date.getMinutes?.() : date.getMinutes?.()
  }`;
}
export function functionIncrement(
  edit,
  setFormData,
  formData,
  scheduleEndDate,
  editScheduleStartDate,
  setDuration,
  duration,
  durationEdit,
  setDurationEdit,
  scheduleStartDate,
  setScheduleEndDate,
  setEditScheduleEndDate
) {
  if (!edit) {
    setDuration(duration + 15);
    setScheduleEndDate(
      new Date(
        Date.parse(scheduleStartDate.toString()) + timeDuration(duration + 15)
      )
    );
    setFormData({
      ...formData,
      endDateTime: new Date(
        Date.parse(scheduleStartDate.toString()) + timeDuration(duration + 15)
      ),
    });
  } else {
    setDurationEdit(durationEdit + 15);
    setEditScheduleEndDate(
      new Date(
        Date.parse(editScheduleStartDate.toString()) +
          timeDuration(durationEdit + 15)
      )
    );
    setFormData({
      ...formData,
      endDateTime: new Date(
        Date.parse(editScheduleStartDate.toString()) +
          timeDuration(durationEdit + 15)
      ),
      value: {
        ...formData.value,
        end: !maxDayValue(
          editScheduleStartDate,
          new Date(
            Date.parse(editScheduleStartDate.toString()) +
              timeDuration(durationEdit + 15)
          )
        )
          ? timeStringify(
              new Date(
                Date.parse(editScheduleStartDate.toString()) +
                  timeDuration(durationEdit + 15)
              )
            )
          : "24:00",
      },
      timeRange: selectTimeline(
        editScheduleStartDate,
        new Date(
          Date.parse(editScheduleStartDate.toString()) +
            timeDuration(durationEdit + 15)
        )
      ),
    });
  }
}
export function functionDecrement(
  edit,
  setFormData,
  formData,
  scheduleEndDate,
  editScheduleStartDate,
  setDuration,
  duration,
  durationEdit,
  setDurationEdit,
  setScheduleEndDate,
  setEditScheduleEndDate
) {
  if (!edit) {
    setDuration(duration - 15);
    setScheduleEndDate(
      new Date(
        Date.parse(scheduleEndDate.toString()) + timeDuration(duration - 15)
      )
    );
    setFormData({
      ...formData,
      endDateTime: new Date(
        Date.parse(scheduleEndDate.toString()) + timeDuration(duration - 15)
      ),
      value: {
        ...formData.value,
        end: timeStringify(
          new Date(
            Date.parse(scheduleEndDate.toString()) + timeDuration(duration - 15)
          )
        ),
      },
    });
  } else {
    setDurationEdit(durationEdit - 15);
    setEditScheduleEndDate(
      new Date(
        Date.parse(editScheduleStartDate.toString()) +
          timeDuration(durationEdit - 15)
      )
    );
    setFormData({
      ...formData,
      endDateTime: new Date(
        Date.parse(editScheduleStartDate.toString()) +
          timeDuration(durationEdit - 15)
      ),
      value: {
        ...formData.value,
        end: !maxDayValue(
          editScheduleStartDate,
          new Date(
            Date.parse(editScheduleStartDate.toString()) +
              timeDuration(durationEdit - 15)
          )
        )
          ? timeStringify(
              new Date(
                Date.parse(editScheduleStartDate.toString()) +
                  timeDuration(durationEdit - 15)
              )
            )
          : "24:00",
      },
    });
  }
}
export function selectTimeline(startDate, endDate) {
  let startDay = Date.parse(
    `${startDate.getFullYear()}-${
      startDate.getMonth() + 1 < 10
        ? "0" + (startDate.getMonth() + 1)
        : startDate.getMonth() + 1
    }-${
      startDate.getDate() < 10 ? "0" + startDate.getDate() : startDate.getDate()
    }T00:00:00`
  );
  let endDay = Date.parse(
    `${startDate.getFullYear()}-${
      startDate.getMonth() + 1 < 10
        ? "0" + (startDate.getMonth() + 1)
        : startDate.getMonth() + 1
    }-${
      startDate.getDate() < 10 ? "0" + startDate.getDate() : startDate.getDate()
    }T23:59:59`
  );
  let dayStartTimeline = Date.parse(
    `${startDate.getFullYear()}-${
      startDate.getMonth() + 1 < 10
        ? "0" + (startDate.getMonth() + 1)
        : startDate.getMonth() + 1
    }-${
      startDate.getDate() < 10 ? "0" + startDate.getDate() : startDate.getDate()
    }T08:00:00`
  );
  let dayEndTimeline = Date.parse(
    `${startDate.getFullYear()}-${
      startDate.getMonth() + 1 < 10
        ? "0" + (startDate.getMonth() + 1)
        : startDate.getMonth() + 1
    }-${
      startDate.getDate() < 10 ? "0" + startDate.getDate() : startDate.getDate()
    }T21:00:00`
  );
  let morningStartTimeline = Date.parse(
    `${startDate.getFullYear()}-${
      startDate.getMonth() + 1 < 10
        ? "0" + (startDate.getMonth() + 1)
        : startDate.getMonth() + 1
    }-${
      startDate.getDate() < 10 ? "0" + startDate.getDate() : startDate.getDate()
    }T00:00:00`
  );
  let morningEndTimeline = Date.parse(
    `${startDate.getFullYear()}-${
      startDate.getMonth() + 1 < 10
        ? "0" + (startDate.getMonth() + 1)
        : startDate.getMonth() + 1
    }-${
      startDate.getDate() < 10 ? "0" + startDate.getDate() : startDate.getDate()
    }T14:00:00`
  );
  let nightStartTimeline = Date.parse(
    `${startDate.getFullYear()}-${
      startDate.getMonth() + 1 < 10
        ? "0" + (startDate.getMonth() + 1)
        : startDate.getMonth() + 1
    }-${
      startDate.getDate() < 10 ? "0" + startDate.getDate() : startDate.getDate()
    }T10:00:00`
  );
  let nightEndTimeline = Date.parse(
    `${startDate.getFullYear()}-${
      startDate.getMonth() + 1 < 10
        ? "0" + (startDate.getMonth() + 1)
        : startDate.getMonth() + 1
    }-${
      startDate.getDate() < 10 ? "0" + startDate.getDate() : startDate.getDate()
    }T24:00:00`
  );
  if (
    Date.parse(startDate) >= startDay &&
    Date.parse(startDate) <= endDay &&
    Date.parse(endDate) >= startDay &&
    Date.parse(endDate) <= endDay
  ) {
    if (
      Date.parse(startDate) >= dayStartTimeline &&
      Date.parse(startDate) <= dayEndTimeline &&
      Date.parse(endDate) >= dayStartTimeline &&
      Date.parse(endDate) <= dayEndTimeline
    ) {
      return {
        min: "08:00",
        max: "21:00",
        timeline: 2,
      };
    }
    if (
      Date.parse(startDate) >= morningStartTimeline &&
      Date.parse(startDate) <= morningEndTimeline
    ) {
      return {
        min: "00:00",
        max: "14:00",
        timeline: 1,
      };
    }
    if (
      Date.parse(startDate) >= nightStartTimeline &&
      Date.parse(startDate) <= nightEndTimeline &&
      Date.parse(endDate) >= nightStartTimeline &&
      Date.parse(endDate) <= nightEndTimeline
    ) {
      return {
        min: "10:00",
        max: "24:00",
        timeline: 3,
      };
    }
  } else {
    if (
      Date.parse(startDate) < dayStartTimeline &&
      Date.parse(endDate) <= endDay
    ) {
      return {
        min: "00:00",
        max: "14:00",
        timeline: 1,
      };
    }
    if (
      Date.parse(startDate) > dayEndTimeline &&
      Date.parse(endDate) <= endDay
    ) {
      return {
        min: "10:00",
        max: "24:00",
        timeline: 3,
      };
    }
    if (
      Date.parse(startDate) > dayStartTimeline &&
      Date.parse(endDate) <= endDay
    ) {
      return {
        min: "08:00",
        max: "21:00",
        timeline: 2,
      };
    }
    if (
      Date.parse(startDate) >= dayStartTimeline &&
      Date.parse(startDate) < nightStartTimeline &&
      Date.parse(endDate) >= endDay
    ) {
      return {
        min: "08:00",
        max: "21:00",
        timeline: 2,
      };
    }
    if (
      Date.parse(startDate) < dayStartTimeline &&
      Date.parse(endDate) >= endDay
    ) {
      return {
        min: "00:00",
        max: "14:00",
        timeline: 1,
      };
    } else {
      return {
        min: "10:00",
        max: "24:00",
        timeline: 3,
      };
    }
  }
}
export function dates(startDate, timeline) {
  if (timeline == 1) {
    return {
      start: Date.parse(
        `${startDate.getFullYear()}-${
          startDate.getMonth() + 1 < 10
            ? "0" + (startDate.getMonth() + 1)
            : startDate.getMonth() + 1
        }-${
          startDate.getDate() < 10
            ? "0" + startDate.getDate()
            : startDate.getDate()
        }T00:00:00`
      ),
      end: Date.parse(
        `${startDate.getFullYear()}-${
          startDate.getMonth() + 1 < 10
            ? "0" + (startDate.getMonth() + 1)
            : startDate.getMonth() + 1
        }-${
          startDate.getDate() < 10
            ? "0" + startDate.getDate()
            : startDate.getDate()
        }T14:00:00`
      ),
    };
  }
  if (timeline == 2) {
    return {
      start: Date.parse(
        `${startDate.getFullYear()}-${
          startDate.getMonth() + 1 < 10
            ? "0" + (startDate.getMonth() + 1)
            : startDate.getMonth() + 1
        }-${
          startDate.getDate() < 10
            ? "0" + startDate.getDate()
            : startDate.getDate()
        }T08:00:00`
      ),
      end: Date.parse(
        `${startDate.getFullYear()}-${
          startDate.getMonth() + 1 < 10
            ? "0" + (startDate.getMonth() + 1)
            : startDate.getMonth() + 1
        }-${
          startDate.getDate() < 10
            ? "0" + startDate.getDate()
            : startDate.getDate()
        }T21:00:00`
      ),
    };
  }
  if (timeline == 3) {
    return {
      start: Date.parse(
        `${startDate.getFullYear()}-${
          startDate.getMonth() + 1 < 10
            ? "0" + (startDate.getMonth() + 1)
            : startDate.getMonth() + 1
        }-${
          startDate.getDate() < 10
            ? "0" + startDate.getDate()
            : startDate.getDate()
        }T10:00:00`
      ),
      end: Date.parse(
        `${startDate.getFullYear()}-${
          startDate.getMonth() + 1 < 10
            ? "0" + (startDate.getMonth() + 1)
            : startDate.getMonth() + 1
        }-${
          startDate.getDate() < 10
            ? "0" + startDate.getDate()
            : startDate.getDate()
        }T23:59:59`
      ),
    };
  }
}
export function minStartRange(value, range) {
  let dataValue = Date.parse(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 < 10
        ? "0" + (new Date().getMonth() + 1)
        : new Date().getMonth() + 1
    }-${
      new Date().getDate() < 10
        ? "0" + new Date().getDate()
        : new Date().getDate()
    }T${
      Number(value.split(":")[0]) < 10
        ? "0" + Number(value.split(":")[0])
        : Number(value.split(":")[0])
    }:${
      Number(value.split(":")[1]) < 10
        ? "0" + Number(value.split(":")[1])
        : Number(value.split(":")[1])
    }:00`
  );
  let dataRange = Date.parse(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 < 10
        ? "0" + (new Date().getMonth() + 1)
        : new Date().getMonth() + 1
    }-${
      new Date().getDate() < 10
        ? "0" + new Date().getDate()
        : new Date().getDate()
    }T${range.split(":")[0]}:${range.split(":")[1]}:00`
  );
  if (dataValue <= dataRange) {
    return timeStringify(new Date(dataRange));
  } else {
    return timeStringify(new Date(dataValue));
  }
}
export function maxEndRange(value, range) {
  let dataValue = Date.parse(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 < 10
        ? "0" + (new Date().getMonth() + 1)
        : new Date().getMonth() + 1
    }-${
      new Date().getDate() < 10
        ? "0" + new Date().getDate()
        : new Date().getDate()
    }T${
      Number(value.split(":")[0]) < 10
        ? "0" + Number(value.split(":")[0])
        : Number(value.split(":")[0])
    }:${
      Number(value.split(":")[1]) < 10
        ? "0" + Number(value.split(":")[1])
        : Number(value.split(":")[1])
    }:00`
  );
  let dataRange = Date.parse(
    `${new Date().getFullYear()}-${
      new Date().getMonth() + 1 < 10
        ? "0" + (new Date().getMonth() + 1)
        : new Date().getMonth() + 1
    }-${
      new Date().getDate() < 10
        ? "0" + new Date().getDate()
        : new Date().getDate()
    }T${range.split(":")[0]}:${range.split(":")[1]}:00`
  );
  if (dataValue > dataRange) {
    return timeStringify(new Date(dataRange));
  } else {
    return timeStringify(new Date(dataValue));
  }
}
export function changeDuration(
  e,
  setDuration,
  setDurationEdit,
  edit,
  formData,
  setFormData,
  scheduleStartDate,
  editScheduleStartDate,
  setScheduleEndDate,
  setEditScheduleEndDate
) {
  if (!edit) {
    let newDate = new Date(
      Date.parse(scheduleStartDate) + timeDuration(Number(e.target.value))
    );
    setDuration(Number(e.target.value));
    setScheduleEndDate(newDate);
    setFormData({
      ...formData,
      endDateTime: newDate,
      value: {
        ...formData.value,
        end: !maxChangeDuration(scheduleStartDate, newDate)
          ? timeStringify(newDate)
          : selectTimeline(scheduleStartDate, scheduleStartDate).max,
      },
    });
  } else {
    let newDate = new Date(
      Date.parse(editScheduleStartDate) + timeDuration(Number(e.target.value))
    );
    setDurationEdit(Number(e.target.value));
    setEditScheduleEndDate(newDate);
    setFormData({
      ...formData,
      endDateTime: newDate,
      value: {
        ...formData.value,
        end: !maxChangeDuration(editScheduleStartDate, newDate)
          ? timeStringify(newDate)
          : selectTimeline(editScheduleStartDate, newDate).max,
      },
      timeRange: selectTimeline(editScheduleStartDate, newDate),
    });
  }
}
