import { NewRequest } from "../../helpers/axios";
import { DELETE, GET, POST, PUT } from "../../helpers/axiosConst";
import { API_URLS, BuildAPIUrl } from "../../utils/config/URLS";
import { AddCurrencies } from "../../store/actions/currenciesActions";
import { toast } from "react-toastify";

export const GetCurrencies = (
  params = false,
  setState = () => false,
  state = {},
  setOnLoad = () => false
) => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(API_URLS.GET_CURRENCIES),
      null,
      (res) => {
        setOnLoad(true);
        const { data, pages, total } = res.data;
        setState({
          ...state,
          isFetchData: false,
          data,
          pages,
          total,
          params: {
            ...state.params,
            page: Number(params.page),
            perPage: Number(params.perPage),
            filtered: params.filtered,
            sorted: params.sorted,
            conditions: params.conditions,
          },
        });
      },
      () => {
        setOnLoad(true);
      },
      params && {
        page: Number(params.page),
        perPage: Number(params.perPage),
        sorted: params.sorted,
        filtered: params.filtered,
        conditions: params.conditions,
      }
    );
  };
};

export const GetAllCurrencies = () => {
  return (dispatch) => {
    NewRequest(
      GET,
      BuildAPIUrl(API_URLS.GET_CURRENCIES),
      null,
      (res) => {
        const { data } = res.data;
        dispatch(AddCurrencies(data));
      },
      () => false,
      {
        page: 1,
        perPage: 100000,
      }
    );
  };
};

export const SetCurrency = (data, onPageClick, state) => {
  return (dispatch) => {
    NewRequest(POST, BuildAPIUrl(API_URLS.GET_CURRENCIES), data, () => {
      onPageClick(state.params);
      toast.success("Currency added");
    });
  };
};

export const EditCurrency = (data, id, onPageClick, state) => {
  return (dispatch) => {
    NewRequest(
      PUT,
      BuildAPIUrl(`${API_URLS.GET_CURRENCIES}/${id}`),
      data,
      () => {
        onPageClick(state.params);
        toast.success("Currency edited");
      }
    );
  };
};

export const DeleteCurrency = (id, onPageClick, state) => {
  return (dispatch) => {
    NewRequest(
      DELETE,
      BuildAPIUrl(`${API_URLS.GET_CURRENCIES}/${id}`),
      null,
      () => {
        onPageClick(state.params);
        toast.success("Currency deleted");
      }
    );
  };
};
