import * as opportunitiesActions from "./constants";

export const SetOpportunitiesOptions = (data) => {
  return {
    type: opportunitiesActions.SET_OPPORTUNITIES_OPTIONS,
    payload: data,
  };
};
export const AddSelectedOpportunities = (data) => {
  return {
    type: opportunitiesActions.SET_SELECTED_OPPORTUNITIES,
    payload: data,
  };
};
export const SetOpportunitiesWeightAction = (data) => {
  return {
    type: opportunitiesActions.SET_WEIGHT_OPPORTUNITIES,
    payload: data,
  };
};
export const AddOpportunities = (data) => {
  return {
    type: opportunitiesActions.SET_OPPORTUNITIES,
    payload: data,
  };
};
export const GetTaxesAction = (data) => {
  return {
    type: opportunitiesActions.SET_TAXES,
    payload: data,
  };
};
export const AddTaxesAction = (data) => {
  return {
    type: opportunitiesActions.ADD_TAXES,
    payload: data,
  };
};
export const EditTaxesAction = (data) => {
  return {
    type: opportunitiesActions.EDIT_TAXES,
    payload: data,
  };
};
export const DeleteTaxesAction = (data) => {
  return {
    type: opportunitiesActions.DELETE_TAXES,
    payload: data,
  };
};
