import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import download from "../../../assets/img/illustrations/download.svg";
import ava1 from "../../../assets/img/illustrations/ava-5.png";
import ava2 from "../../../assets/img/illustrations/ava-2.png";
import ava3 from "../../../assets/img/illustrations/ava-1.png";
import ava4 from "../../../assets/img/illustrations/ava-4.png";
import loading from "../../../assets/img/icons/loading.svg";
import { useDispatch } from "react-redux";
import { avatarToBlob, canvasToBlob } from "../../../helpers/avatarToBlob";
import ModalLayout from "../../../layouts/ModalLayout";
import AvatarEditor from "react-avatar-editor";
import add from "../../../assets/img/illustrations/add_button.svg";

const ChangeAvatarModal = React.memo(({ avatarModal, setAvatarModal }) => {
  const [drag, setDrag] = useState(false);
  const [progress, setProgress] = useState(null);
  const [selectedAvatar, setSelectedAvatar] = useState("");
  const defaultAvatarList = [
    { avatar: ava1 },
    { avatar: ava2 },
    { avatar: ava3 },
    { avatar: ava4 },
  ];
  const dispatch = useDispatch();
  const [newAvatar, setNewAvatar] = useState(false);
  const ref = useRef();
  function changeDefaultAvatar(e, avatar) {
    setSelectedAvatar(avatar);
    avatarToBlob(avatar, setNewAvatar);
  }
  function dragStartHandler(e) {
    e.preventDefault();
    setDrag(true);
  }
  function draLeaveHandler(e) {
    e.preventDefault();
    setDrag(false);
  }
  function onDropHandler(e) {
    e.preventDefault();
    let files = [e.dataTransfer.files];
    setNewAvatar(files[0][0]);
  }
  function onChangeAvatar(e) {
    setNewAvatar(e.target.files[0]);
  }
  const [editorData, setEditorData] = useState({
    scale: 1,
  });
  function handleScale(e) {
    const scale = parseFloat(e.target.value);
    setEditorData({ ...editorData, scale: scale });
  }
  function handleSubmit() {
    const newCanvas = ref.current.getImage();
    canvasToBlob(
      newCanvas,
      dispatch,
      setProgress,
      setAvatarModal,
      setNewAvatar,
      setEditorData
    );
  }
  function close() {
    setNewAvatar(false);
    setEditorData({
      scale: 1,
    });
    setAvatarModal(false);
  }
  return (
    <ModalLayout
      modal={avatarModal}
      close={close}
      className={`${progress && "loaded"} modal_avatar`}
      title={"Change avatar"}
      handleSubmit={handleSubmit}
    >
      <div className={"modal_overlay"}>
        <div className={"d-flex align-items-center"}>
          <img src={loading} alt="icon" />
          {`${Math.floor(progress)} %`}
        </div>
      </div>
      <p>
        Upload your file or choose one from the list below. We accept a maximum
        of 1024x1024px (jpg, png, gif)
      </p>
      {newAvatar && (
        <div
          className={
            "avatar_editor_wrapper d-flex justify-content-center align-items-center flex-column"
          }
        >
          <div className={"position-relative avatar_canvas_wrapper"}>
            <AvatarEditor
              ref={ref}
              image={newAvatar}
              borderRadius={150}
              style={{ borderRadius: "50%" }}
              width={170}
              height={170}
              border={0}
              color={[255, 255, 255, 0.6]}
              scale={editorData.scale}
            />
            <div className={"add_avatar"}>
              <img src={add} alt="icon" />
              <input type="file" onInput={onChangeAvatar} />
            </div>
          </div>
          <label className={"d-flex align-items-center justify-content-center"}>
            Zoom:
            <input
              className={"ms-2"}
              name="scale"
              type="range"
              onChange={(e) => handleScale(e)}
              min={"1"}
              max="2"
              step="0.01"
              defaultValue="1"
            />
          </label>
        </div>
      )}
      {!newAvatar && (
        <label
          onInput={onChangeAvatar}
          onDrop={onDropHandler}
          onDragStart={dragStartHandler}
          onDragLeave={draLeaveHandler}
          onDragOver={dragStartHandler}
          className={
            "drop_input d-flex align-items-center justify-content-center"
          }
        >
          <img src={download} alt="icon" className={"mx-2"} />{" "}
          {!drag ? "Drop your files here" : "Release the dragged file"}
          <input type="file" />
        </label>
      )}
      <ul className={"d-flex p-0 mt-4 mb-0"}>
        {defaultAvatarList.map((item, index) => (
          <li
            onClick={(e) => changeDefaultAvatar(e, item.avatar)}
            key={index}
            className={`default_avatar_list ${
              selectedAvatar === item.avatar ? "active" : ""
            }`}
          >
            <img src={item.avatar} alt="default_avatar_1" />
          </li>
        ))}
      </ul>
    </ModalLayout>
  );
});

ChangeAvatarModal.propTypes = {
  avatarModal: PropTypes.bool,
  setAvatarModal: PropTypes.func,
};

export default ChangeAvatarModal;
